import React from "react";
import {Button, List, PageHeader} from 'antd';
import configMap from "../../Config/types";
import {useSearchParams} from "react-router-dom";
import {useNavigateWithSearch} from "../../../hooks/navigation";

import Help from "../../Common/Help";

const feedTypes = Object.entries(configMap["import"]).map(
    ([type, value]) => {
        return {
            type,
            ...value
        }
    }
);

export default function FeedWizard() {
    const [searchParams] = useSearchParams();
    const folderId = searchParams.get("folderId");
    const navigate = useNavigateWithSearch();

    return <div>
        <PageHeader
            title={'Создание нового источника'}
            subTitle={'выберите тип источника'}
            backIcon={false}
            extra={[
                <Help page='SOURCE_TYPE'>Что такое тип источника?</Help>,
            ]}
        />
        <List
            style={{margin: '20px 30px'}}
            itemLayout="horizontal"
            dataSource={feedTypes}
            rowKey={item => item.type}
            renderItem={item => (
                <List.Item>
                    <div className="list-item" style={{width: '100px'}}>
                        <b>{item.type}</b>
                    </div>
                    <List.Item.Meta
                        description={item.description}
                    />
                    <Button onClick={() => navigate(item.type, {searchParams: {folderId}})}>
                        Выбрать
                    </Button>
                </List.Item>
            )}
        />
    </div>
};
