import React, {useState} from 'react';
import {Button, Table, Empty, Space} from 'antd';

import {useFetching} from '../../hooks/useFetching';
import ProductService from '../../API/ProductService';
import ProposalCard from './ProposalCard';
import trans from '../../languages/ruTranslate';
import FeedService from "../../API/FeedService";

export function generateTranslatedErrorMessage(state, prefix) {
    if (typeof state.errorDetails == "string") {
        return state.errorDetails
    } else {
        const errorDescriptionTemplate = trans(prefix + "_" + state.errorType + "_template")
        if (!errorDescriptionTemplate)
            return ""
        const names = state.errorDetails ? state.errorDetails : {}
        return errorDescriptionTemplate.replace(/%\w+%/g, function (templateVar) {
            return templateVar in names ? state.errorDetails[templateVar] : templateVar
        })
    }
}

export function findErroredAttribute(state) {
    switch (state.errorType) {
        case "no_id":
            return trans("productId")
        case "no_category":
            return "_category_"
        case "no_attribute":
        case "wrong_value":
        case "wrong_attribute":
        case "expression_error":
            return state.errorDetails["%ATTRIBUTE_NAME%"]
        default:
            return undefined
    }
}

export function translateErrorType(errorType, prefix) {
    return errorType ? trans(prefix + "_" + errorType) : "";
}

export default function FeedErrors({feedDoc, openAttributeMapping}) {
    const feedId = feedDoc._id;
    const [data, setData] = useState([]);
    const [productId, openProduct] = useState();
    const [pageSize, setPageSize] = useState(100);
    const [current, setCurrent] = useState(1);
    const [total, setTotal] = useState(10);
    const [fetchProducts, loading] = useFetching( async () => {
        const result = await ProductService.getConflicts({feedId, current, pageSize});
        setTotal(result.count);
        for (const proposal of result.data) {
            proposal.errorTypeTrans = translateErrorType(proposal.state.errorType, "re")
            proposal.errorDescriptionTrans = generateTranslatedErrorMessage(proposal.state, "re")
            proposal.erroredAttribute = findErroredAttribute(proposal.state)
        }
        setData(result.data);
    })

    React.useEffect( () => {
        fetchProducts();
    }, [current, pageSize]);

    const handleTableChange = ({current, pageSize}) => {
        setCurrent(current);
        setPageSize(pageSize);
    };

    return ( productId
        ? <ProposalCard proposalId={productId} openProduct={openProduct}/>
            : <div>
                {!loading && (data.length ?
                        <div>
                            <Space style={{float: 'right', marginBottom: '10px'}}>
                                <Button
                                    onClick={() => FeedService.downloadErrorsToXls(feedDoc.name, feedId)}
                                >Сохранить в Excel</Button>
                            </Space>
                            <Table
                                rowKey={p => p._id}
                                dataSource={data}
                                columns={[
                                    {
                                        title: 'ID товара',
                                        key: 'productId',
                                        render: (text, item) =>
                                            <Button type='link' onClick={() => openProduct(item._id)}>
                                <span style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: 'inline-block',
                                    textAlign: 'left'
                                }}>
                                    {item.productId ? item.productId : "ID отсутствует"}
                                </span>
                                            </Button>,
                                    }, {
                                        title: 'Ошибка',
                                        dataIndex: ['errorTypeTrans'],
                                        key: 'error'
                                    }, {
                                        title: 'Описание',
                                        dataIndex: ['errorDescriptionTrans'],
                                        key: 'description'
                                    }, {
                                        key: 'mapping',
                                        render: (text, item) => item.erroredAttribute ?
                                            <Button type='link'
                                                    onClick={() => openAttributeMapping(item.erroredAttribute)}>
                                                Редактирование
                                            </Button> : null
                                    }
                                ]}
                                defaultExpandAllRows={false}
                                pagination={{current, total, pageSize}}
                                key="table"
                                onChange={handleTableChange}
                            /></div>
                        : <Empty description='Нет ошибок'/>
                )}
            </div>
    )
}
