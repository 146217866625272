import React, {useState, useEffect} from "react";
import { PageHeader,Button, Col, Row, Modal } from 'antd';
import { LockOutlined, DeleteOutlined } from '@ant-design/icons';

import AttributeAdd  from "./AttributeAdd";
import AttributeService from "../../API/AttributeService";
import { useFetching } from "../../hooks/useFetching";

export default function Attributes() {    
    const [data, setData] = useState([]);
    const [addModal, setAddModal] = useState(false);
    const [fetchAttributes, isLoading, error] = useFetching( async () => {
        const response = await AttributeService.getAttributes();
        setData(response)
    })
    const { showBoundary } = require('react-error-boundary').useErrorBoundary()

    const deleteAttribute = async (element) => {
        try {
            await AttributeService.deleteAttribute(element.name);
            const newData = data.filter( (item) => item.name !== element.name );
            setData(newData);
        } catch (e) {
            console.log("attribute deleting problem")
            showBoundary(e)
        }
    }

    const addAttribute = (attribute) => data.push(attribute);

    function showDeleteConfirm(element) {
        Modal.confirm({
          title: 'Удалить атрибут?',
          okText: 'Да',
          okType: 'danger',
          cancelText: 'Нет',
          onOk() {
            deleteAttribute(element);
          }
        });
    }

    const getItems =  () => data?.map(element => {
        return (
            <Row style={{marginTop : '20px'}} key = {element.name}>          
                <Col  span={8}>{element.name}</Col>
                <Col  span={14}>{element.description}</Col>
                <Col span={2}>{ 
                    !element.isSystem ? 
                        <Button icon={<DeleteOutlined />} onClick = {() => showDeleteConfirm(element)}/>
                    : <LockOutlined style = {{ marginLeft : '10px'}} />
                }</Col>
            </Row>       
        )
    })

    useEffect(() => {
        fetchAttributes();
    }, []);
    
    if (error) {
        return <div>
            Обшибка загрузки: {error}
        </div>
    }

    if (isLoading) {
        return <div>
            Загрузка данных....
        </div>
    }

    return <div>
        <PageHeader
            title="Атрибуты товара"
            extra={[
                <Button key="addAttribute" type="primary" onClick={() => setAddModal(true)}>
                    Добавить атрибут
                </Button>
            ]}
        />   
        <div style= {{margin: '20px 30px'}}>
            { getItems() }
        </div>
        
        { addModal ? <AttributeAdd setAddModal = {setAddModal} addAttribute = {addAttribute} />: null }
    </div>         
}