import {Modal,Input, Select, Form, DatePicker, AutoComplete} from 'antd';
import moment from 'moment';

import config from '../../config';

export default function EditPayment({projects, item, saveItem, payments}) {
    const [form] = Form.useForm();

    // Filter `option.label` match the user type `input`
    const filterOption = (input, option) => (option.label ?? '').toLowerCase().includes(input.toLowerCase()); 
    const setInn = projectId => {
        const lastPayment = payments.sort((a, b) => b.num - a.num).find(payment => payment.projectId === projectId && payment.status === 'paid');
        if (lastPayment){
            form.setFieldValue('inn', lastPayment.inn);
            form.setFieldValue('client', lastPayment.client);
            form.setFieldValue('tariff', lastPayment.tariff);
            form.setFieldValue('summ', lastPayment.summ)   
        }
    }   

    return (
        <Modal
            open={true}
            title= { item.status === 'paid' ? "Платеж" : "Счет" }
            okText="Сохранить"
            cancelText="Закрыть"
            onOk={()=> form.submit()}
            onCancel={() => saveItem()}
        >
            <Form
                    labelCol={{ span: 5 }}
                    wrapperCol={{ span: 18 }}
                    form={form}    
                    initialValues={item}
                    onFinish={(values) => saveItem({_id: item._id, ...values})}            
            >
                <Form.Item label="Проект" name = "projectId" 
                    rules={[
                        {required: true, message: 'Обязательный параметр'}]
                    }
                >
                    <Select
                        showSearch
                        filterOption={filterOption}
                        options= {projects.filter(prj => !prj.parentProject).map( prj => ({value: prj._id, label: prj.name})) }
                        onSelect={(label, option)=> setInn(option.value)}
                    />
                </Form.Item>    
                <Form.Item label="Дата" name = "date" initialValue={moment()}
                    rules={[
                        {required: true, message: 'Обязательный параметр'}]
                    }
                >
                    <DatePicker />
                </Form.Item>
                { item.status === 'paid' && 
                    <Form.Item 
                        label="Дата оплаты" 
                        name="payDate"
                        rules={[
                            {required: true, message: 'Обязательный параметр'}]
                        }
                    >
                        <DatePicker />
                    </Form.Item>
                }  
                <Form.Item label="Сумма" name = "summ"
                    rules={[
                        {required: true, message: 'Обязательный параметр'}]
                    }
                >
                    <Input /> 
                </Form.Item>                 
                <Form.Item label="Тариф" name = "tariff"
                    rules={[
                        {required: true, message: 'Обязательный параметр'}]
                    }
                >
                    <AutoComplete 
                        options = {config.tariffs}
                    />
                </Form.Item>  
                <Form.Item label="Клиент" name = "client">
                    <Input /> 
                </Form.Item>
                <Form.Item label="ИНН" name = "inn">
                    <Input /> 
                </Form.Item>
            </Form>
        </Modal>
    );

};
