import React from 'react';
import { Form } from 'antd';

import config from '../../config';
import AuthProvider from "../../API/AuthProvider";

const ConfigAvito = ({data}) => {
    const host = config.apiUrl ? config.apiUrl : config.origin;
    const feedUrl = `${host}/api/load/${AuthProvider.getProject()}_${data?.name}.xml`;

    return (
        <div>
            {   data &&
                <Form.Item label="Ссылка на фид">
                    <a href={feedUrl}>{feedUrl}</a>
                </Form.Item>
            }
        </div>
    );
};

export default ConfigAvito;