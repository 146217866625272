import React from "react";
import { Form, Input, Button } from 'antd';

import UserService from '../../API/UserService';

export default function ResetPassword({activation, login, onFinish}) {
    const [message, setMessage] = React.useState();
    const [form] = Form.useForm();
    const onActivate = async (values) => {
        try {
            await UserService.resetPassword(values);
            setMessage('Отправлен запрос для сброса пароля на емэйл');
        } catch (ex) {
            setMessage('Нет такого пользователя');
            console.log('Activate problem: ', ex);
        }
    }

    if (message) return <h3>{message}</h3>
    return (
        <Form 
            name="basic"
            form={form}
            onFinish={onActivate}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 8 }}
        >
            <Form.Item wrapperCol={{offset: 8, span: 16 }}>
                <h3>Сброс пароля</h3>
            </Form.Item>
            <Form.Item label="Логин/email" name="login" rules={[{ required: true, message: 'введите email!' }]}>
                <Input />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8, span: 8 }}>
                <Button type="primary" htmlType="submit">Отправить на емэйл</Button>
            </Form.Item>
        </Form>
    )
}
