import ConfigXml from './ConfigXml';
import ConfigYmlExport from './ConfigYmlExport';
import ConfigJsonExport from './ConfigJsonExport';
import ConfigYml from './ConfigYml';
import ConfigXlsx from './ConfigXlsx';
import ConfigCsv from './ConfigCsv';
import ConfigOzon from './ConfigOzon';
import ConfigOzonStocks from './ConfigOzonStocks';
import ConfigWild from './ConfigWild';
import ConfigWildStocks from './ConfigWildStocks';
import ConfigSber from './ConfigSber';
import ConfigSberExport from './ConfigSberExport';
import ConfigSkult from './ConfigSkult';
import Config1C from './Config1C';
import ConfigYandexMarket from "./ConfigYandexMarket";
import ConfigSberImport from "./ConfigSberImport";
import ConfigXlsxExport from "./ConfigXlsxExport";
import ConfigAvito from "./ConfigAvito";

const configMap = {
    import : {
        '1C': {config: Config1C, description: "Получение товаров из системы 1С Торговля"},
        'OZON': {config: ConfigOzon, description: "Получение товаров из маркетплейса Озон"},
        'WILD': {config: ConfigWild, description: "Получение товаров из маркетплейса Вайлдберриз"},
        'YNDX': {config: ConfigYandexMarket("business", "warehouse", "campaign"), description: "Получение товаров из маркетплейса Яндекс Маркет"},
        'SBER': {config: ConfigSberImport, description: "Получение товаров из маркетплейса Мегамаркет"},
        'YML': {config: ConfigYml, description: "Получение товаров из файла в стандарте YML"},
        'XML': {config: ConfigXml, description: "Получение товаров из файлов XML"},
        'XLSX': {config: ConfigXlsx, description: "Получение товаров из файлов Excel таблиц"},
        'GSS': {config: ConfigXlsx, description: "Получение товаров из файлов из Google Таблиц"},
        'CSV': {config: ConfigCsv, description: "Получение товаров из файлов CSV"},
        'JSON': {config: ConfigXml, description: "Получение товаров из файла в формате JSON"}
    },
    export : {
        'OZON_CARDS' : {config: ConfigOzon, parent: "OZON", description: "Выгрузка карточек товаров"},
        'OZON_STOCKS': {config: ConfigOzonStocks, parent: "OZON", description: "Выгрузка остатков товаров"},
        'OZON_PRICES': {config: ConfigOzon, parent: "OZON", description: "Выгрузка цен товаров"},
        'OZON_CARDS_OLD' : {config: ConfigOzon, parent: "OZON", description: "Выгрузка карточек (старое дерево категорий)", hidden: true},
        'WILD_CARDS' : {config: ConfigWild, parent: "WILD", description: "Выгрузка карточек товаров"},
        'WILD_STOCKS': {config: ConfigWildStocks, parent: "WILD", description: "Выгрузка остатков товаров"},
        'WILD_PRICES' : {config: ConfigWild, parent: "WILD", description: "Выгрузка цен товаров"},
        'YNDX_CARDS': {config: ConfigYandexMarket("business", "", "campaign"), parent: "YNDX", description: "Выгрузка карточек товаров"},
        'YNDX_GOODS': {config: ConfigYandexMarket("business"), parent: "YNDX", description: "Выгрузка ассортимента товаров", hidden: true},
        'YNDX_STOCKS': {config: ConfigYandexMarket("", "warehouse", "required_campaign"), parent: "YNDX", description: "Выгрузка остатков товаров"},
        'YNDX_PRICES': {config: ConfigYandexMarket("business"), parent: "YNDX", description: "Выгрузка цен товаров"},
        'SBER_CARDS': {config: ConfigSberExport, parent: "SBER", description: "Выгрузка карточек товаров"},
        'SBER_STOCKS': {config: ConfigSber, parent: "SBER", description: "Выгрузка остатков товаров"},
        'SBER_PRICES': {config: ConfigSber, parent: "SBER", description: "Выгрузка цен товаров"},
        'AVITO_ADS' : {config: ConfigAvito, parent: "AVITO", description: "Выгрузка объявлений"},
        'AVITO_STOCKS' : {config: ConfigXlsxExport, parent: "AVITO", description: "Выгрузка остатков в формате XLSX"},
        'YML_FILE': {config: ConfigYmlExport, parent: "FILE", description: "Выгрузка карточек, цен и остатков товаров в формате YML"},
        'JSON_FILE':{config: ConfigJsonExport, parent: "FILE", description: "Выгрузка карточек товаров карточек, цен и остатков в формате JSON"},
        'XLSX_FILE': {config: ConfigXlsxExport, parent: "FILE", description: "Выгрузка всех данных каталога в формате XLSX"},
        'SKULT_CARDS' : {config: ConfigSkult, parent: "SKULT", description: "Выгрузка карточек товаров"}
    },
    parent : {
        'FILE' : {name: "Файлы", description: ""},
        'OZON' : {name: "Маркетплейс Озон", description: ""},
        'WILD' : {name: "Маркетплейс Вайлдберриз", description: ""},
        'SBER' : {name: "Маркетплейс СберМегаМаркет", description: ""},
        'YNDX' : {name: "Маркетплейс Яндекс Маркет", description: ""},
        'SKULT' : {name: "Интернет-магазин Спорткульт", description: ""},
        'AVITO' : {name: "Каталог объявлений Авито", description: ""},
    }
}

export default configMap;