import api, {masterApi} from "./ServerAPI";

export default class PaymentService {
    static async getAllPayments(){
        const response = await masterApi.get(`/payments`);
        return response.data
    }

    static async addPayment(payment){
        const response = await masterApi.post(`/payments`, payment);
        return response.data
    }

    static async updatePayment(payment){
        const response = await masterApi.patch(`/payments/${payment._id}`, payment);
        return response.data
    }

    static async getPayments(){
        const response = await api.get(`/payments`);
        return response.data
    }

    static async projectsBiling(){
        const response = await masterApi.get(`/payments/billing`);
        return response.data
    }
}