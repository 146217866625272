import React, {useEffect, useState} from 'react';
import {DatePicker, Form, Input, Select, InputNumber, Modal} from 'antd';
import locale from 'antd/es/date-picker/locale/ru_RU';
import 'dayjs/locale/ru'

import ProjectService from "../../API/ProjectService";

function EditProject({hide, project, onProjectChange, projects}) {
    const [confirmLoading, setConfirmLoading] = useState(false)
    const { showBoundary } = require('react-error-boundary').useErrorBoundary()
    const [parent, setParent] = useState(project.parentProject);
    const [form] = Form.useForm()

    const filterOption = (input, option) => (option.label ?? '').toLowerCase().includes(input.toLowerCase()); 
    const getOptions = () => {
        const options = [{
            label: 'не задан',
            value: null
        }];
        const mainProjects = projects.filter(prj => !prj.parentProject).map( prj => ({
            value: prj._id, 
            label: prj.name
        }));
        return options.concat(mainProjects)
    }

    const saveProject = async (values) => {
        try{
            setConfirmLoading(true);
            const projectDoc = await ProjectService.updateProject(project._id, values);
            onProjectChange(projectDoc)
            setConfirmLoading(false)
            hide();
        } catch (e){
            showBoundary(e)
        }
    }

    useEffect(() => {
        form.setFieldsValue({...project, parentProject : parent ? parent : null})
    }, [])

    return <Modal
        title="Редактировать проект"
        okText={'Сохранить'}
        cancelText={'Отмена'}
        open={true}
        onOk={() => form.submit()}
        onCancel={() => hide()}
        confirmLoading={confirmLoading}
        width={850}
    >
        <Form 
            form={form}
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 18 }}
            onFinish={saveProject}
        >
            <Form.Item
                name="parentProject"
                label="Основной проект"
                onKeyPress={(e) => {
                    if (e.key === "Enter") form.submit()
                }}
            >
                <Select
                    showSearch
                    filterOption={filterOption}
                    options= {getOptions()}
                    onSelect={(label, option)=> setParent(option.value)}
                />
            </Form.Item>
            <Form.Item
                name="tariff"
                label="Тариф"
                onKeyPress={(e) => {
                    if (e.key === "Enter") form.submit()
                }}
                rules={[
                    {required: true, message: 'Обязательный параметр'},
                    {
                        pattern: /^[^\s]+(\s+[^\s]+)*$/,
                        message: 'Пробелы в начале и в конце запрещены',
                    }
                ]}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                name="dueDate"
                label="Дата окончания"
                onKeyPress={(e) => {
                    if (e.key === "Enter") form.submit()
                }}
                rules={[
                    {required: true, message: 'Обязательный параметр'},
                ]}
            >
                <DatePicker locale={locale}/>
            </Form.Item>
            <Form.Item
                name="maxProducts"
                label="Товаров"
                onKeyPress={(e) => {
                    if (e.key === "Enter") form.submit()
                }}
                rules={[
                    {required: true, message: 'Обязательный параметр'},
                    {min: 20, max: 100_000, type: 'integer'},
                ]}
            >
                <InputNumber step={1000} min={20} max={500_000}/>
            </Form.Item>
        </Form>
    </Modal>
}

export default EditProject;