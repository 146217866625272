import React, {useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {Button, Card, Dropdown, Form, Image, Input, Menu, Modal, PageHeader, Space, Table, Upload} from 'antd';
import {MinusCircleOutlined, MoreOutlined, PlusOutlined} from '@ant-design/icons';
import _ from 'lodash'

import ProductService from "../../API/ProductService";
import {useFetching} from '../../hooks/useFetching';
import Help from "../Common/Help";
import FilterComponent from "../Common/FilterComponent";
import AttributeService from "../../API/AttributeService";
import trans from '../../languages/ruTranslate';

export default function Products() {
    const [selectList, setSelectList] = useState();   
    const [data, setData] = useState([]);
    const [pageSize, setPageSize] = useState(100);
    const [current, setCurrent] = useState(1);
    const [search, setSearch] = useState({text: "", filters: [], _prepared: false});
    const [total, setTotal] = useState(10);
    const { showBoundary } = require('react-error-boundary').useErrorBoundary()
    const [fetchProducts, loading] = useFetching( async () => {
        const result = await ProductService.searchProducts({current, pageSize}, search);
        setTotal(result.count);
        setData(result.data);
    })
    const [sourceFields, setSourceFields] = useState([]);
    const [form] = Form.useForm();
    const navigate = useNavigate();

    useEffect(() => {
        AttributeService.getAttributeNames()
            .then(attributes => setSourceFields(attributes))
    }, []);

    const { state } = useLocation();

    const setStoredFilters = (filterStr) => {
        const searchFilter = JSON.parse(filterStr);
        setSearch(searchFilter)
        form.setFieldsValue(searchFilter)
        searchProducts(searchFilter)
    }

    React.useEffect( () => {
        const filterStr = localStorage.getItem('product-filters');
        if (state && state["feed-filters"]) {
            const filterStr = state["feed-filters"]
            setStoredFilters(filterStr);
        } else if (filterStr) {
            setStoredFilters(filterStr);
        } else {
            setSearch({text: "", filters: []})
        }
    }, [state]);

    React.useEffect( () => {
        if (search._prepared === false)
            return
        fetchProducts();
    }, [current, pageSize, search]);

    const deleteProducts = async (deleteList) => {
        await ProductService.deleteProducts(deleteList);
        fetchProducts()
    }

    function showDeleteConfirm(deleteList) {
        Modal.confirm({
          title: 'Удалить товары?',
          okText: 'Да',
          okType: 'danger',
          cancelText: 'Нет',
          onOk() {
            deleteProducts(deleteList);
          }
        });
    }
    const uploadProducts = async (options) => {
        try{
            await ProductService.uploadProductsFromXls(options.file);
        } catch (e){
            console.log("products loading problem")
            showBoundary(e)
        } finally {
            fetchProducts();
        }
    };

    const searchProducts = filter => {
        setCurrent(1);
        setSearch(filter)
        localStorage.setItem('product-filters', JSON.stringify(filter))
    }

    const handleTableChange = ({current, pageSize}) => {
        setCurrent(current);
        setPageSize(pageSize);
    };

    const additionalMenu = (
        <Menu>
            <Menu.Item>
                <Button 
                    onClick = {() => showDeleteConfirm('ALL')}
                    type='link'
                >
                    Удалить все товары каталога
                </Button>
            </Menu.Item>
        </Menu>
    );

    if (loading) {
        return <div>
            Загрузка данных....
        </div>
    }

    function stringifyCatalogFilters() {
        if (_.isArray(selectList) && !_.isEmpty(selectList)) {
            const idToProduct = _.groupBy(data, x => x._id)
            const productIds = selectList.map(id => idToProduct[id][0].attributes[trans('productId')])
            return JSON.stringify({
                text: "", filters: [{
                    source: trans("productId"),
                    condition: "in",
                    argument: _.join(productIds, ", ")
                }]
            })
        }
        else
            return JSON.stringify(search);
    }

    return <div>
        <PageHeader
            title='Каталог товаров'
            subTitle='загруженные в систему записи '
            extra={[
                <Help page='CATALOG'>Как работать с каталогом?</Help>
            ]}
        />
        <Form
            form={form}
            style={{marginLeft: '25px'}}
            initialValues={search}
            onFinish = {searchProducts}
        >
            <Form.Item name="text" label="Название или ID товара">
                <Input.Search placeholder="введите текст поиска"
                                defaultValue={search.text}
                                allowClear
                                style={{width: 400}}
                                onSearch={(value) => {
                                    const fieldsValue = form.getFieldsValue();
                                    fieldsValue["text"] = value
                                    searchProducts(fieldsValue);
                                }}
                                key='text'
                />
            </Form.Item>
            <Form.List name="filters">
                {(fields, {add, remove}) => (
                        <>
                            {fields.map(({key, name}) =>
                                <Space key={key} style={{display: 'flex', marginBottom: 8}} align="baseline">
                                    <FilterComponent name={name} sourceFields={sourceFields}/>
                                    <MinusCircleOutlined className="dynamic-delete-button"
                                                         onClick={() => remove(name)}/>
                                </Space>)}
                            <Space>
                                <Form.Item>
                                    <Button type="dashed" onClick={() => add()} icon={<PlusOutlined/>}>
                                        Добавить условие
                                    </Button>
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit">Найти</Button>
                                </Form.Item>
                            </Space>
                        </>
                )}
            </Form.List>
        </Form>
        <span style={{float:'left', marginLeft:'30px', marginTop:'15px'}}>Товаров: <b>{total}</b></span>
        <Space style={{float: 'right', marginRight: '20px'}}>
            <Upload
                    customRequest = {uploadProducts}
                    showUploadList = {false}
                >
                    <Button>Загрузить из Excel</Button>
            </Upload>
            <Button onClick={async () => await ProductService.loadProductsToXls(search, selectList)}>Сохранить в Excel</Button>
            <Button onClick={() => showDeleteConfirm(selectList)}>Удалить</Button>
            <Button type='primary' onClick={() => {
                navigate("../exports/create", {state: {"catalog-filters": stringifyCatalogFilters()}})
            }}>Создать Экспорт</Button>
            <Dropdown  key="more" overlay={ additionalMenu } placement="bottomRight">
                <Button type="text" icon={<MoreOutlined style={{ fontSize: 20 }} />} />
            </Dropdown>   
        </Space>
        <div style= {{margin: '40px 30px'}}>
            { !loading &&  ( data.length ? 
                <Table
                    rowKey={p => p._id}
                    dataSource={data}
                    rowSelection={{
                        type: 'checkbox',
                        onChange: (selectedRowKeys, selectedRows) => {
                            setSelectList(selectedRowKeys)
                        }                 
                    }}
                    columns={[
                        {
                            dataIndex: ['attributes', 'Изображения'],
                            render: (text, item) => <Image
                                width={40}
                                src={Array.isArray(text) ? text[0] : text }
                            />                    
                        },
                        {
                            title: 'ID товара',
                            dataIndex: ['attributes','ID товара'],
                            render: (text, item) => 
                                <Button type='link' onClick={ () => navigate(item._id)}>
                                    {text ? text : item.productId}
                                </Button>,
                        }, {
                            title: 'Товар',
                            dataIndex: ['attributes', trans('name')],
                            render: (text, item) =>
                                    <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', display : 'inline-block',  width: '400px', textAlign : 'left'}}>
                                        {text ? text : item.productId}
                                    </span>
                        }, {
                            title: 'Вариант',
                            dataIndex: ['attributes', trans('variation')]
                        }, {
                            title: 'Категория',
                            dataIndex: ['attributes', trans('category')]
                        }, {
                            title: 'Цена',
                            dataIndex: ['attributes', trans('price')]
                        },  {
                            title: 'Остаток',
                            dataIndex: ['attributes', trans('stock')]
                        }
                    ]}
                    defaultExpandAllRows={false}
                    pagination={ {current, total, pageSize} }
                    key="table"
                    onChange={handleTableChange}
                />
                : <Card>
                    <h4 style={{marginBottom:'100px'}}>Товары не найдены</h4>
                    <p><b>Принцип работы сервиса:</b></p>
                    <p>1. Вы загружаете данные о товарах из Excel или различных источников данных: <Link to='../imports/create'>добавить источник</Link>.</p>
                    <p>2. Вы редактируете данные о товарах при необходимости добавляете новые атрибуты.</p>
                    <p>3. Вы экспортируете товары на нужные торговые площадки: <Link to='../exports/create'>создать экспорт</Link>.</p>
                </Card>
        )}
        </div>
    </div>;
  }