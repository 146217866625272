import React, { useState }  from 'react';
import {Table, Button, Input, Empty, Space} from 'antd';

import { useFetching } from '../../hooks/useFetching';
import ProductService from '../../API/ProductService';
import ProposalCard from './ProposalCard';
import trans from '../../languages/ruTranslate';
import FeedService from "../../API/FeedService";


export default function FeedData(props) {
    const feedId = props.data._id;
    const mapping = props.data.mapping;
    const [data, setData] = useState([]);
    const [search, setSearch] = useState("");
    const [productId, openProduct] = useState();
    const [pageSize, setPageSize] = useState(100);
    const [current, setCurrent] = useState(1);
    const [total, setTotal] = useState(10);
    const [fetchProducts, loading] = useFetching( async () => {
        const result = await ProductService.getProposals({feedId, current, pageSize, search});
        setTotal(result.count);
        setData(result.data);
    })

    React.useEffect( () => {
        fetchProducts();
    }, [current, pageSize, search]);

    const handleTableChange = ({current, pageSize}) => {
        setCurrent(current);
        setPageSize(pageSize);
    }; 

    
    const idColumn = {
        title: trans('productId'),
        key: 'productId',
        dataIndex: ['productId'],
        render: (text, item) => 
        <Button type='link' onClick={ () => openProduct(item._id)}>
            <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', display : 'inline-block', textAlign : 'left'}}>
                {text}
            </span>
        </Button>,
    };
    const attributeColumns = mapping?.filter(item => (item.name !== trans('productId')) && !item.categoryDepended).map(item => ({
        title: item.name,
        key: item.name,
        dataIndex: ['attributes', item.name],
        render: text => (
            <div style={{'text-overflow': 'ellipsis', width: '200px', 'white-space': 'nowrap', overflow: 'hidden'}}>{text?.toString()}</div>
        )
    }))
    const columns = [idColumn, ...attributeColumns];   
  
    return ( productId 
        ? <ProposalCard proposalId={productId} openProduct={openProduct}/>
            : <div>
                {!loading &&
                    <div>
                        <Space style={{float: 'right', marginBottom: '10px'}}>
                            <Button onClick={async () => await FeedService.downloadDataToXls(props.data.name, feedId, search)}>
                                Сохранить в Excel
                            </Button>
                            <Input.Search
                                placeholder="введите текст поиска"
                                defaultValue={search}
                                allowClear
                                onSearch={text => {
                                    setSearch(text.toLowerCase());
                                    setCurrent(1)
                                }}
                                key='search'
                            />
                        </Space>
                        {data.length ?
                        <Table
                            rowKey={p => p._id}
                            scroll={{x: true}} // this causes error 'ResizeObserver loop completed' on empty data
                            dataSource={data}
                            columns={columns}
                            pagination={{current, total, pageSize}}
                            key="table"
                            onChange={handleTableChange}
                        />
                        : <Empty description='Нет загруженных данных'/>}
                    </div>
                }
            </div>
    )
}
