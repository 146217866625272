import api from "./ServerAPI";
import {download} from "../utils/Files";

export default class ProductService {
    static async searchProducts(paging, filter){
        const response = await api.post("/products/search", filter, { params : paging });
        return response.data
    }
    
    static async getProduct(productId){
        const response = await api.get(`/products/${productId}`);
        return response.data
    }

    static async updateProductAttribute(productId, key, value){
        await api.post(`/products/${productId}/attributes`, {key: key, value: value});
    }

    static async deleteProductAttribute(productId, key){
        await api.delete(`/products/${productId}/attributes/${key}`);
    }

    static async deleteProducts(selectList){
        await api.delete(`/products`, { data: { selectList } });
    }

    static async uploadProductsFromXls(file){
        const formData = new FormData();
        formData.append('file', file);
        const response =  await api.post('/uploadProductsFromXls', formData, { headers: {"Content-Type": "multipart/form-data"}});
        return response.data;
    }

    static  async loadProductsToXls(search, selectedList){
        const response = await api.get(`/loadProductsToXls`, {
            responseType : 'blob',
            params : { search: {...search, selected: selectedList } }
        })
        download(response.data, `databird.xlsx`, 'application/vnd.ms-excel');
    }

    static async getProposals(filter){
        const response = await api.get("/proposals", { params : filter });
        return response.data
    }

    static async getConflicts(filter){
        const response = await api.get("/conflicts", { params : filter});
        return response.data
    }

    static async getProposal(proposalId){
        const response = await api.get(`/proposals/${proposalId}`);
        return response.data
    }
}