import api from "./ServerAPI";
import {download} from "../utils/Files";

export default class OrderService {
    static async searchOrders(paging, filter){
        const response = await api.post("/orders/search", filter, { params : paging });
        return response.data
    }
    
    static async getOrder(orderId){
        const response = await api.get(`/orders/${orderId}`);
        return response.data
    }

    static  async loadOrdersToXls(search, selectedList){
        const response = await api.get(`/loadOrdersToXls`, {
            responseType : 'blob',
            params : { search: {...search, selected: selectedList } }
        })
        download(response.data, `databird-orders.xlsx`, 'application/vnd.ms-excel');
    }
}