import React from 'react';
import {Form, Input, Select, Space} from 'antd';
import FieldSelector from "../Common/FieldSelector";


const roundRange = ["0.001", "0.01", "0.1", "1", "10", "100", "1000"];
const operations = [
    {op: "add", val: "Прибавить"},
    {op: "subtract", val: "Отнять"},
    {op: "multiply", val: "Умножить"},
    {op: "divide", val: "Разделить"},
    {op: "round", val: "Округлить до"},
    {op: "concat", val: "+ строка"},
    {op: "trim", val: "Обрезать до"},
    {op: "concatAttr", val: "+ атрибут"},
]
const numberOperations = ["add", "subtract", "multiply", "divide", "trim"]
const attributeOperations = operations.filter(x => x.op.endsWith("Attr")).map(x => x.op)


export default function RuleComponent(props) {
    const form = props.form
    const name = props.name
    const sourceFields = props.sourceFields
    function handleOperationChange(operation) {
        const argumentValue = form.getFieldValue(["rules", name, "argument"]);
        if (operation === "round" && roundRange.indexOf(argumentValue) < 0) {
            form.setFieldValue(["rules", name, "argument"], "1")
        }
        // if (attributeOperations.)
    }

    return <Space>
        <Form.Item name={[name, "operation"]} style={{width: 130}} rules={[{ required: true, message: 'Задайте операцию' }]}>
            <Select onChange={handleOperationChange}>
                {operations.map(item => (<Select.Option key={item.op}
                                                        value={item.op}>{item.val}</Select.Option>))}
            </Select>
        </Form.Item>

        <Form.Item noStyle shouldUpdate>
            {({getFieldValue}) => {
                if (attributeOperations.indexOf(getFieldValue(["rules", name, "operation"])) >= 0)
                    return <Form.Item name={[name, "argument"]} style={{width: '150'}}>
                        <FieldSelector sourceFields={sourceFields}/>
                    </Form.Item>
                else if (getFieldValue(["rules", name, "operation"]) === "round") {
                    return <Form.Item name={[name, "argument"]} style={{width: 300}}>
                        <Select>
                            {roundRange.map(item => <Select.Option key={item}
                                                                   value={item}>{item}</Select.Option>)}
                        </Select>
                    </Form.Item>
                } else
                    return <Form.Item name={[name, "argument"]} style={{width: 300}}
                                      dependencies={[["rules", name, "operation"]]}
                                      rules={[{
                                          validator: (_, value) =>
                                              numberOperations.indexOf(getFieldValue(["rules", name, "operation"])) < 0
                                              || value.trim() && !isNaN(value.trim()) ? Promise.resolve() : Promise.reject(new Error()),
                                          message: "Значение должно быть числом"
                                      }]}>
                        <Input/>
                    </Form.Item>
            }
            }
        </Form.Item>
    </Space>
}