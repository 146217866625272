import {Form, Input, Modal} from 'antd';

function AddProject({create}) {

    const [form] = Form.useForm()

    return <Modal
        title="Создание проекта"
        okText={'Сохранить'}
        cancelText={'Отмена'}
        open={true}
        onOk={() => create(form.getFieldsValue())}
        onCancel={() => create()}
    >            
    <Form
        form={form}
        labelCol={{span: 8}}
        wrapperCol={{span: 16}}
        onFinish={create}
    >
        <Form.Item
            label="Название проекта"
            name="name"
            rules={[
                {required: true, message: 'Введите название проекта!'},
            ]}
        >
            <Input/>
        </Form.Item>
    </Form>
    </Modal>
}

export default AddProject;