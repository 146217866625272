import React, {useState, useEffect} from 'react';
import moment from 'moment';
import { Form, Button, Space, TimePicker, InputNumber, Select } from 'antd';
import FeedService from "../../API/FeedService";
import trans from '../../languages/ruTranslate';

const ScheduleForm = ({feed, disabled}) => {
    const { showBoundary } = require('react-error-boundary').useErrorBoundary()
    const [form] = Form.useForm();
    const [schedule, setSchedule] = useState({});

    useEffect( () => {
        const schd = {...feed.schedule}
        if (disabled)
            schd.period = "manual"
        setSchedule(schd)
    }, [feed, disabled] );
    useEffect( () => form.resetFields(), [schedule]);

    const saveSchedule = async (values) => {
        try{
        await FeedService.saveSchedule(feed._id, values);
            setSchedule(values);
        } catch(e){
            showBoundary(e)
        }
    }

    const TimePickerString = ({value, onChange}) => {
        const mtime = value ? moment(value, "HH:mm") : moment('00:00', "HH:mm")

        return <TimePicker 
                    format= "HH:mm"
                    onChange={ (time, timeString) => onChange(timeString)}
                    value={mtime}
                />
    }

    return (
        <Form
            form = {form}
            layout="inline"
            initialValues = {schedule}
            onFinish = {saveSchedule}
        >
            <Form.Item
                label="Период загрузки"
                name="period"
            >
                <Select placeholder='выберите период' style={{ width: 120 }} disabled={disabled}>
                    <Select.Option key='manual'>{trans('manual')}</Select.Option>
                    <Select.Option key='timer'>{trans('timer')}</Select.Option>
                    <Select.Option key='hourly'>{trans('hourly')}</Select.Option>
                    <Select.Option key='daily'>{trans('daily')}</Select.Option>
                    <Select.Option key='weekly'>{trans('weekly')}</Select.Option>
                    <Select.Option key='monthly'>{trans('monthly')}</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item shouldUpdate>
                { () => (
                    <Space>
                        <Form.Item
                            name="dayOfWeek"
                            hidden = {form.getFieldValue('period') !== 'weekly'}
                        >
                            <Select placeholder = 'День недели'>
                                <Select.Option key={1}>Понедельник</Select.Option>
                                <Select.Option key={2}>Вторник</Select.Option>
                                <Select.Option key={3}>Среда</Select.Option>
                                <Select.Option key={4}>Четверг</Select.Option>
                                <Select.Option key={5}>Пятница</Select.Option>
                                <Select.Option key={6}>Суббота</Select.Option>
                                <Select.Option key={7}>Воскресенье</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="dayOfMonth"
                            hidden={form.getFieldValue('period') !== 'monthly'}
                        >
                            <InputNumber placeholder = 'Day' max={31} min={1}/>
                        </Form.Item>
                        <Form.Item
                            hidden={['weekly', 'monthly', 'daily', 'timer', 'hourly'].indexOf(form.getFieldValue('period')) === -1}
                            name = 'time'
                        >
                            <TimePickerString />
                        </Form.Item>
                    </Space>
                )}
            </Form.Item>
            <Form.Item shouldUpdate>
                { () => (
                    <Space>
                        <Button
                            type="primary"
                            htmlType="submit"
                            disabled={disabled || !form.isFieldsTouched()}
                        >
                            Сохранить
                        </Button>
                        <Button
                            onClick={ () => form.resetFields() }
                            disabled={disabled || !form.isFieldsTouched()}
                        >
                            Отмена
                        </Button>
                    </Space>
                )}
            </Form.Item>
        </Form>
    );
};

export default ScheduleForm;