import React from 'react';
import {Typography} from 'antd';
import ruHelp from '../../languages/ruHelp'

const {Link} = Typography

export default function HelpLink({page, children}) {
    return <Link href={`https://databirds.notion.site/${ruHelp(page)}`} target='_blank'>
        {children}
    </Link>
}
