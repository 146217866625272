const strings = {
    // Роли пользователя
    'user' : 'пользователь',
    'admin' : 'администратор',
    'owner' : 'владелец',

    // Статус загрузки
    'failed': 'ошибка',
    'check' : 'проверка настроек',
    'test': 'тест',
    'ok': 'ок',

    // Периоды загрузки
    'manual': 'вручную',
    'timer': 'таймер',
    'hourly': 'ежечасно',
    'daily': 'ежедневно',
    'weekly': 'еженедельно',
    'monthly': 'раз в месяц',

    // Ошибки записей: типы
    're_expression_error' : 'Ошибка в вычислениях',
    're_dublicate': 'Запись продублирована',
    're_bad_mapping':'Ошибка мэппинга',
    're_no_id': 'Нет id товара',
    're_no_category': 'Не задана категория',
    're_category_not_exists': 'Не существует категория',
    're_no_attribute': 'Не задан обязательный атрибут',
    're_wrong_value' : 'Неправильное значение',
    're_wrong_attribute' : 'Неправильное значение',
    're_other': 'Другая ошибка',

    // Ошибки записей: шаблоны ошибок
    're_expression_error_template' : 'Ошибочное выражение: %EXPRESSION%',     // Ошибка в вычислениях
    're_dublicate_template': 'Такой ID товара уже существует: %PRODUCT_ID%',  // Запись продублирована
    're_no_id_template': 'Не настроен ID товара',                                // Не задан id продукта
    're_no_category_template': 'Не задана категория',                         // Не задана категория
    're_category_not_exists_template': 'Категория "%CATEGORY_NAME%"',// Не существует категория
    're_no_attribute_template': 'Атрибут "%ATTRIBUTE_NAME%"',          // Не задан обязательный атрибут
    're_wrong_value_template' : 'Атрибут "%ATTRIBUTE_NAME%": %VALUE%', // Атрибут не может принимать это значение
    're_wrong_attribute_template' : 'Атрибут "%ATTRIBUTE_NAME%": %VALUE%; %HINT%', // Атрибут не может принимать это значение с указанием возможных
    're_other_template': '%ERROR_DETAILS%',                                     // Другая ошибка

    'fe_wrong_creds' : 'Ошибочный токен или пароль',
    'fe_wrong_url' : 'Ошибочный адрес',
    'fe_wrong_config_value' : 'Неправильные настройки',
    'fe_wrong_usage' : 'Неправильное использование',
    'fe_wrong_values' : 'Неправильные значения',
    'fe_unknown' : 'Неизвестная ошибка',
    'fe_project_over_due' : 'Необходимо продлить тариф',
    'fe_project_product_limited' : 'Максимальное число товаров в каталоге ограничивает импорт',
    'fe_empty_file' : 'Не смогли получить данные',

    'fe_wrong_creds_template' : 'Проверьте настройки',
    'fe_wrong_url_template' : 'Такого ресурса не существует: %ADDRESS%',
    'fe_wrong_config_value_template' : 'Не правильное значение в настройках: "%CONFIG_PARAM_NAME%"',
    'fe_wrong_usage_template' : '%ERROR_MESSAGE%',
    'fe_wrong_values_template' : '%ERROR_MESSAGE%',
    'fe_unknown_template' : '%ERROR_MESSAGE%',
    'fe_project_over_due_template' : 'Пожалуйста, оплатите тариф',
    'fe_project_product_limited_template' : '%NUMBER_OF_PRODUCTS% товаров не поместилось в каталог',
    'fe_empty_file_template' : 'Неверные настройки источника',

    // Синонимы атрибутов
    'productId' : 'ID товара',
    'name': 'Название',
    'vendor': 'Бренд',
    'vendorCode' : 'Артикул',
    'variation': 'Вариант',
    'images': 'Изображения',
    'category': 'Категория',
    'price': 'Цена',
    'stock': 'Остаток',
    'description': 'Описание', 
    'length' : 'Длина упаковки', 
    'width' : 'Ширина упаковки', 
    'height' : 'Высота упаковки', 
    'weight' : 'Вес в упаковке', 
    'creationDate' : 'Дата создания',
    'barcode' : 'Штрихкод',
    'source' : 'Источник',

    // Статус заказов
    // WB statuses
    'new' : 'новый',
    'confirm' : 'На сборке',
    'complete' : 'В доставке',
    'cancel' : 'Отменено продавцом',
    // Ozon statuses
    'acceptance_in_progress' : 'идёт приёмка',
    'arbitration' : 'арбитраж',
    'awaiting_approve' : 'ожидает подтверждения',
    'awaiting_deliver' : 'ожидает отгрузки',
    'awaiting_packaging' : 'ожидает упаковки',
    'awaiting_registration' : 'ожидает регистрации',
    'awaiting_verification' : 'создано',
    'cancelled' : 'отменено',
    'cancelled_from_split_pending' : 'отменён из-за разделения отправления',
    'client_arbitration' : 'клиентский арбитраж доставки',
    'delivering' : 'доставляется',
    'driver_pickup' : 'у водителя',
    'not_accepted' : 'не принят на сортировочном центре',
    'sent_by_seller' : 'отправлено продавцом',
    // YM statuses
    'CANCELLED': 'отменен',
    'DELIVERED': 'получен покупателем',
    'DELIVERY': 'передан в службу доставки',
    'PICKUP': 'доставлен в пункт самовывоза',
    'PROCESSING': 'находится в обработке',
    'PENDING': 'ожидает обработки со стороны продавца',
    'UNPAID': 'оформлен, но еще не оплачен (если выбрана оплата при оформлении)',
    'PLACING': 'оформляется, подготовка к резервированию',
    'RESERVED': 'зарезервирован, но недооформлен',
    'PARTIALLY_RETURNED': 'возвращен частично',
    'RETURNED': 'возвращен полностью',
    'UNKNOWN': 'неизвестный статус',
    // MM statuses
    'NEW': 'ожидает подтверждения',
    'CONFIRMED': 'подтверждено продавцом',
    'PACKED': 'укомплектовано продавцом',
    'SHIPPED': 'отгружено продавцом',
    // 'DELIVERED': 'заказ выполняется',
    'CONFIRMATION_EXPIRED': 'время на подтверждение просрочено',
    'PACKING_EXPIRED': 'время на комплектацию просрочено',
    'SHIPPING_EXPIRED': 'отгрузка просрочена',
    'MERCHANT_CANCELED': 'отменено продавцом',
    'CUSTOMER_CANCELED': 'отменено покупателем',
};

export default function getName(name) {
    return strings[name]
}