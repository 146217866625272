import React, {useState} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {Tabs, PageHeader, Button, Upload, Dropdown, Menu, Row, Col, Badge, Modal, Spin, Space, Tooltip} from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import moment from 'moment';

import trans from '../../languages/ruTranslate';
import { useFetching } from '../../hooks/useFetching';
import FeedService from "../../API/FeedService";
import FeedConfig from "./FeedConfig";
import MappingList from "../Mapping/MappingList";
import FeedData from "../Products/FeedData";
import FeedErrors, {generateTranslatedErrorMessage, translateErrorType} from "../Products/FeedErrors";
import RenameCopyDialog from "./RenameCopyDialog";
import FolderToChoose from "../Common/Folder/FolderToChoose";
import {useNavigateWithSearch} from "../../hooks/navigation";
import {download} from "../../utils/Files";


const { TabPane } = Tabs;

export default function Feed() {
    const [data, setData] = useState({state: {}})
    const [spinning, setSpinning] = useState(false);
    const [readFromFile, setReadFromFile] = useState();
    const [renameCopy, setRenameCopy] = useState();
    const [chooseFolder, setChooseFolder] = useState(false);
    const navigate = useNavigateWithSearch();
    const [activeTabKey, setActiveTabKey] = useState('mapping');
    const [attributeToEdit, setAttributeToEdit] = useState();
    const { feedId } = useParams();
    const [searchParams] = useSearchParams();
    const { showBoundary } = require('react-error-boundary').useErrorBoundary()
    const [fetchFeed, isLoading, error] = useFetching( async () => {
        const response = await FeedService.getFeed(feedId);
        if (!response.feedParameters) {
            response.feedParameters = {}
        }
        setData(response);
        setReadFromFile(response.config?.readFromFile)
        setSpinning(response.isLoading);
    })

    React.useEffect(() => {
        fetchFeed();        
    }, [feedId]);

    React.useEffect(() => {
        const tab = searchParams.get("tab");
        if (tab) {
            setActiveTabKey(tab)
        }
    }, []);

    if (isLoading) {
        return <div>
            Загрузка данных....
        </div>
    }

    function openAttributeMapping(attributeName) {
        setActiveTabKey("mapping")
        setAttributeToEdit(attributeName)
    }

    function getDate(dt){
        return dt ? moment(dt).format("DD.MM.YY HH:mm") : ""
    }

    const customRequest = async (options) => {
        setSpinning(true);
        const { onSuccess, onError, file, onProgress } = options;
        try{
            await FeedService.feedUpload(file, data._id, onProgress);
            onSuccess();
        } catch (e){
            console.log("feed sync problem")
            showBoundary(e)
        } finally {
            await fetchFeed();
        }
    }

    function showRunConfirm() {
        Modal.confirm({
          title: 'Запуcтить импорт?',
          content: 'Импорт обновит данные фида.',
          okText: 'Да',
          okType: 'danger',
          cancelText: 'Нет',
          onOk() {
            syncFeed()
          }
        });
    }

    const syncFeed = async () => {   
        setSpinning(true);
        try {
            await FeedService.feedSync(data._id)
        } catch (e){
            console.log("feed sync problem")
            showBoundary(e)
        } finally {
            await fetchFeed();
        }
    }

    const moveFeedFunc = async (newParentFolderId) => {
        data.parentFolderId = newParentFolderId
        await FeedService.updateFeed(feedId, {parentFolderId: newParentFolderId})
        setChooseFolder(false)
    }

    const saveFeedToJson = () => {
        download(JSON.stringify(data), `${data.name}.json`, 'application/json')
    }

    const menu = (
        <Menu>
            <Menu.Item>
                <Button type='link' key="1" onClick = { () => setRenameCopy('rename')}>
                    Переименовать
                </Button>
            </Menu.Item>
            <Menu.Item>
                <Button type='link' key="2" onClick = { () => setChooseFolder(true)}>
                    Переместить
                </Button>
            </Menu.Item>
            <Menu.Item>
                <Button type='link' key="3" onClick = { () => setRenameCopy('copy')}>
                    Скопировать
                </Button>
            </Menu.Item>
            <Menu.Item>
                <Upload
                    maxCount = {1}
                    customRequest = {customRequest}
                    showUploadList = {{ showRemoveIcon : false }}
                >
                    <Button type='link'>Загрузить из файла</Button>
                </Upload>
            </Menu.Item>
            <Menu.Item>
                <Button type='link' key="4" onClick = { () => saveFeedToJson()}>
                    Конфигурация
                </Button>
            </Menu.Item>
        </Menu>
    );

    return <div>
        <PageHeader
            onBack={ () => navigate('../imports', {searchParams: {folderId: data.parentFolderId}}) }
            title = { data.name }
            subTitle = { data.type }
            extra={[
                <Spin style={{marginRight: '20px'}} size='small' spinning={spinning}/>,
                <Upload
                    maxCount={1}
                    customRequest={customRequest}
                    showUploadList={{showRemoveIcon: false}}
                >
                    <Button type='primary' hidden={!readFromFile}>Загрузить из файла</Button>
                </Upload>,
                <Button disabled={spinning}
                        type='primary'
                        hidden={readFromFile}
                        onClick={() => showRunConfirm()}>Загрузить</Button>,
                <Dropdown key="more" overlay={menu} disabled={spinning} placement="bottomRight">
                    <Button type="text" icon={<MoreOutlined style={{fontSize: 20}}/>}/>
                </Dropdown>
            ]}
        />   
        <div style= {{margin: '10px 30px'}}>
            <Row>
                <Col span={3}>Успешный импорт:</Col>
                <Col>
                        {data.lastSync ? `${getDate(data.lastSync?.startedAt)} (${Math.floor(data.lastSync?.extractDuration / 1000)} сек.)`
                                       : "данные не загружались"
                        }
                </Col>         
            </Row>
            <Row>
                <Col span={3}>Последняя загрузка:</Col>
                <Col span={3}>            
                    <span style={{ color : data.state?.state === 'failed' ? 'red' : 'green'}}>
                        {getDate(data.state?.date)}
                    </span>
                </Col>       
            </Row>
            <Row>
                <Col span={3}>Статус загрузки:</Col>
                <Col>
                    {data.state?.state === 'failed'
                        ? <Space>
                            <span style={{color: 'red'}} title={generateTranslatedErrorMessage(data.state, "fe")}>
                                {data.state.errorType ? translateErrorType(data.state.errorType, "fe") : trans(data.state.state) }
                            </span>
                            <Tooltip title={generateTranslatedErrorMessage(data.state, "fe")} trigger={["click", "hover"]}>
                                <a>Детали</a>
                            </Tooltip>
                        </Space>
                        : data.state?.state
                            ? <span style={{color: 'green'}}>{trans(data.state?.state)}</span>
                            : <span>НЕТ</span>
                    }
                </Col>
            </Row>
        </div>
        <Tabs
            style= {{margin: '20px 30px'}}
            activeKey={activeTabKey}
            onChange={ key => {
                setActiveTabKey(key);
            }}
            destroyInactiveTabPane = {true}
        >       
            <TabPane tab="Атрибуты" key = "mapping"><MappingList data={data} fetchFeed={fetchFeed} attributeToEdit={attributeToEdit}/></TabPane>
            <TabPane 
                tab= {<Badge count=  {data.lastSync?.inserted} offset={[8, -6]} color='blue' overflowCount={100000}>Данные</Badge>}
                key = "data"
            >
                <FeedData data={data} />
            </TabPane>   
            <TabPane
                tab= {<Badge count=  {data.lastSync?.mistakes} offset={[8, -6]} color='red' overflowCount={100000}>Ошибки</Badge>}
                key = "errors"
            > 
                <FeedErrors feedDoc={data} openAttributeMapping={openAttributeMapping}/>
            </TabPane>
            <TabPane tab="Настройки" key="config">
                <FeedConfig data={data}
                            fetchFeed={fetchFeed}
                            onConfigChange={(value) => setReadFromFile(value.readFromFile)}/>
            </TabPane>
        </Tabs>
        {renameCopy ? <RenameCopyDialog feed={data} mode={renameCopy} setMode={setRenameCopy} fetchFeed={fetchFeed}/> : null}
        <FolderToChoose
            stream={'import'}
            open={chooseFolder}
            defaultValue={data.parentFolderId}
            onChosenFolder={moveFeedFunc}
            onCancel={() => setChooseFolder(false)}
        />
    </div>
}
