import React, {useState} from "react";
import { Tabs, PageHeader, Row, Col } from 'antd';
import {ArrowLeftOutlined} from '@ant-design/icons';

import { useFetching } from '../../hooks/useFetching';
import ProductService from "../../API/ProductService";
import trans from '../../languages/ruTranslate';

const { TabPane } = Tabs;

export default function ProposalCard(props) {
    const [data, setData] = useState()
    const [activeTabKey, setActiveTabKey] = useState('attributes');
    const { proposalId, openProduct, displayBackIcon } = props;
    const [fetchProposal] = useFetching( async () => {
        const data = await ProductService.getProposal(proposalId);
        setData(data);
    })

    React.useEffect(() => {
        fetchProposal();
    }, []);

    const RowTable = ({source}) => {
        if (!source) return <div/>
        const keys = Object.keys(source);
        if ( !keys.length )return <div/>;
        return (keys.map(key => (                          
            <Row wrap style={{marginTop : '10px', wordWrap: 'break-word'}} key = {key}>          
                <Col  span={8}><b>{key}</b></Col>
                <Col  span={16}>{source[key] !== null ? JSON.stringify(source[key]) : ''}</Col>
            </Row>
        )))
    }

    if (!data) return <div/>;

    return <div>
        <PageHeader key='pageheader'
            onBack={ () => openProduct(null) }
            backIcon={ displayBackIcon === undefined || displayBackIcon ? <ArrowLeftOutlined /> : false }
            title = { data.productId }
        />  
        { data && 
            <Tabs
                style= {{margin: '20px 30px'}}
                defaultActiveKey={activeTabKey}
                onChange={ key => {
                    setActiveTabKey(key);
                }}
            >
                <TabPane tab="Атрибуты" key = "attributes">
                   <RowTable source={data.attributes}/>
                </TabPane>
                <TabPane tab="Исходные поля" key = "source">
                    <RowTable source={data.rawData}/>        
                </TabPane>    
            </Tabs>
        }
    </div>
}
