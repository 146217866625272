import React from "react";
import {Link} from "react-router-dom";
import {List} from 'antd';
import {FolderOutlined} from '@ant-design/icons';

export default function FolderList({stream, folders}) {
    return <div>
        {folders.length > 0 ? <List
            style={{margin: '20px 30px'}}
            itemLayout="horizontal"
            dataSource={folders}
            rowKey={folder => folder._id}
            renderItem={folder => (
                <List.Item key={folder._id}>
                    <List.Item.Meta
                        title={<><FolderOutlined/> <Link
                            to={`../${stream}s?folderId=${folder._id}`}>{folder.name}</Link></>}
                    />
                </List.Item>
            )}
        /> : null}
    </div>
};
