import React from 'react';
import ReactDOM from 'react-dom';
import {
    BrowserRouter,
} from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";

import './index.less';
import App from './App';
import FallbackComponent from './components/FallbackComponent'

ReactDOM.render(
    <ErrorBoundary FallbackComponent={FallbackComponent}
                   onError={(e, i) => console.error(e, i)}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </ErrorBoundary>
    ,
    document.getElementById('root')
);