import { useState } from 'react';
import { Form, Input, Modal } from 'antd';

import UserService from '../../API/UserService';

export default function UserChangePassword(props) {
    const { setEditUser } = props;
    const { showBoundary } = require('react-error-boundary').useErrorBoundary()

    const [form] = Form.useForm();

    const [confirmLoading, setConfirmLoading] = useState(false);

    const OnOkClicked = async () => {
        const passwords = await form.validateFields();

        try {
            setConfirmLoading(true);
            await UserService.changePassword(passwords);
            setConfirmLoading(false);
            setEditUser(false);
        } catch (ex) {
            console.log('Changing password problem: ' + ex);
            showBoundary(ex)
        }
    }

    const onCancelClicked = async () => setEditUser(false);

    return (
        <Modal
            title={'Изменение пароля'}
            open={true}
            okText={'Сохранить'}
            cancelText={'Отмена'}
            onOk={() => OnOkClicked()}
            onCancel={() => onCancelClicked()}
            confirmLoading={confirmLoading}
        >
            <Form layout='vertical' form={form}>
                <Form.Item name='oldPassword' label='Предыдущий пароль'
                           rules={[{ required: true, message: 'Введите предыдущий пароль!' }]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item name='newPassword' label='Новый пароль'
                           rules={[{ required: true, message: 'Введите новый пароль!' }]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item name='confirmPassword' label='Подтвердите новый парль'
                           rules={[{ required: true, message: 'Подтвердите новые парль!' }]}
                >
                    <Input.Password />
                </Form.Item>
            </Form>
        </Modal>
    )
}
