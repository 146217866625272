import api from "./ServerAPI";

export default class FolderService {
    static async getSubFolders(stream, folderId) {
        const response = await api.get(`/subfolders`, {params: {stream, folderId}});
        return response.data
    }
    static async getFolders(stream) {
        const response = await api.get(`/folders`, {params: {stream}});
        return response.data
    }
    static async getFolder(folderId, stream) {
        if (!folderId) {
            return {name: '', stream}
        }
        const response = await api.get(`/folders/${folderId}`);
        return response.data
    }
    static async getSiblings(folderId) {
        const response = await api.get(`/folders/${folderId}/siblings`);
        return response.data
    }
    static async addFolder(folder) {
        folder = Object.assign({}, folder);
        const response = await api.post('/folders', folder);
        return response.data
    }
    static async updateFolder(folder) {
        folder = Object.assign({}, folder);
        const response = await api.patch(`/folders/${folder._id}`, folder);
        return response.data
    }
    static async deleteFolder(folderId) {
        const response = await api.delete(`/folders/${folderId}`);
        return response.data
    }
}