import {Input, Button, Space, Form} from 'antd';
import jsonata from 'jsonata';
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';

import RuleComponent from "./RuleComponent";

export default function RuleList(props) {
    const form = props.form
    const sourceFields = props.sourceFields
    return <Form.List name="rules">
        {(fields, { add, remove }) => (
            <>
                {fields.map(({ key, name }) =>
                    <Space key={key} style={{display: 'flex', marginBottom: 8}} align="baseline">
                        {form.getFieldValue(["rules", name]).isJsonata ?
                            <Form.Item style={{width: "700px"}}
                                       name={[name, "operation"]}
                                       rules={[{
                                           validator: async (_, value) => value && jsonata(value),
                                           message: "Неверный формат правила"
                                       }]}
                            >
                                <Input.TextArea
                                    rows={2}
                                    placeholder="ПРАВИЛО НЕ ЗАДАНО"
                                />
                            </Form.Item>
                            :
                            <RuleComponent form={form} name={name} sourceFields={sourceFields}/>}
                        <MinusCircleOutlined className="dynamic-delete-button"
                                             onClick={() => remove(name)}/>
                    </Space>)}
                <Space direction="vertical">

                    <Space>
                        <Form.Item>
                            <Button type="dashed" onClick={() => add({isJsonata: false})}
                                    icon={<PlusOutlined/>}>
                                Добавить операцию
                            </Button>
                        </Form.Item>
                        <Form.Item>
                            <Button type="dashed" onClick={() => add({isJsonata: true, operation: ""})}
                                    icon={<PlusOutlined/>}>
                                Добавить операцию JSONata
                            </Button>
                        </Form.Item></Space>
                </Space>
            </>
        )}
    </Form.List>
}