import React, {useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Alert, Button, PageHeader, Space, Spin, Typography, Upload} from 'antd';
import {useFetching} from '../../../hooks/useFetching';
import FeedService from "../../../API/FeedService";
import {generateTranslatedErrorMessage, translateErrorType} from "../../Products/FeedErrors";
import trans from "../../../languages/ruTranslate";

const {Title, Paragraph} = Typography;


export default function FeedCheck() {
    const [data, setData] = useState({})
    const [checking, setChecking] = useState(false)
    const [externalCheckingInProgress, setExternalCheckingInProgress] = useState()
    const [checkResult, setCheckResult] = useState({})
    const {feedId} = useParams();
    const navigate = useNavigate();
    const {showBoundary} = require('react-error-boundary').useErrorBoundary()
    const [fetchFeed, isLoading, error] = useFetching(async () => {
        const response = await FeedService.getFeed(feedId);
        setExternalCheckingInProgress(response.isLoading)
        setData(response);
    })

    React.useEffect(async () => {
        await fetchFeed();
    }, [feedId]);

    React.useEffect(async () => {
        if (externalCheckingInProgress === undefined || externalCheckingInProgress || !data.config || data.config.readFromFile) {
            return
        }
        setChecking1(true)
        let checkRes;
        try {
            checkRes = await FeedService.feedCheck(data._id);
        } catch (e) {
            setChecking1(false)
            showBoundary(e)
        }
        setChecking1(false)
        setCheckResult(checkRes)
    }, [data]);

    const setChecking1 = (isChecking) => {
        if (isChecking) {
            window.onbeforeunload = function (e) {
                e.preventDefault();
                // Included for legacy support, e.g. Chrome/Edge < 119
                e.returnValue = true;
                return true;
            }
        } else {
            window.onbeforeunload = null
        }
        setChecking(isChecking)
    }

    const checkFileRequest = async (options) => {
        setChecking1(true);
        const {onSuccess, onError, file, onProgress} = options;
        let checkRes
        try {
            checkRes = await FeedService.feedCheckFile(data._id, file, onProgress);
            onSuccess();
        } catch (e) {
            setChecking1(false)
            showBoundary(e)
        }
        setChecking1(false)
        setCheckResult(checkRes)
    }

    if (isLoading) return <div>
        Подождите...
    </div>

    return <div>
        <PageHeader
            onBack={() => navigate(`../imports/${data._id}?tab=config`)}
            title={data.name}
            subTitle={data.type}
        />
        <Space direction='vertical'>
            <Typography>
                <Title>Проверка источника</Title>
                <Paragraph>
                    Пожалуйста, не уходите со страницы, до конца проверки.
                </Paragraph>
            </Typography>
            <div hidden={!data.config?.readFromFile || checkResult.state || externalCheckingInProgress}>
                <Space>Загрузите файл для проверки:
                    <Upload
                        maxCount={1}
                        customRequest={checkFileRequest}
                        showUploadList={false}
                    >
                        <Button type='primary'>Загрузить файл</Button>
                    </Upload></Space>
            </div>
            <div hidden={!checking}>
                <Spin/> Идет проверка...
            </div>
            <div hidden={checkResult.state !== 'ok'}>
                <Alert
                    message="Проверка прошла успешно"
                    description={`Источник содержит ${checkResult.numRecords} записей.`}
                    type="success"
                    action={
                        <Space direction="vertical">
                            <Button size="middle" type="primary"
                                    onClick={() => navigate(`../imports/${data._id}`)}>
                                Перейти к правилам загрузки
                            </Button>
                        </Space>
                    }
                />
            </div>
            <div hidden={externalCheckingInProgress === undefined || !externalCheckingInProgress}>
                <Alert
                    message="Проверка идет в другом окне"
                    description={`Через некоторое время перейдите к правилам загрузки.`}
                    type="warning"
                    action={
                        <Space direction="vertical">
                            <Button size="middle" type="primary"
                                    onClick={() => navigate(`../imports/${data._id}`)}>
                                Перейти к правилам загрузки
                            </Button>
                        </Space>
                    }
                />
            </div>
            <div hidden={checkResult.state !== 'failed'}>
                <Alert
                    message={checkResult.errorType ? translateErrorType(checkResult.errorType, "fe") : trans(checkResult.state)}
                    description={generateTranslatedErrorMessage(checkResult, "fe")}
                    type="error"
                    action={
                        <Space direction="vertical">
                            <Button size="middle" type="primary"
                                    onClick={() => navigate(`../imports/${data._id}?tab=config`)}>
                                Настройка
                            </Button>
                        </Space>
                    }
                />
            </div>
        </Space>
    </div>
}
