import React, {useState} from "react";

import { Button, Input, Form } from 'antd';

import ProjectService from '../../API/ProjectService';

export default function Register({done}) {
    const [message, setMessage] = useState();
    const [form] = Form.useForm();

    const create = async () => {
        try {
        await ProjectService.register(form.getFieldsValue());
        if (done) done()
        else setMessage(
            <div>
                <h3>Проект создан</h3>
                <p>На вашу почту отправлено приглашение для активации доступа.</p>
                <p>Пожалуйста, проверьте, свой почтовый ящик. Письмо может попасть в СПАМ.</p>
                <p>Активируйте ваш акаунт по ссылке в письме.</p>
            </div>
        )
        } catch (e){
            setMessage(
                <div>
                    <h3>Пользователь с таким email уже существует</h3>
                    <p>
                        Перейдите по <a href='login'>ссылке</a> и воостановите ваш пароль.
                    </p>
                </div>
            )
        }
    }

    if (message) return message;

    return (
        <Form  
            form = {form}                         
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 14 }}
            onFinish={create}
        >
            <Form.Item wrapperCol={{ offset: 6, span: 8 }}>
                <h3>Регистрация нового клиента</h3>
            </Form.Item>
            <Form.Item 
                label="Логин/email" 
                name="email" 
                rules={[{ required: true, message: 'введите email!' }]}>
                <Input />
            </Form.Item>
            <Form.Item 
                label="Имя пользователя" 
                name="name" 
                rules={[{ required: true, message: 'введите имя пользователя!' }]}>
                <Input />
            </Form.Item>
            <Form.Item 
                label="Телефон" 
                name="phone" 
                rules={[{ required: true, message: 'введите телефон!' }]}>
                <Input />
            </Form.Item>
            <Form.Item 
                label="Название компании" 
                name="company" 
                rules={[
                    { required: true, message: 'Введите Название компании!' },
                ]}>
                <Input />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 6, span: 8 }}>
                <Button type="primary" htmlType="submit">Зарегистрировать</Button>
            </Form.Item>
        </Form>
    )
};