import React, {useState} from 'react';
import {Form, InputNumber, Input, Select, Radio, Space, Checkbox} from 'antd';

import InputTable from '../Common/InputTable';

const ConfigXlsx = (props) => {
    const { form } = props;
    const [readFromFile, setReadFromFile] = useState(form.getFieldValue("readFromFile"));
    const [advancedMode, setAdvancedMode] = useState(form.getFieldValue("isAdvancedMode"))

    return [
        <Form.Item name = "readFromFile" label = 'Источник данных'>
            <Radio.Group defaultValue={false} onChange={e => setReadFromFile(e.target.value)}>
                <Space direction="vertical">
                    <Radio value={true}>Загрузить из файла</Radio>
                    <Radio value={false}>Загрузить по ссылке</Radio>
                </Space>
            </Radio.Group>
        </Form.Item>,
        <Form.Item
            label="Ссылка на фид"
            name="url"
            hidden={readFromFile}
        >
            <Input/>
        </Form.Item>,
        <Form.Item
          label="Кодировка"
          name="encoding"
          rules={[
            {required: true, message: 'Обязательный параметр'}
        ]}
        >
            <Select>
                <Select.Option key='utf8'>UTF-8</Select.Option>
                <Select.Option key='utf16le'>UTF-16LE</Select.Option>
                <Select.Option key='win1251'>WIN1251</Select.Option>
            </Select>
        </Form.Item>,
        <Form.Item
          label="Разделитель"
          name="delimiter"
          rules={[
            {required: true, message: 'Обязательный параметр'}
          ]}
        >
            <Select>
                <Select.Option key=';'>; - точка с запятой</Select.Option>
                <Select.Option key=','>, - запятая</Select.Option>
                <Select.Option key='\t'>Tab</Select.Option>
            </Select>
        </Form.Item>,
        <Form.Item
            key = "skipLines"
            label="Пропустить строчки"
            name="skipLines"
        >
            <InputNumber min={1} max={50}/>
        </Form.Item>,
        <Form.Item
            key = "isAdvancedMode"
            label="Расширенный режим"
            name="isAdvancedMode"
            valuePropName="checked"
        >
            <Checkbox onChange={ e => setAdvancedMode(e.target.checked) }/>
        </Form.Item>,
        <Form.Item
            key= "template"
            label="Шаблон файла"
            name="template"
            hidden={!advancedMode}
            wrapperCol={{ span: 20 }}     
            rules={[
                {required: true, message: 'Обязательный параметр'}
            ]}    
        >   
            <InputTable/>         
        </Form.Item>
    ]
};

export default ConfigXlsx;