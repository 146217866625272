import React from "react";
import { Layout, Image} from 'antd';

const Header = () => {
    return (
        <Layout.Header className="header">
            <Image
                width={30}
                src="../../img/databird-logo.png"
            />
            <span className="header-title">Databird : управление данными о товарах</span>
        </Layout.Header>     
    )
}

export default Header;