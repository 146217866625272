import jsPDF from "jspdf";
import font from './Fonts';

export const htmlPdf = async (name,params) => {
    const response = await fetch("/bill.html");
    // Сначала более длинные ключи, чтобы не заменялись более короткие.
    const keys = Object.keys(params).sort((a,b) => b.length - a.length);
    let template = await response.text();
    for (let i of keys) {        
        template = template.replaceAll(`$${i}`, params[i]);
    };
    const html = `<div style="font-family : MyFont">${template}</div>`;
    const jsPdf = new jsPDF({
        orientation: 'p',
        unit: 'px',
        format: 'a4',
    });
    jsPdf.addFileToVFS("MyFont_regular.ttf", font.regular);
    jsPdf.addFileToVFS("MyFont_bold.ttf", font.bold);
    jsPdf.addFont("MyFont_regular.ttf", "MyFont", "normal");
    jsPdf.addFont("MyFont_bold.ttf", "MyFont", "bold");
    jsPdf.html(html, {
        callback: function (doc) {
          doc.save(name);
        }
     });
}

