import React, {useEffect} from "react";
import {Button, Form, Input, PageHeader, Menu, Upload, Dropdown} from 'antd';
import FeedService from "../../../API/FeedService";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import { MoreOutlined } from '@ant-design/icons';

import configMap from '../../Config/types';
import OAuthService from "../../../API/OAuthService";
import FormItem from "antd/es/form/FormItem";

const layout = {
    labelCol: {span: 3},
    wrapperCol: {span: 8},
};

const tailLayout = {
    wrapperCol: {offset: 3, span: 8}
};

export default function FeedName() {
    const {feedType} = useParams();
    const Config = configMap.import[feedType].config;
    const navigate = useNavigate();
    const { showBoundary } = require('react-error-boundary').useErrorBoundary()

    const [form] = Form.useForm();
    useEffect(() => {
        form.getFieldInstance("name").focus()
    })
    //fixme maybe we cold move this part into ConfigYandexMarket.jsx (and from ExportName.jsx and index.js too)
    const [searchParams, setSearchParams] = useSearchParams();
    const code = searchParams.get("code")
    const serviceId = searchParams.get("serviceId")
    const folderId = searchParams.get('folderId')

    React.useEffect(() => {
        async function Exchange() {
            if (code && serviceId) {
                const result = await OAuthService.getCode(code)
                const access_token = result["access_token"];
                if (access_token) {
                    form.setFieldValue("access_token", access_token)
                }
                searchParams.delete("code")
                searchParams.delete("serviceId")
                setSearchParams(searchParams)
            }
        }

        Exchange()
    }, [code, form, searchParams, serviceId, setSearchParams])

    function storeState() {
        localStorage.setItem('config-form', JSON.stringify(form.getFieldsValue()))
    }

    React.useEffect(() => {
        const configStr = localStorage.getItem("config-form");
        if (configStr) {
            form.setFieldsValue(JSON.parse(configStr))
        }
        localStorage.removeItem('config-form')
    }, [])

    const saveItem = async () => {
        const config = await form.validateFields();
        const name = config.name;
        delete config.name;
        try {
            const feed = {
                stream: "import",
                type: feedType,
                parentFolderId: folderId,
                name,
                catalogCreate: true,
                config
            }
            const newFeed = await FeedService.addFeed(feed);
            navigate(`../imports/${newFeed._id}/check`)
        } catch (e) {
            console.log("feed adding problem")
            showBoundary(e)
        }
    }

    const createFeedFromJson = async (options) => {
        const { onSuccess, onError, file, onProgress } = options;
        try{
            const textFile = await file.text()
            const feedConfig = JSON.parse(textFile);
            const mapping = feedConfig.mapping;
            if (feedConfig.type !== feedType) throw "wrong feed type"
            const feed = await FeedService.addFeed({
                stream: feedConfig.stream,
                type: feedConfig.type,
                parentFolderId: folderId,
                name: feedConfig.name,
                catalogCreate: true,
                config: feedConfig.config
            });

            if (mapping) {
                // Сохраняем все мэппинги из архива
                for (const item of mapping ) await FeedService.saveMapping(feed._id, item);
                // Удаляем ненужные мэппинги
                const unusedMappings = feed.mapping.filter(item => !mapping.find(m => m.name === item.name));
                for (const item of unusedMappings) await FeedService.deleteMapping(feed._id, item.name);
            }
            
            onSuccess();
            navigate('../imports', {searchParams: {folderId}})
        } catch (e){
            console.log(e)
        }
    }

    const menu = (
        <Menu>
            <Menu.Item key="createFeedFromJson">
                <Upload
                    maxCount = {1}
                    customRequest = {createFeedFromJson}
                    showUploadList = {{ showRemoveIcon : false }}
                >
                    <Button type='link'>Загрузить конфuгурацию</Button>
                </Upload>
            </Menu.Item>
        </Menu>
    )

    return (
        <div>
            <PageHeader
                title={'Создание нового источника'}
                subTitle={'тип ' + feedType}
                backIcon={false}
                extra={[
                    <Dropdown key="more" overlay={menu} placement="bottomRight">
                        <Button type="text" icon={<MoreOutlined style={{fontSize: 20}}/>}/>
                    </Dropdown>
                ]}
            />
            <Form
                {...layout}
                form={form}
                onFinish={saveItem}
            >
                <Form.Item name="name" label="Название источника" rules={[
                    {required: true, message: 'Обязательный параметр'},
                    {
                        pattern: /^(?! +)[a-zA-Z0-9_а-яА-Я- ]+[a-zA-Z0-9_а-яА-Я-]+$/,
                        message: 'Возможны только буквы, цифры, -, _, пробелы в начале и в конце запрещены',
                    },
                ]}>
                    <Input placeholder='Любое имя'/>
                </Form.Item>
                <Config form={form} storeStateFn={storeState}/>
                <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit">Создать</Button>
                </Form.Item>
            </Form>
        </div>
    )
};
