import React, {useEffect, useState} from "react";
import {Button, Form, Input, PageHeader} from 'antd';
import FeedService from "../../../API/FeedService";
import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";

import configMap from '../../Config/types';
import OAuthService from "../../../API/OAuthService";

const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};

const tailLayout = {
    wrapperCol: {offset: 8, span: 16},
};

export default function ExportName() {
    const {feedType} = useParams();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [myState, setMyState] = useState({})
    const Config = configMap.export[feedType].config;


    useEffect(() => {
        form.getFieldInstance("name").focus()
    }, [])
    const [searchParams, setSearchParams] = useSearchParams();
    const code = searchParams.get("code")
    const serviceId = searchParams.get("serviceId")
    const folderId = searchParams.get('folderId')
    const { showBoundary } = require('react-error-boundary').useErrorBoundary()

    React.useEffect(() => {
        async function Exchange() {
            if (code && serviceId) {
                const result = await OAuthService.getCode(code);
                const access_token = result["access_token"];
                if (access_token) {
                    form.setFieldValue("access_token", access_token)
                }
                searchParams.delete("code")
                searchParams.delete("serviceId")
                setSearchParams(searchParams)
            }
        }

        Exchange()
    }, [code, form, searchParams, serviceId, setSearchParams])

    const { state } = useLocation();
    React.useEffect(() => {
        if (state && state['catalog-filters']) {
            setMyState({'catalog-filters': state['catalog-filters']})
        }
    }, [])
    function storeState() {
        localStorage.setItem('config-form', JSON.stringify(form.getFieldsValue()))
        localStorage.setItem("my-state", JSON.stringify(myState))
    }
    React.useEffect(() => {
        const configStr = localStorage.getItem("config-form");
        if (configStr) {
            form.setFieldsValue(JSON.parse(configStr))
        }
        const myStateStr = localStorage.getItem("my-state");
        if (myStateStr) {
            setMyState(JSON.parse(myStateStr))
        }
        localStorage.removeItem('config-form')
        localStorage.removeItem('my-state')
    }, [])

    const saveItem = async () => {
        const config = await form.validateFields();
        const name = config.name;
        delete config.name;
        const filters = myState['catalog-filters'] ? JSON.parse(myState['catalog-filters']) : {text: ""}
        try {
            const feed = {
                stream: "export",
                type: feedType,
                parentFolderId: folderId,
                name,
                filters,
                config
            }
            const newFeed = await FeedService.addFeed(feed);
            navigate(`../exports/${newFeed._id}`)
        } catch (e) {
            console.log("feed adding problem")
            showBoundary(e)
        }
    }

    return (
        <div>
            <PageHeader
                title={'Создание нового экспорта'}
                subTitle={'тип ' + feedType}
                backIcon={false}
            />
            <Form
                {...layout}
                form={form}
                onFinish={saveItem}
                style={{maxWidth: 600}}
            >
                <Form.Item
                    name="name"
                    label="Название экспорта"
                    rules={[
                        {required: true, message: 'Обязательный параметр'},
                        {
                            pattern: /^(?! +)[a-zA-Z0-9_а-яА-Я- ]+[a-zA-Z0-9_а-яА-Я-]+$/,
                            message: 'Возможны только буквы, цифры, -, _, пробелы в начале и в конце запрещены',
                        }
                    ]}
                >
                    <Input/>
                </Form.Item>
                {Config && <Config storeStateFn={storeState}/>}
                <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit">Создать</Button>
                </Form.Item>
            </Form>
        </div>
    );
};
