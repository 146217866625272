import React, {useEffect, useState} from 'react';
import {AutoComplete, Button, Col, Form, Modal, Row, Space} from 'antd';
import {DeleteOutlined, EditOutlined} from '@ant-design/icons';

import FieldSelector from '../Common/FieldSelector';
import FeedService from "../../API/FeedService";
import AttributeService from '../../API/AttributeService';

export default function ExportCategories({data, fetchFeed}) {
    const {categories} = data;

    // Source field
    const [fieldForm] = Form.useForm();
    const [sourceFields, setSourceFields] = useState([]);
    //Modal
    const [form] = Form.useForm();
    const [modal, setModal] = useState();
    const [localOptions, setLocalOptions] = useState([]);
    const [remoteOptions, setRemoteOptions] = useState([]);
    const [hint, setHint] = useState('');
    const [presentedCategories, setPresentedCategories] = useState([]);
    const { showBoundary } = require('react-error-boundary').useErrorBoundary()

    useEffect( () => {
        async function loadCategories(){
            const remoteCats = await FeedService.getCategoryList(data._id, '');
            setRemoteOptions(remoteCats.map( item => ({value: item.category})))
        }
        async function loadLocal(){
            const localCats = await AttributeService.getFieldValues(null, data.categorySource);
            setLocalOptions(localCats);
        }      
        async function loadSources(){
            const sf = await AttributeService.getSourceFields(data._id);
            setSourceFields(sf)
        }
        async function loadPresentedCategories(){
            const sf = await FeedService.getPresentedCategoryList(data._id);
            setPresentedCategories(sf)
        }
        loadSources();
        loadCategories();
        loadLocal()
        loadPresentedCategories()
    }, [data])
    
    const saveField = async (values) => {
        try{
            const {categorySource} = values;
            await FeedService.setSource(data._id, categorySource);
            fetchFeed()
        } catch (e){
            console.log("saving problem")
            showBoundary(e)
        }
    }

    const onSave = async () => {
        const values = form.getFieldsValue();
        await FeedService.addCategory(data._id, { remote: values.remote, local: values.local});
        setModal();
        form.resetFields();
        fetchFeed()
    }

    const loadHint = async (category) => {
        const hints = await FeedService.getCategoryHints([category], data.type);
        setHint(hints[category]);
    }

    const onEdit = async (item) => {
        setHint(undefined);
        loadHint(item.local).then();

        setModal(true);
        form.setFieldsValue(item);
    }   

    const onDelete = async (item) => {
		await FeedService.deleteCategory(data._id, item);
        fetchFeed()      
    }

    const onCancel = () => {
    	setModal();
    }  

    const items =  categories?.map(element => {
      return (
        <Row style={{margin : '10px auto'}} key = {element.local}>
            <Col span={6}>{element.local}</Col>
            <Col span={6}>{element.remote}</Col>
            <Col span={2}>{presentedCategories[element.local]}</Col>
              <Space>
                <Button onClick={() => {onEdit(element)}}><EditOutlined/></Button>
                <Button onClick={() => {onDelete(element.local)}}><DeleteOutlined/></Button>
              </Space>
          </Row>
      )
    })
    const alreadyMappedCategories = new Set(categories.map(x => x.local))
    const notMappedCategories = Object.keys(presentedCategories).filter(x => !alreadyMappedCategories.has(x))
    notMappedCategories.forEach(localCategory => items.push(
        <Row style={{margin : '10px auto'}} key = {localCategory}>
            <Col span={6}>{localCategory}</Col>
            <Col span={6}><span style={{color : 'red'}}>Не задано</span></Col>
            <Col span={2}>
                {presentedCategories[localCategory]}
            </Col>
            <Space>
                <Button onClick={() => {onEdit({local: localCategory, remote: undefined})}}><EditOutlined/></Button>
            </Space>
        </Row>
    ))

    return <div>
        <Row>
            <Form                
                layout='inline'
                form = {fieldForm} 
                initialValues = {data}
                onFinish = {saveField}
            >
                <Form.Item name = 'categorySource' label={'Атрибут категории'} style={{width: '600px'}} >
                    <FieldSelector sourceFields={sourceFields}/>
                </Form.Item>  
                <Form.Item shouldUpdate>
                    { () => (
                        <Space>  
                            <Button 
                                type="primary" 
                                htmlType="submit"
                                disabled={!fieldForm.isFieldsTouched()}
                            >
                                Сохранить
                            </Button>                  
                            <Button 
                                onClick={ () => fieldForm.resetFields() }
                                disabled={!fieldForm.isFieldsTouched()}
                            >
                                Отмена
                            </Button>
                        </Space>
                    )}
                </Form.Item>
            </Form>  
        </Row>
        <Row style={{marginTop : '40px'}} key = 'title'>
            <Col span={6}><b>Категория товара</b></Col>
            <Col span={6}><b>Категория экспорта</b></Col>
            <Col span={2}><b>Товаров</b></Col>
            <Space>
                <Button type="primary" onClick={() => setModal(true)}>Добавить</Button>
            </Space>
        </Row>         
        { items }
        <Modal
            title = {'Добавить категорию'}
            okText={'Сохранить'}
            cancelText={'Отмена'}
            open={modal}
            onOk={ () => {
                form.validateFields()
                    .then(() => onSave())
                    .catch(() => {});
            }}
            onCancel={ onCancel }
            width={850}
        >
            <Form layout='inline' form={form}>
                <Form.Item name = "local">
                    <AutoComplete 
                        placeholder = 'Категория товара' 
                        style= {{width: '800px'}}
                        options = {localOptions}  
                        filterOption = {true}                      
                    />
                </Form.Item>
                <Form.Item> = </Form.Item>
                <Form.Item name = "remote" rules={[{required: true, message: 'Обязательный параметр'}]}>
                    <AutoComplete
                        placeholder = 'Категория экспорта'
                        style= {{width: '800px'}}
                        options = {remoteOptions}
                        filterOption = {true}
                    />
                </Form.Item>
                <Form.Item hidden={!hint}>
                    Автоподбор:<Button type='link' onClick={() => form.setFieldValue("remote", hint)}>{hint}</Button>
                </Form.Item>
            </Form>
        </Modal>
    </div>
}
