import React, {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Button, Col, Image, Input, Modal, PageHeader, Row, Tabs} from 'antd';
import {ArrowLeftOutlined, DeleteOutlined, EditOutlined} from '@ant-design/icons';

import {useFetching} from '../../hooks/useFetching';
import ProductService from "../../API/ProductService";
import trans from '../../languages/ruTranslate';
import AddAttributeBlock from "../Attributes/AddAttributeBlock";

const _ = require("lodash")

const {TabPane} = Tabs;
// Атрибуты, которые используются в карточке в качестве системных
const systemAttributes  = [ 'productId', 'name', 'vendor', 'vendorCode', 'variation',
                            'images', 'category', 'price', 'stock', 'description', 'length',
                            'width', 'height', 'weight', 'creationDate', 'source'].map(i => trans(i))
const { TextArea } = Input;

export default function ProductCard() {
    const [activeTabKey, setActiveTabKey] = useState('attributes');
    const [data, setData] = useState();
    const { productId } = useParams();
    const { showBoundary } = require('react-error-boundary').useErrorBoundary()
    const [fetchProduct] = useFetching( async () => {
        const data = await ProductService.getProduct(productId);
        setData(data)
    });
    const navigate = useNavigate();

    React.useEffect(() => {
        fetchProduct();
    }, []);

    const save = async (key, value) => {
        key = key.trim()
        if (key === "")
            throw Error("Key is empty")

        const newData = {...data}
        const oldValue = data.attributes[key]
        const wasObject = _.isObject(oldValue)
        const wasNumber = _.isNumber(oldValue)
        let newValue;
        if (wasObject) {
            try {
                newValue = JSON.parse(value);
            } catch (e) {
                newValue = value
            }
        } else if (wasNumber) {
            newValue = parseFloat(value)
            if (Number.isNaN(newValue))
                newValue = value
        } else {
            newValue = value
        }
        newData.attributes[key] = newValue
        setData(newData)
        try {
            await ProductService.updateProductAttribute(productId, key, newValue)
        } catch (e) {
            console.error("attribute saving problem", e)
            setData(data)
            showBoundary(e)
        }
    }

    function handleArray(value, wasArray) {
        const newValue = value.split(/[,\s]+/).filter(s => s !== '')
        if (newValue.length <= 1 && !wasArray) {
            return newValue.length === 0 ? '' : newValue[0];
        } else {
            return newValue;
        }
    }

    const saveImage = async (imageValue, imageKey, wasArray) => {
        const corrected = handleArray(imageValue, wasArray);
        const newData = {...data}
        newData.attributes[imageKey] = corrected
        try {
            await ProductService.updateProductAttribute(productId, imageKey, corrected)
            setData(newData)
        } catch (e) {
            console.error("image saving problem", e)
            showBoundary(e)
        }
    }

    const deleteAttribute = async (key) => {
        try {
            await ProductService.deleteProductAttribute(productId, key);
            const newData = {...data}
            delete newData.attributes[key]
            setData(newData);
        } catch (e) {
            console.error("attribute deleting problem", e)
            showBoundary(e)
        }
    }

    const getAttrRow = (key, isDeletable = false, isEditable = true) => getRow(key,
        data.attributes[key], isDeletable,
        isEditable ? (value) => save(key, value) : null);

    const EditableCell = ({
                              editable,
                              name,
                              value,
                              handleSave,
                          }) => {
        const [editing, setEditing] = useState(false);
        const inputRef = useRef(null);
        useEffect(() => {
            if (editing) {
                inputRef.current.focus();
            }
        }, [editing]);
        const toggleEdit = () => {
            setEditing(!editing);
        };
        const save = async (e) => {
            try {
                toggleEdit();
                handleSave(e.target.value);
            } catch (errInfo) {
                console.error('Save failed:', errInfo);
                showBoundary(errInfo)
            }
        };
        if (editable) {
            const editableElement = <div>
                <TextArea ref={inputRef}
                          onBlur={save}
                          defaultValue={_.isObject(value) ? JSON.stringify(value, null, 2) : value}
                          onKeyDown={(e) => {
                              if (e.key === "Escape") {
                                  toggleEdit()
                              } else if (e.key === "Enter" && e.ctrlKey) {
                                  save(e)
                              }
                          }}
                          autoSize={{maxRows: 6}}
                />
                <Button type={"primary"} style={{margin: '10px 0',}}>Сохранить</Button>
            </div>

            let cleanValue;
            if (_.isString(value)) {
                const multiLine = value.split(/\r\n|\r|\n/g)
                    .filter(str => str.trim() !== '')
                    .map(str => <div>{str}</div>)
                if (multiLine.length > 10) {
                    cleanValue = multiLine.slice(0, 10)
                    cleanValue.push("...↴")
                } else {
                    cleanValue = multiLine
                }
            } else {
                cleanValue = _.isObject(value) ? JSON.stringify(value, null, 2) : value;
            }
            const notEditableElement = <div
                className="editable-cell-value-wrap"
                style={{
                    paddingRight: 24,
                    minHeight: "1em",
                }}
                title="Для редактирования нажмите мышью"
                onClick={toggleEdit}
            >
                {cleanValue}
            </div>
            return <><Col span={12}>
                {editing ? editableElement : notEditableElement}
            </Col><Col><Button icon={<EditOutlined/>} title={`Редактировать '${name}'`} onClick={toggleEdit}/></Col></>
        } else
            return <div>{_.isObject(value) ? JSON.stringify(value, null, 2) : value}</div>
    };
    const getRow = (key, value, isDeletable, handleSave) =>
        <Row wrap style={{marginTop: '10px', wordWrap: 'break-word'}} key={key}>
            <Col span={5}><b>{key}</b></Col>
            <EditableCell
                editable={handleSave}
                name={key}
                value={value}
                handleSave={handleSave}
            />
            {isDeletable ?
                <Col><Button icon={<DeleteOutlined/>} title={`Удалить '${key}'`} style={{marginLeft: 3}}
                             onClick={() => showDeleteConfirm(key)}/></Col>
                : null}
        </Row>

    function showDeleteConfirm(key) {
        Modal.confirm({
            title: `Удалить атрибут '${key}'?`,
            okText: 'Да',
            okType: 'danger',
            cancelText: 'Нет',
            onOk() {
                deleteAttribute(key);
            },
        });
    }

    const getImages = key => {
        const value = data.attributes[key];
        const valueStr = _.isArray(value) ? value.join(",\n") : value
        return (
            <>
                <Row><Image.PreviewGroup>
                    {Array.isArray(value)
                        ? value.map(item => (<Image width={200} src={item}/>))
                        : <Image width={200} src={value}/>
                    }
                </Image.PreviewGroup></Row>
                <Row wrap style={{marginTop: '10px', wordWrap: 'break-word'}} key={key}>
                    <Col span={4}><b>Ссылки на изображения</b></Col>
                    <EditableCell
                        editable={true}
                        name={"Изображения"}
                        value={valueStr}
                        handleSave={(value) => saveImage(value, key, _.isArray(value))}
                    />
                </Row>
            </>
        )
    }

    if (!data) {
        return <div>
            Загрузка данных....
        </div>
    }

    return <div>
        <PageHeader key='pageheader'
            onBack={ () => navigate('../products') }
            backIcon={ <ArrowLeftOutlined /> }
            title = { data?.attributes[trans('name')] }
        />
        <Tabs
            style= {{margin: '20px 30px'}}
            defaultActiveKey={activeTabKey}
            onChange={ key => {
                setActiveTabKey(key);
            }}
        >
            <TabPane tab="Основные" key = "main">
                { getRow(trans('productId'), data.productId, false) }
                { getAttrRow(trans('creationDate'), false, false) }
                { getAttrRow(trans('source'), false, false) }
                { getAttrRow(trans('vendor')) }
                { getAttrRow(trans('vendorCode')) }
                { getAttrRow(trans('name')) }
                { getAttrRow(trans('variation')) }
                { getAttrRow(trans('category')) }
                { getAttrRow(trans('description')) }
            </TabPane>
            <TabPane tab="Габариты" key = "size">
                { getAttrRow(trans('length')) }
                { getAttrRow(trans('width')) }
                { getAttrRow(trans('height')) }
                { getAttrRow(trans('weight')) }
            </TabPane>
            <TabPane tab="Цены и остатки" key = "stocks">
                { getAttrRow(trans('price')) }
                { getAttrRow(trans('stock')) }
            </TabPane>
            <TabPane tab="Изображения" key = "images">
                { getImages(trans('images'))}
            </TabPane>
            <TabPane tab="Характеристики" key = "source">
                {
                    data.attributes && Object.keys(data.attributes)
                        .filter( key => !systemAttributes.includes(key))
                        .map( key => getAttrRow(key, true))
                }
                <Row><Col><AddAttributeBlock addAttribute={(attr) => save(attr.name, "")}
                                             existedAttributeNames={Object.keys(data.attributes)}/></Col></Row>
            </TabPane>
        </Tabs>
    </div>
}
