import React from 'react';
import { Form } from 'antd';

import config from '../../config';
import AuthProvider from "../../API/AuthProvider";

const ConfigJson = ({data}) => {
    const feedUrl = `${config.apiUrl}/api/load/${AuthProvider.getProject()}_${data?.name}.json`;
    return (
        <div>
            {   data &&
                <Form.Item label="Ссылка на фид"> 
                <a href={feedUrl}>{feedUrl}</a>
                </Form.Item>
            }
        </div>
    );
};

export default ConfigJson;