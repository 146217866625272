import {Form, Input, Modal} from "antd";
import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import FeedService from "../../API/FeedService";

const RenameCopyDialog = ({feed, mode, setMode, fetchFeed}) => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const save = async () => {
        if (mode == 'rename') {
            await FeedService.updateFeed(feed._id, form.getFieldsValue());
            setMode();
            fetchFeed()
        } else {
            const feedDoc = await FeedService.copyFeed(feed._id, form.getFieldsValue());
            setMode();
            navigate(`../${feedDoc.stream}s/${feedDoc._id}`)
        }
    }

    useEffect(() => {
        form.setFieldsValue(feed)
        form.getFieldInstance("name").focus()
    }, [])

    return <Modal
        title={mode === 'rename' ? 'Переименовать' : 'Копировать'}
        okText={'Сохранить'}
        cancelText={'Отмена'}
        open={true}
        onOk={save}
        onCancel={() => setMode()}
        width={850}
    >
        <Form layout='inline' form={form}>
            <Form.Item
                name="name"
                label="Название"
                onKeyPress={(e) => {
                    if (e.key === "Enter") save()
                }}
                rules={[
                    {required: true, message: 'Обязательный параметр'},
                    {
                        pattern: /^(?! +)[a-zA-Z0-9_а-яА-Я- ]+[a-zA-Z0-9_а-яА-Я-]+$/,
                        message: 'Возможны только буквы, цифры, -, _, пробелы в начале и в конце запрещены',
                    }
                ]}
            >
                <Input/>
            </Form.Item>
        </Form>
    </Modal>
}

export default RenameCopyDialog;