import React from 'react';
import { Form, Input} from 'antd';

import config from '../../config';
import AuthProvider from '../../API/AuthProvider';

const ConfigSberExport = ({data}) => {
    const host = config.apiUrl ? config.apiUrl : config.origin;
    const feedUrl = `${host}/api/load/${AuthProvider.getProject()}_${data?.name}.zip`;
    return (
        <div>
            { data &&
                <Form.Item label="Ссылка на архив с шаблонами" wrapperCol={{ span: 16 }}> 
                    <a href={feedUrl}>{feedUrl}</a>
                </Form.Item>
            }
        </div>
    );
};

export default ConfigSberExport;