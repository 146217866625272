import React,{ useState, useEffect } from "react";
import { Routes, Route, Navigate, useLocation} from "react-router-dom";

import ContentPanel from './components/ContentPanel';
import AuthProvider  from './API/AuthProvider';
import UserService from './API/UserService';
import Login from "./components/Users/Login";
import Dashboard from "./components/Dashboard/Dashboard";
import Projects from "./components/Users/Projects";
import Products from "./components/Products/Products";
import Orders from "./components/Orders/Orders";
import Feeds from "./components/Feeds/Feeds";
import FeedCard from "./components/Feeds/FeedCard";
import Exports from "./components/Exports/Exports";
import ExportCard from "./components/Exports/ExportCard";
import Attributes from "./components/Attributes/Attributes";
import Dictionaries from "./components/Dictionaries/Dictionaries";
import Users from "./components/Users/Users";
import FeedWizard from "./components/Feeds/wizard/FeedWizard";
import FeedName from "./components/Feeds/wizard/FeedName";
import ExportWizard from "./components/Exports/wizard/ExportWizard";
import ExportName from "./components/Exports/wizard/ExportName";
import Master from "./components/Master";

// we need to extend dayjs in order to get Antd Datepicker working fine
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import FeedCheck from "./components/Feeds/wizard/FeedCheck";
import ProductCard from "./components/Products/ProductCard";

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)


function App() {
    const [user, setUser] = useState();
    const projectId = AuthProvider.getProject();
    const location = useLocation();

    async function fetchUser() {
        try {
            const id = AuthProvider.getUserId(); 
            if (!id) setUser(null)
            else if (!user || user._id !== id){
                const result = await UserService.getProfile();
                setUser(result);
            }
        } catch (e) {
            console.log("no user");
            setUser(null);
        }
    }

    useEffect(() => {
        fetchUser();
    }, [location]);

    if (!user) return (<Login />)

    return (
        <Routes>
            <Route path="/master" element={<Master user={user}/>}/>
            <Route path="/projects" element={<Projects />}/>   
            <Route path="/projects/:projectId" element={<ContentPanel user={user}/>}>                 
                <Route path="dashboard" element={<Dashboard user={user}/>}/>
                <Route path="products" element={<Products />}/>
                <Route path="products/:productId" element={<ProductCard />}/>
                <Route path="imports" element={<Feeds/>}/>
                <Route path="imports/:feedId" element={<FeedCard />}/>
                <Route path="imports/create" element={<FeedWizard/>}/>
                <Route path="imports/create/:feedType" element={<FeedName/>}/>
                <Route path="imports/:feedId/check" element={<FeedCheck/>}/>
                <Route path="exports" element={<Exports/>}/>
                <Route path="exports/:feedId" element={<ExportCard />}/>
                <Route path="exports/create" element={<ExportWizard/>}/>
                <Route path="exports/create/:feedType" element={<ExportName/>}/>
                <Route path="attributes" element={<Attributes />}/>
                <Route path="dictionaries" element={<Dictionaries />}/>  
                <Route path="orders" element={<Orders />}/>
                <Route path="users" element={<Users />}/>
            </Route>
            <Route path="*" element={ 
                    projectId   ? <Navigate to={`/projects/${projectId}/dashboard`}/>
                                : <Navigate to={`/projects`}/>
            }/>            
        </Routes>
    );
}

export default App;
