import React from "react";
import { Form, Input, Button } from 'antd';

import UserService from '../../API/UserService';

export default function Activate({activation, login, onFinish}) {
    const [message, setMessage] = React.useState();
    const [form] = Form.useForm();
    const onActivate = async (values) => {
        try {
            const request = {...values, activation, login}
            await UserService.activation(request);
            onFinish(request)
        } catch (ex) {
            setMessage('Неверный код активации');
            console.log('Activate problem: ' + ex);
        }
    }

    return (
        <Form 
            name="basic"
            form={form}
            onFinish={onActivate}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 8 }}
        >
            <Form.Item wrapperCol={{offset: 8, span: 16 }}>
                <h3>Создайте пароль для активации пользователя Databird</h3>
            </Form.Item>
            <Form.Item label='Логин/email' >
                {login}
            </Form.Item>
            <Form.Item name='password' label='Новый пароль'
                        rules={[{ required: true, message: 'Введите новый пароль!' }]}
            >
                <Input.Password />
            </Form.Item>
            <Form.Item name='confirmPassword' label='Подтвердите пароль'
                rules={[
                    {
                        required: true,
                        message: 'Подтвердите новые пароль!',
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error('Пароли должны совпадать!'));
                        },
                    }),
                ]}
            >
                <Input.Password />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8, span: 8 }}>
                <Button type="primary" htmlType="submit">Активировать</Button>
                <span style={{color : 'red'}}> {message}</span>
            </Form.Item>
        </Form>
    )
}
