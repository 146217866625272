import dayjs from 'dayjs'
import React, {useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {Button, Card, Form, Input, PageHeader, Row, Col, Space, Table} from 'antd';

import OrderService from "../../API/OrderService";
import {useFetching} from '../../hooks/useFetching';
import {newWindow} from "../../utils/Files";
import api from "../../API/ServerAPI";
import trans from '../../languages/ruTranslate';

export default function Orders() {
    const [selectList, setSelectList] = useState();
    const [data, setData] = useState([]);
    const [pageSize, setPageSize] = useState(100);
    const [current, setCurrent] = useState(1);
    const [search, setSearch] = useState({text: "", filters: [], _prepared: false});
    const [total, setTotal] = useState(10);
    const [fetchOrders, loading] = useFetching(async () => {
        const result = await OrderService.searchOrders({current, pageSize}, search);
        setTotal(result.count);
        setData(result.data);
    })
    const [form] = Form.useForm();
    const navigate = useNavigate();

    const {state} = useLocation();

    const setStoredFilters = (filterStr) => {
        const searchFilter = JSON.parse(filterStr);
        setSearch(searchFilter)
        form.setFieldsValue(searchFilter)
        searchOrders(searchFilter)
    }

    React.useEffect(() => {
        const filterStr = localStorage.getItem('order-filters');
        if (state && state["order-filters"]) {
            const filterStr = state["order-filters"]
            setStoredFilters(filterStr);
        } else if (filterStr) {
            setStoredFilters(filterStr);
        } else {
            setSearch({text: "", filters: []})
        }
    }, [state]);

    React.useEffect(() => {
        if (search._prepared === false)
            return
        fetchOrders();
    }, [current, pageSize, search]);

    const searchOrders = filter => {
        setCurrent(1);
        setSearch(filter)
        localStorage.setItem('order-filters', JSON.stringify(filter))
    }

    const handleTableChange = ({current, pageSize}) => {
        setCurrent(current);
        setPageSize(pageSize);
    };

    if (loading) {
        return <div>
            Загрузка данных....
        </div>
    }

    const downloadSticker = async (orderid, orderNumber) => {
        const response = await api.get(`/orders/${orderid}/print`, {responseType: 'blob'})
        newWindow(response.data, response.headers['content-type']);
    }

    return <div>
        <PageHeader
            title='Заказы'
            subTitle='получаются из настроенных источников'
        />
        <Row>
            <Col span={16}><Form
                form={form}
                style={{marginLeft: '25px'}}
                initialValues={search}
                onFinish={searchOrders}
            >
                <Form.Item name="text" label="Номер заказа или ID товара">
                    <Input.Search placeholder="введите текст поиска"
                                  defaultValue={search.text}
                                  allowClear
                                  style={{width: 400}}
                                  onSearch={(value) => {
                                      const fieldsValue = form.getFieldsValue();
                                      fieldsValue["text"] = value
                                      searchOrders(fieldsValue);
                                  }}
                                  key='text'
                    />
                </Form.Item>
            </Form></Col>
            <Col span={8}>
                <Space style={{float: 'right', marginRight: '20px'}}>
                    <Button onClick={async () => await OrderService.loadOrdersToXls(search, selectList)}>
                        Сохранить в Excel
                    </Button>
                </Space>
            </Col>
        </Row>
        <div>
            {!loading && (data.length ?
                    <Table
                        rowKey={p => p._id}
                        dataSource={data}
                        rowSelection={{
                            type: 'checkbox',
                            onChange: (selectedRowKeys, selectedRows) => {
                                setSelectList(selectedRowKeys)
                            }
                        }}
                        columns={[
                            {
                                title: 'Номер заказа', dataIndex: ['orderNumber'],
                            },
                            {
                                title: 'Дата заказа', dataIndex: ['createdAt'],
                                render: dateStr => dayjs(dateStr).format('DD.MM.YYYY HH:mm:ss')
                            },
                            {
                                title: 'Источник', dataIndex: ['feedName'],
                                render: (text, item) =>
                                    <Button
                                        type='link'
                                        onClick={() => navigate(`../imports/${item.feedId}`)}
                                        style={{ overflow: 'hidden',  width: '180px', textAlign : 'left'}}
                                    >
                                        {text}
                                    </Button>
                            },{
                                title: 'Номер отправления', dataIndex: ['postingNumber'],
                                render: (text, item) =>
                                    <Button
                                        type='link'
                                        onClick={() => downloadSticker(item._id, item.postingNumber)}
                                    >
                                        {text}
                                    </Button>
                            },
                            {
                                title: 'ID товара на маркетплейсе', dataIndex: ['externalProductId'],
                            },
                            {
                                title: 'Товар', dataIndex: ['name'],
                            },
                            {
                                title: 'ID товара', dataIndex: ['productId'],
                            },
                            {
                                title: 'Цена', dataIndex: ['price']
                            },
                            {
                                title: 'Количество', dataIndex: ['quantity']
                            },
                            {
                                title: 'Статус', dataIndex: ['externalStatus'],
                                render: (text, item) => trans(text)
                            }
                        ]}
                        defaultExpandAllRows={false}
                        pagination={{current, total, pageSize}}
                        key="table"
                        onChange={handleTableChange}
                    />
                    : <Card>
                        <h4 style={{marginBottom: '100px'}}>Заказов нет</h4>
                        <p><b>Для загрузки заказов:</b></p>
                        <p>1. Настройте источник: <Link to='../imports/create'>добавить источник</Link>.</p>
                        <p>2. Включите галочку Настройки синхронизации данных -> Получать заказы</p>
                    </Card>
            )}
        </div>
    </div>
}