import React, { useState }  from 'react';
import { Select, Radio} from 'antd';

/*
    Селектор поля
    Поле может быть атрибутом или исходным полем
*/

function FieldSelector(props) {
    const { sourceFields, value, onChange, style } = props;
 
    return <Select
        placeholder = "укажите поле"
        showSearch          
        onChange={onChange}
        value={value}
        style={style}
    >
            <Select.Option key = "none" value = ""><b>не задано</b></Select.Option>
            { sourceFields?.map(item => (<Select.Option key = {item} value = {item}>{item}</Select.Option>)) }
    </Select>
}

export default FieldSelector;