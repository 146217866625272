import { useState } from 'react';

export const useFetching = (callback) => {
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const fetching = async (x) => {
        try {
            setLoading(true);
            await callback(x);
        } catch (e) {
            console.debug(e)
            setError(e.message);
        } finally {
            setLoading(false);
        }
    }

    return [fetching, isLoading, error];
}