import api from "./ServerAPI";

export default class AttributeService {
    static async getAttributes(){
        const response = await api.get(`/attributes`);
        return response.data
    }

    static async getAttributeNames(){
        const response = await api.get(`/attribute-names`);
        return response.data
    }

    static async addAttribute(attribute){
        const response = await api.post('/attributes', attribute);
        return response.data
    }

    static async deleteAttribute(name){
        const response = await api.delete('/attributes', { data: { name } });
        return response.data
    }

    static async getFieldValues(feed, field){
        const response = await api.get('/values', { params : { feed, field}  });
        return response.data
    }

    static async getDictValues(feed, attribute){
        const response = await api.get('/dictValues', { params : { feed, attribute }  });
        return response.data
    }

    static async getSourceFields(feed){
        const response = await api.get('/sourceFields', { params : { feed }  });
        return response.data
    }

    static async getProductsAttributes(filter){
        const response = await api.post("/products/attributes", filter);
        return response.data
    }
}