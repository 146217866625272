import axios from "axios";
import config from "../config";

const api = axios.create({
    timeout: 600000
})
api.interceptors.request.use(requestAuth);
api.interceptors.request.use(requestProject);
api.interceptors.response.use(responseConfig => responseConfig, responseError)

export const masterApi = axios.create({
    timeout: 600000,
    baseURL : `${config.apiUrl}/api`
})
masterApi.interceptors.request.use(requestAuth);
masterApi.interceptors.response.use(responseConfig => responseConfig, responseError)

function requestAuth(requestConfig) {
    const token = localStorage.getItem(config.accessTokenKey);
    requestConfig.headers.Authorization =  `Bearer ${token}`;
    return requestConfig
}

// Добавить ссылку на проект
function requestProject(requestConfig) {
    const projectId = localStorage.getItem(config.projectKey);
    requestConfig.baseURL = `${config.apiUrl}/api/projects/${projectId}`;
    return requestConfig
};

async function responseError(error){
    const originalRequest = error.config;    
    console.log("error");
    if (error.response.status === 401 && error.config && !error.config._isRetry){
        originalRequest._isRetry = true;
        const token = localStorage.getItem(config.refreshTokenKey);
        if (!token) return (error);
        try {
            const response = await axios.post(`${config.apiUrl}/api/token`, {token});
            const { accessToken, refreshToken } = response.data;
            localStorage.setItem(config.accessTokenKey, accessToken);
            localStorage.setItem(config.refreshTokenKey, refreshToken);            
            return api.request(originalRequest)
        } catch (e) {
            console.log('User is not authorised');
            localStorage.setItem(config.accessTokenKey, null);
            localStorage.setItem(config.refreshTokenKey, null);     
        }
    }
    throw error;
}

export default api;


