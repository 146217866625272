import React, {useState, useRef, useEffect} from 'react';
import { Tag, Input, Space } from 'antd';
import {PlusOutlined} from '@ant-design/icons';


function TagList(props) {
    // values - array of tag values
    const {value, onChange} = props;
    const [inputValue, setInputValue] = useState("");
    const [inputVisible, setInputVisible] = useState(false);
    const inputRef = useRef(null);

    const deleteValue = index => onChange(value.filter( (el, i) => {
        return i !== index
    }))

    const getTags = () => value?.map( (item, index) => {
        return (
            <Tag closable onClose={ () => deleteValue(index)} key={item}>{item}</Tag>
        )
    })

    const inputChange = e => {
        setInputValue(e.target.value)
    }

    const inputConfirm = () => {
        if (inputValue){
            const newArr = value ? [...value, inputValue] : [inputValue];
            onChange(newArr);
            setInputValue("")
        }
        setInputVisible(false);
    }

    useEffect( () => {
        if (inputVisible) inputRef.current.focus({
            cursor: 'all',
        });
    }, [inputVisible])

    return (
        <Space wrap={true}>
            {getTags()}
            { inputVisible 
                ? <Input
                        type="text"
                        size="small"
                        style={{ width: 78 }}
                        onChange={inputChange}
                        onBlur={inputConfirm}
                        onPressEnter={inputConfirm}
                        ref={inputRef}
                />
                :  <Tag 
                        onClick={ () => setInputVisible(true) }
                        style = {{borderStyle: 'dashed'}} 
                    >
                        <PlusOutlined /> Добавить
                 </Tag>
            }
        </Space>
    );
}

export default TagList;