import api, {masterApi} from './ServerAPI';

export default class UserService {
    // Работа с аккаунтом пользователя.
    static async getProfile() {
        const response = await masterApi.get(`/profile`);
        return response.data;
    }
    
    static async activation(request) {
        const response = await masterApi.post(`/activation`, request);
        return response.data;
    }

    static async resetPassword(request) {
        const response = await masterApi.post(`/resetPassword`, request);
        return response.data;
    }

    static async getProject(projectId) {
        const response = await masterApi.get(`/projects/${projectId}`);
        return response.data;
    }

    static async getProjects() {
        const response = await masterApi.get(`/projects`);
        return response.data;
    }

    static async changePassword(passwords) {
        const response = await masterApi.put(`/changePassword`, passwords);
        return response.data;
    }

    static async changeInfo(user) {
        const response = await masterApi.put(`/changeInfo`, user);
        return response.data;
    }

    static async getProjectDashboard() {
        const response = await api.get(`/dashboard`);
        return response.data;
    }

    // Управления пользователями проекта
    static async getUsers() {
        const response = await api.get(`/users`);
        return response.data;
    }

    static async getUsersRoles() {
        const response = await api.get(`/users/roles`);
        return response.data;
    }

    static async getUser(userId) {
        const response = await api.get(`/users/${userId}`);
        return response.data;
    }

    static async addUser(user) {
        const response = await api.post('/users', user);
        return response.data;
    }

    static async deleteUser(userId) {
        const response = await api.delete(`/users/${userId}`);
        return response.data;
    }
}