import { useEffect, useState } from 'react';
import { Table, PageHeader, Button, Modal, Spin } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { useFetching } from '../../hooks/useFetching';
import UserService from '../../API/UserService';
import UserAdd from './UserAdd';
import trans from '../../languages/ruTranslate';

export default function Users() {
    const [data, setData] = useState([]);
    const [isDataOutdated, setDataOutdated] = useState(true);
    const { showBoundary } = require('react-error-boundary').useErrorBoundary()

    const [isUserAddModalActive, setUserAddModalActive] = useState(false);

    const [fetchUsers, areUsersFetching, fetchUsersError] = useFetching(async () => {
        const response = await UserService.getUsers();
        setData(response);
        setDataOutdated(false);
    });


    const deleteUser = async (user) => {
        try {
            await UserService.deleteUser(user._id);
            setDataOutdated(true);
        } catch (ex) {
            console.log('User deleting problem: ' + ex);
            showBoundary(ex)
        }
    }

    function showDeleteConfirm(user) {
        Modal.confirm({
            title: 'Удалить пользователя?',
            okText: 'Да',
            okType: 'danger',
            cancelText: 'Нет',
            onOk() {
                deleteUser(user);
            }
        });
    }

    useEffect(() => {
        if (isDataOutdated && !areUsersFetching)
            fetchUsers();
    }, [fetchUsers, isDataOutdated, areUsersFetching]);
    
    if (fetchUsersError) {
        return <div>Users loading error: {fetchUsersError}</div>
    }

    return <div>
        <PageHeader
            title={'Пользователи'}
            extra={[
                <Button type='primary' key='btn' onClick={() => setUserAddModalActive(true)}>{'Добавить пользователя'}</Button>
            ]}
        />
        {areUsersFetching
            ? <div style={{ margin: '20px 30px', 'text-align': 'center' }}><Spin /></div>
            : <Table style={{ margin: '20px 30px' }}
                rowKey={item => item._id}
                dataSource={data}
                columns={[  
                    {
                        title: 'Логин',
                        dataIndex: ['login'],
                    }, {
                        title: 'Имя пользователя',
                        dataIndex: ['name']
                    }, {
                        title: 'Роль',
                        dataIndex: ['role'],
                        render: (value, item) => trans(value),
                    }, {
                        key: 'actions',
                        align: 'right',
                        render: (_, item) => (
                            <Button disabled={item.role === 'owner'} icon={<DeleteOutlined />} onClick={() => showDeleteConfirm(item)} />
                        )
                    }
                ]}
                pagination={false}
                key='table'
            /> 
        }
        {isUserAddModalActive ? <UserAdd setModalActive={setUserAddModalActive} setDataOutdated={setDataOutdated} /> : null}    
    </div>         
}