import axios from "axios";

export default class OAuthService {
    static async getCode(code) {
        const data = `grant_type=authorization_code&code=${code}&client_id=aa056b6d695c41c19195db2437f1f62c&client_secret=4e7c9909c1234728a720847abef5cc6e`;
        const client = axios.create({timeout: 30000})
        let response
        try {
            response = await client.post('https://oauth.yandex.ru/token', data);
        } catch (e) {
            console.log(e)
        }
        return {
            access_token: response.data.access_token
        }
    }
}