import React from 'react';
import { Input, InputNumber, Space } from 'antd';

function InputTable(props) {
    // value - array of rows
    const {value, onChange} = props;

    if (!value || !value[0]){
        onChange([[null, null, null, null]]);
        return <div/>
    }

    const inputChange = (e, row, col) => {        
        const newArr = [...value];
        const str = e.target.value;
        
        if (str){
            //Если вставляют заголовки, то сделать split
            const sp = str.split('\t');
            for (let i = 0; i < sp.length; i++) {
                newArr[row][col+i] = sp[i]
            }
        } else newArr[row][col] = null;
        onChange(newArr);
    };

    const setArray = (x, y) => {        
        const rows = x ? x : value.length;
        const columns = y ? y : value[0].length;    
        const newArr = [];
        for (let r = 0; r < rows; r ++){
            if (value[r]) {
                newArr[r] = [];
                for (let c = 0; c < columns; c++) {
                    if (value[r][c]) newArr[r][c] = value[r][c]
                    else newArr[r][c] = null;
                }
            } else newArr[r] = new Array(columns).fill(null);
        }
        onChange(newArr)
    }

    const getItem = (row, col) => {
        return (<Input 
            placeholder="нет значения"
            key = {`${row} ${col}`}
            style={{ width: '100px' }} 
            onChange={e => inputChange(e, row, col)} 
            value={value[row][col]}
        />)
    }

    return (<>
        <Input.Group style={{ width: '230px'}}>
            <Space>
                <InputNumber  value={value.length} addonBefore="rows" min={1} max={30} onChange={ rows => setArray(rows, value[0].length) }/>
                <InputNumber  value={value[0].length} addonBefore="columns" min={1} max={50} onChange={cols => setArray(value.length, cols) }/>
            </Space>
        </Input.Group>
        <br/>
        <div style={{ width : '80%', overflowX: 'auto', whiteSpace: 'nowrap'}}>          
            { 
                value?.map( (item, row) => 
                    <Input.Group key={row} compact>
                        { 
                            item.map( (str, col) => getItem(row, col) )                                                        
                        }
                    </Input.Group>                    
                )
            }
        </div>
    </>)
}

export default InputTable;