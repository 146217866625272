import React from 'react';
import {Form, Input, Select, Space} from 'antd';
import FieldSelector from "./FieldSelector";
import moment from 'moment'

const filterOperations = [
    {op: "lt", val: "<"},
    {op: "lte", val: "≤"},
    {op: "gt", val: ">"},
    {op: "gte", val: "≥"},
    {op: "eq", val: "="},
    {op: "ne", val: "≠"},
    {op: "contains", val: "содержит"},
    {op: "not_contains", val: "не содержит"},
    {op: "in", val: "в списке"},
    {op: "defined", val: "задан"},
    {op: "undefined", val: "не задан"},
]
const singleArgumentOperations = ["defined", "undefined"]
const numberOperations = filterOperations.slice(0, 4).map(x => x.op);


export default function FilterComponent(props) {
    const name = props.name
    const sourceFields = props.sourceFields

    return <Space>
        <Form.Item name={[name, "source"]} style={{width: 200}} rules={[{ required: true, message: 'Необходим аттрибут товара' }]}>
            <FieldSelector sourceFields={sourceFields}/>
        </Form.Item>
        <Form.Item name={[name, "condition"]} style={{width: 120}} rules={[{ required: true, message: 'Необходимо выбрать операцию сравнения' }]}>
            <Select>
                {filterOperations.map(item => (<Select.Option key={item.op}
                                                              value={item.op}>{item.val}</Select.Option>))}
            </Select>
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
            {({getFieldValue}) =>
                singleArgumentOperations.indexOf(getFieldValue(["filters", name, "condition"])) < 0 ? (
                    <Form.Item name={[name, "argument"]} style={{width: 200}}
                               dependencies={[["filters", name, "condition"]]}
                               rules={[{
                                   validator: (_, value) =>
                                       numberOperations.indexOf(getFieldValue(["filters", name, "condition"])) < 0
                                       || value.trim() && !isNaN(value.trim())
                                       || moment(value.trim(), ["D.M.YYYY", "D.M.YY"], true).isValid() ? Promise.resolve() : Promise.reject(new Error()),
                                   message: "Значение должно быть числом или датой"
                               }]}>
                        <Input/>
                    </Form.Item>
                ) : null
            }
        </Form.Item>
    </Space>
}