import { useNavigate } from "react-router-dom";
import React from "react";


/*
export function useNavigate() {
    const navigate = useNavigate_();
    return React.useCallback((to, { searchParams, ...options } = {}) => {
        let search;
        if (typeof searchParams === "object") {
            const noEmpty = Object.fromEntries(Object.entries(searchParams).filter(([_, v]) => v != null));
            search = new URLSearchParams(noEmpty).toString();
        } else {
            search = undefined;
        }
        to = typeof to === "string" ? parsePath(to) : to;
        return navigate({ search, ...to }, options);
    }, [navigate]);
}*/

export function useNavigateWithSearch() {
    const navigate = useNavigate();
    return React.useCallback((to, arg) => {
        const {searchParams, ...options} = arg || {}
        let search;
        if (searchParams) {
            const noEmpty = Object.fromEntries(Object.entries(searchParams)
                .filter(([_, v]) => v != null));
            search = new URLSearchParams(noEmpty).toString();
        } else {
            search = undefined;
        }
        if (search) {
            to = to + '?' + search
        }
        return navigate(to, options);
    }, [navigate]);
}
