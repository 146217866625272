import { useEffect, useState } from 'react';
import { Form, Input, Modal, Select } from 'antd';

import { useFetching } from '../../hooks/useFetching';
import UserService from '../../API/UserService';
import trans from '../../languages/ruTranslate';

const { Option } = Select;

export default function UserAdd(props) {
    const { setModalActive, setDataOutdated } = props;
    const { showBoundary } = require('react-error-boundary').useErrorBoundary()

    const [form] = Form.useForm();

    const [userRoles, setUserRoles] = useState([]);
    const [areUserRolesFetched, setUserRolesFetched] = useState(false);

    const [confirmLoading, setConfirmLoading] = useState(false);

    const [fetchUserRoles, areUserRolesFetching, userRolesFetchingError] = useFetching(async () => {
        const response = await UserService.getUsersRoles();
        setUserRoles(response);
        setUserRolesFetched(true);
    });

    const onOkClicked = async () => {
        const user = await form.validateFields();

        try {
            setConfirmLoading(true);

            await UserService.addUser(user);

            setDataOutdated(true);
            setConfirmLoading(false);
            setModalActive(false);
        } catch (ex) {
            console.log('User adding problem: ' + ex);
            showBoundary(ex)
        }
    }

    const onCancelClicked = async () => setModalActive(false);

    useEffect(() => {
        if (!areUserRolesFetched && !areUserRolesFetching)
            fetchUserRoles();
    });

    if (userRolesFetchingError) {
        return <div>User roles loading error: {userRolesFetchingError}</div>
    }

    return (
        <Modal
            title={'Добавление пользователя'}
            okText={'Сохранить'}
            cancelText={'Отмена'}
            open={true}
            onOk={() => onOkClicked()}
            onCancel={() => onCancelClicked()}
            confirmLoading={confirmLoading}
        >
            <Form layout='vertical' form={form}>
                <Form.Item name='login' label='Логин/email'
                           rules={[{ required: true, message: 'Укажите email пользователя!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item name='userName' label='Имя пользователя'
                           rules={[{ required: true, message: 'Укажите имя пользователя!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item name='role' label='Роль'
                           rules={[{required: true}]}
                >
                    {areUserRolesFetching
                        ? <div>User roles are loading...</div>
                        : <Select placeholder='Укажите роль' allowClear>
                            {userRoles.map((role, idx) => {
                                return <Option key={role.name} value={role.name}>{trans(role.name)}</Option>
                            })}
                        </Select>
                    }
                </Form.Item>
            </Form>
        </Modal>
    )
}