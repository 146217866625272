import React, {useState} from 'react';
import { Tag, Select, Space } from 'antd';
import {PlusOutlined} from '@ant-design/icons';

function TagListSelector(props) {
    // values - array of tag values
    const {value, options, onChange} = props;
    const [selectVisible, setSelectVisible] = useState(false);

    const deleteValue = index => onChange(value.filter( (el, i) => {
        return i !== index
    }))

    const getTags = () => value.map( (item, index) => {
        return (
            <Tag 
                closable 
                onClose={ () => deleteValue(index)} 
                key={item}
            >
                { options.find( el => el._id === item )?.name }
            </Tag>
        )
    })


    const selectConfirm = selectValue => {
        if (value) {
            const newArr = value ? [...value, selectValue] : [selectValue];
            onChange(newArr);
        }
        setSelectVisible(false);
    }

    return (
        <Space wrap={true}>
            {getTags()}
            { selectVisible 
                ? <Select
                    type="text"
                    size="small"
                    style={{ width: 200 }}
                    onChange={selectConfirm}
                    placeholder='выберите значение'
                >
                    {
                        options.map( (item) => 
                            <Select.Option  key = {item._id}>
                                {item.name}
                            </Select.Option>
                        )
                    }

                </Select>
                :  <Tag onClick={ () => setSelectVisible(true)} style = {{borderStyle: 'dashed'}} >
                        <PlusOutlined /> Добавить
                 </Tag>
            }
        </Space>
    );
}

export default TagListSelector;