import React, {useEffect, useState} from 'react';
import {AutoComplete, Button, Form, Space} from 'antd';

import AttributeService from '../../API/AttributeService';
import AttributeAdd from "./AttributeAdd";

function AddAttributeBlock({addAttribute, existedAttributeNames, disableSavingNewAttrs}) {
    const existedAttributeSet = new Set(existedAttributeNames.map(name => name.toLowerCase()))
    const [attributes, setAttributes] = useState([]);
    const [options, setOptions] = useState([]);
    const [addNewAttribute, setAddNewAttribute] = useState(false);
    const [form] = Form.useForm();

    const loadAttributes = async () => {
        const attributes = await AttributeService.getAttributes();
        setAttributes(attributes);
        const options = attributes
            .filter(attr => !existedAttributeSet.has(attr.name.toLowerCase()))
            .map(attr => ({value: attr.name, label: attr.name}))

        setOptions(options);
    }

    useEffect(loadAttributes, []);

    const addAttributeItem = async () => {
        const newAttribute = form.getFieldValue("attributeName")
        const foundAttribute = attributes.find(obj => obj.name.toLowerCase() === newAttribute.toLowerCase());
        if (disableSavingNewAttrs) {
            doAddAttributeToClientSpace({name: newAttribute, description: ""})
        } else {
            if (foundAttribute)
                doAddAttributeToClientSpace(foundAttribute)
            else
                setAddNewAttribute(true)
        }
    }

    const doAddAttributeToClientSpace = (attr) => {
        addAttribute(attr)
        form.resetFields()
    }

    return (
        <><Form
                    form={form}
                    onFinish={addAttributeItem}
                >
                    <Space>
                        <Form.Item name="attributeName" rules={[{
                            validator: (_, value) =>
                                value.trim() === '' ? Promise.reject(new Error()) : Promise.resolve(),
                            message: "Имя должно быть задано"
                        }, {
                            validator: (_, value) =>
                                existedAttributeSet.has(value.trim().toLowerCase()) ? Promise.reject(new Error()) : Promise.resolve(),
                            message: "Такой атрибут уже есть"
                        }]}>
                            <AutoComplete
                                style={{width: '300px'}}
                                placeholder='название поля'
                                options={options}
                                filterOption={true}
                            />
                        </Form.Item>
                        <Form.Item><Button htmlType="submit" type="default">Добавить</Button></Form.Item>
                    </Space>
                </Form>
            {addNewAttribute ? <AttributeAdd setAddModal={setAddNewAttribute} addAttribute={doAddAttributeToClientSpace}
                                             name={form.getFieldValue("attributeName")}/>
                : null}
        </>
    )
}

export default AddAttributeBlock;