import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import { List, PageHeader, } from 'antd';
import {FolderOutlined } from '@ant-design/icons';

import UserService from "../../API/UserService";
import Header from "../Header";

// Если задан root и текущий проект, то редиректить текущий
export default function Projects() {
    const [projects, setProjects] = useState([]);
    const fetchProjects = async () => {
        const response = await UserService.getProjects();
        setProjects(response);
    };
  
    useEffect(() => {
        fetchProjects();
    }, []);

    if (!projects) return null;

    return <div>
        <Header />
        <div style={{ margin: '50px 300px', width: '600px'}} >                
            <PageHeader
                title={ 'Выберите проект'}
            />
            <List
                style= {{margin: '20px 30px'}}
                itemLayout="horizontal"
                dataSource={projects}
                rowKey = { item => item._id }
                renderItem={ item => (
                    <List.Item>

                        <List.Item.Meta
                            title={<Link to={`/projects/${item._id}/dashboard`}><FolderOutlined/> {item.name}</Link>}
                        />
                    </List.Item>
                )}
            />
        </div>
    </div>
        
}; 
