import Config from '../Config';
import ScheduleForm from '../Common/ScheduleForm';
import {Button, Form, Input, Space} from "antd";
import jsonata from "jsonata";
import React from "react";
import FeedService from "../../API/FeedService";

const ExportConfig = (props) => {
    const [filterForm] = Form.useForm();
    const {showBoundary} = require('react-error-boundary').useErrorBoundary()

    const saveAdvancedFilter = async (values) => {
        try {
            await FeedService.saveAdvancedFilter(props.data._id, values);
            Object.assign(props.data, values)
            filterForm.resetFields()
        } catch (e) {
            showBoundary(e)
        }
    }

    return (
        <div>
            <ScheduleForm feed={props.data}/>
            <br/>                     
            <Config data={props.data}/>
            <Form
                form={filterForm}
                initialValues={props.data}
                onFinish={saveAdvancedFilter}
                layout='vertical'
                labelCol={{span: 5}}
            >
                <h3>Условие для загрузки товара</h3>
                <Form.Item
                    key="advancedFilter"
                    name="advancedFilter"
                    rules={[{
                        validator: async (_, value) => value && jsonata(value),
                        message: "Неверный формат правила"
                    }]}
                >
                    <Input.TextArea
                        rows={2}
                        placeholder="УСЛОВИЯ НЕ ЗАДАНЫ"
                    />
                </Form.Item>
                <Form.Item shouldUpdate>
                    {() => (
                        <Space>
                            <Button
                                type="primary"
                                htmlType="submit"
                                disabled={!filterForm.isFieldsTouched()}
                            >
                                Сохранить
                            </Button>
                            <Button
                                onClick={() => filterForm.resetFields()}
                                disabled={!filterForm.isFieldsTouched()}
                            >
                                Отмена
                            </Button>
                        </Space>
                    )}
                </Form.Item>
            </Form>
        </div>
    )
}

export default ExportConfig;