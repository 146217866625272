import React, {useEffect, useState} from "react";
import {Link, Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import {Button, Divider, Image, Layout, Menu, Space} from 'antd';
import {FolderOpenOutlined, ToolOutlined} from '@ant-design/icons';

import AuthProvider from '../API/AuthProvider';
import UserService from '../API/UserService';
import UserChangePassword from "./Users/UserChangePassword";
import dayjs from 'dayjs';

export function dateOverdue(project) {
    const dueDate = dayjs(project?.dueDate)
    const today = dayjs();
    return dueDate.isBefore(today, 'day')
}

const ContentPanel = ({user}) => {
    const {projectId} = useParams();
    const [project, setProject] = useState();
    const [menuItem, setMenuItem] = useState();
    const [editUser, setEditUser] = useState();
    const location = useLocation();
    const navigate = useNavigate();
    const isDev = process.env.NODE_ENV === 'development';

    async function fetchProject() {
        try {
            AuthProvider.setProject(projectId);
            const project = await UserService.getProject(projectId);
            setProject(project);
        } catch (e) {
            console.log("no project");
            setProject(null);
        }
    }

    useEffect(() => {
        const menu = location.pathname.split('/')[3];
        setMenuItem( menu ? menu : "products");
    }, [location]);

    useEffect(() => {
        fetchProject();
    }, []);
    
    const logout = () => {         
        AuthProvider.signout();
        AuthProvider.setProject();
        navigate('/');
    }

    function productLimitReached(project) {
        return project?.numProducts >= project?.maxProducts
    }

    return (
        <Layout  className="layout App">
            <Layout.Header className={isDev ? "header dev-mode" : "header"}>
                <Image
                    width={30}
                    src="../../img/databird-logo.png"
                />
                <span className={isDev ? "header-title dev-mode" : "header-title"}>Databird : управление данными о товарах</span>
                <Space mode="horizontal" className={isDev ? "dev-mode" : ""} >
                    <Button type="text">
                        <Link to="/projects"><FolderOpenOutlined/>
                            Проект: <strong>{project?.name}</strong> (
                            {project?.tariff} до <strong style={{color: dateOverdue(project) ? 'red' : 'green'}}>
                                {dayjs(project?.dueDate).format('DD.MM.YYYY')}</strong> Товаров: <strong style={{color: productLimitReached(project) ? 'red' : 'green'}}>
                                {project?.numProducts}</strong>
                            )
                        </Link>
                    </Button>
                    <Button type="text" onClick={() => setEditUser(true)}>
                        {user.name} ({user.login}) <ToolOutlined />
                    </Button> 
                    <Button type="text">
                        <Link to="/" onClick={logout}>Выйти</Link>
                    </Button>  
                </Space>
            </Layout.Header>
            <Layout>
                <Layout.Sider 
                    className="site-layout-background" 
                    width={200}
                >
                    <Menu
                        mode="inline"
                        selectedKeys={[menuItem]}
                        className={isDev ? "dev-mode" : ""}
                        style={{borderRight: 0 }} >
                        <Menu.Item key="dashboard"><Link to="dashboard">Общая информация</Link></Menu.Item>
                        <Menu.Item key="products"><Link to="products">Каталог товаров</Link></Menu.Item>
                        <Menu.Item key="imports"><Link to="imports">Источники данных</Link></Menu.Item>
                        <Menu.Item key="exports"><Link to="exports">Экспорт данных</Link></Menu.Item>
                        <Menu.Item key="attributes"><Link to="attributes">Атрибуты товара</Link></Menu.Item>
                        <Menu.Item key="dictionaries"><Link to="dictionaries">Словари</Link></Menu.Item>
                        <Menu.Item key="orders"><Link to="orders">Заказы</Link></Menu.Item>
                        <Menu.Item key="users"><Link to="users">Пользователи</Link></Menu.Item>
                    </Menu>
                    <div style={{position: 'absolute', bottom: '20px', marginLeft: '10px'}}>
                        <Divider/>
                        <p><a href="https://databirds.notion.site/Databird-912f386294a34d4c9b38916edd968e82" target="_blank">Справка по системе</a></p>
                        <p><a href="https://t.me/databird_techsupport_bot" target="_blank">Техническая поддержка</a></p>
                    </div>
                </Layout.Sider>                  
                <Layout style={{ padding: '20px', height: "93vh"}}>
                    <Layout.Content className="site-layout-background" style={{padding: '5px 20px', overflowY: 'auto'}}>
                        <Outlet context={[project]} />   
                    </Layout.Content>
                </Layout>
            </Layout>
            {editUser && <UserChangePassword setEditUser={setEditUser} user={user}/>} 
        </Layout>   
    )
}

export default ContentPanel;