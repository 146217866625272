import React, {useEffect, useState} from "react";
import {Link, useSearchParams} from "react-router-dom";
import {useNavigateWithSearch} from "../../hooks/navigation";
import {Button, List, Modal, PageHeader} from 'antd';
import {DeleteOutlined, FolderOutlined} from '@ant-design/icons';
import moment from 'moment';

import trans from '../../languages/ruTranslate';
import Help from "../Common/Help";
import {useFetching} from '../../hooks/useFetching';
import FeedService from "../../API/FeedService";
import FolderService from "../../API/FolderService";
import FolderList from "../Common/Folder/FolderList";
import FolderMenu from "../Common/Folder/FolderMenu";

export default function Feeds({ stream = 'import' }) {
    const [searchParams] = useSearchParams();
    const folderId = searchParams.get("folderId");
    const [data, setData] = useState([]);
    const [folders, setFolders] = useState([]);
    const [folder, setFolder] = useState({});
    const navigate = useNavigateWithSearch();
    const { showBoundary } = require('react-error-boundary').useErrorBoundary()
    const [fetchFeeds, isLoading, error] = useFetching( async () => {
        const foldersResp = await FolderService.getSubFolders(stream, folderId);
        setFolders(foldersResp)
        const response = await FeedService.getFeeds(stream, folderId);
        if (response.length === 0 && !folderId && foldersResp.length === 0) navigate("create")
        else setData(response)
    });
    useEffect(async () => {
        setFolder(await FolderService.getFolder(folderId, stream))
    }, [folderId]);
    const deleteFeed = async (feed) => {
        try {
            await FeedService.deleteFeed(feed._id);
            fetchFeeds()
        } catch (e) {
            console.log("feed deleting problem")
            showBoundary(e)
        }
    }

    const syncFeed = async (item) => {
        item.isLoading = true;
        setData([...data]);
        try {
            await FeedService.feedSync(item._id);
        } catch (e){
            console.log("feed sync problem")
            showBoundary(e)
        } finally {
            fetchFeeds();
        }
    }

    function showDeleteConfirm(feed) {
        Modal.confirm({
          title: 'Удалить источник?',
          okText: 'Да',
          okType: 'danger',
          cancelText: 'Нет',
          onOk() {
            deleteFeed(feed);
          }
        });
    }

    function getDate(dt){
        return dt ? moment(dt).format("DD.MM.YY HH:mm") : ""
    }

    useEffect(() => {
        fetchFeeds();
    }, [stream, folderId]);

    if (error) {
        return <div>
            Ошибка загрузки: {error}
        </div>
    }

    return <div>
        <PageHeader
            onBack={folderId ? () => navigate('../imports', {searchParams: {folderId: folder.parentFolder}}) : null}
            title={folderId ? [<FolderOutlined/>, ' ', folder.name] : 'Источники данных'}
            subTitle={'получение из внешних систем'}
            extra={[
                <Help page='DATA_SOURCES'>Что такое источник данных?</Help>,
                <Button key="btn" type="primary" onClick={() => navigate("create", {searchParams: {folderId}})}>
                    Добавить источник
                </Button>,
                <FolderMenu folder={folder} updateFolder={setFolder} updateFolders={setFolders}/>
            ]}
        />
        <FolderList stream={stream} folders={folders}/>
        <List
            style= {{margin: '20px 30px'}}
            itemLayout="horizontal"
            dataSource={data}
            rowKey = { item => item._id }
            renderItem={ item => (
                <List.Item
                    actions={[
                        <Button
                            style = {{width : '120px'}}
                            type='link'
                            loading = { item.isLoading }
                            disabled={item.config?.readFromFile}
                            onClick={ () => syncFeed(item)}>
                            {item.config?.readFromFile ? "Файл" : "Загрузить"}
                        </Button>,
                        <Link to={item._id}>Открыть</Link>,
                        <Button icon={<DeleteOutlined />} onClick = { () => showDeleteConfirm(item)}/>
                    ]}>
                    <List.Item.Meta
                        title={<Link to={item._id}>{item.name}</Link>}
                    />
                    <div className="list-item" style = {{width : '100px'}}>
                        {item.lastSync?.inserted}
                    </div>
                    <div className="list-item">{item.type}</div>
                    <div className="list-item">{item.schedule?.period ? trans(item.schedule.period) : trans("manual")}</div>
                    <div style={{ color : item.state?.state === 'failed' ? 'red' : 'green', width : '150px'}}>
                        {getDate(item.state?.date)} {trans(item.state?.state)}
                    </div>
                </List.Item>
            )}
        />
    </div>
}; 
