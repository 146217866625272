import React, {useEffect, useState} from 'react';
import {Button, Typography, Form, Input, Space} from 'antd';
import {useNavigate} from "react-router-dom";
import FeedService from '../../API/FeedService';
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import AttributeService from "../../API/AttributeService";
import FilterComponent from "../Common/FilterComponent";
import Help from "../Common/Help";

export default function ExportFilters(props) {
    const {data : {filters, _id} } = props;
    const [myFilters, setMyFilters] = useState(filters);
    const [sourceFields, setSourceFields] = useState([]);
    const [numberOfProducts, setNumberOfProducts] = useState(0);
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { showBoundary } = require('react-error-boundary').useErrorBoundary()


    useEffect(() => {
        if (_id) {
            AttributeService.getSourceFields(_id)
                .then(result => setSourceFields(result))
        }
    }, []);

    useEffect(() => {
        if (_id) {
            FeedService.getNumberOfProducts(_id)
                .then(result => setNumberOfProducts(result))
        }
    }, [myFilters]);


    const saveFilters = async (values) => {
        try{
            await FeedService.saveFilters(_id, values);
            setMyFilters(values)
            form.resetFields()
            props.data.filters = values
        } catch(e){
            showBoundary(e)
        }
    }

    return (
        <><Form
            form={form}
            initialValues={myFilters}
            onFinish={saveFilters}
        >
            <Form.Item name="text" label="Название или ID товара">
                <Input placeholder="введите текст поиска"
                              allowClear
                              style={{width: 365}}
                />
            </Form.Item>
            <Form.List name="filters">
                {(fields, {add, remove}) => (
                    <>
                        {fields.map(({key, name}) =>
                            <Space key={key} style={{display: 'flex', marginBottom: 8}} align="baseline">
                                <FilterComponent name={name} sourceFields={sourceFields}/>
                                <MinusCircleOutlined className="dynamic-delete-button"
                                                     onClick={() => remove(name)}/>
                            </Space>)}
                        <Space direction="vertical">
                            <Form.Item noStyle shouldUpdate>
                                {({getFieldValue}) =>
                                    getFieldValue("filters").length === 0 ? (
                                        <Typography.Text type="secondary">Фильтр не задан</Typography.Text>
                                    ) : null
                                }
                            </Form.Item>
                            <Space>
                                <Form.Item>
                                    <Button type="dashed" onClick={() => add({})}
                                            icon={<PlusOutlined/>}>
                                        Добавить фильтр
                                    </Button>
                                    <Help page="EXPORT_FILTERS" type='link'>Что такое фильтр?</Help>                                    
                                </Form.Item>
                            </Space>
                        </Space>
                    </>
                )}
            </Form.List>
            <Form.Item shouldUpdate>
                {() => (
                    <Space>
                        <Button
                            type="primary"
                            htmlType="submit"
                            disabled={!form.isFieldsTouched()}
                        >
                            Сохранить
                        </Button>
                        <Button
                            onClick={() => form.resetFields()}
                            disabled={!form.isFieldsTouched()}
                        >
                            Отмена
                        </Button>
                    </Space>
                )}
            </Form.Item>
        </Form>  
        <div>
            Выбрано товаров: <b>{numberOfProducts}</b>
            <Button 
                type='link' 
                onClick={() => {
                    navigate("../products", {state: {"feed-filters": JSON.stringify(myFilters)}})
                }}
            >
                открыть в каталоге
            </Button>
        </div>      
        </>
    )
}

