import React, {useState} from "react";
import {useNavigateWithSearch} from "../../hooks/navigation";
import {useParams, useSearchParams} from "react-router-dom";
import {Tabs, PageHeader, Button, Tooltip, Row, Col, Dropdown, Menu, Badge, Modal, Spin, Space} from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import moment from 'moment';

import { useFetching } from '../../hooks/useFetching';
import trans from '../../languages/ruTranslate';
import FeedService from "../../API/FeedService";
import ExportConfig from "./ExportConfig";
import ExportCategories from "./ExportCategories";
import MappingList from "../Mapping/MappingList";
import ExportSources from "./ExportSources";
import FeedData from '../Products/FeedData';
import FeedErrors, {generateTranslatedErrorMessage, translateErrorType} from "../Products/FeedErrors";
import RenameCopyDialog from '../Feeds/RenameCopyDialog';
import FolderToChoose from "../Common/Folder/FolderToChoose";

const { TabPane } = Tabs;

export default function Export() {
    const [data, setData] = useState({filters: {text: "", filters: []}});
    const [spinning, setSpinning] = useState(false);
    const [renameCopy, setRenameCopy] = useState();
    const [chooseFolder, setChooseFolder] = useState(false);
    const navigate = useNavigateWithSearch();
    const [searchParams] = useSearchParams();
    const [activeTabKey, setActiveTabKey] = useState('filter');
    const [attributeToEdit, setAttributeToEdit] = useState();
    const { feedId } = useParams();
    const [fetchFeed, isLoading] = useFetching( async () => {
        const response = await FeedService.getFeed(feedId);
        setData(response);
        setSpinning(response.isLoading);
    })

    React.useEffect(() => {
        fetchFeed();
    }, [feedId]);

    React.useEffect(() => {
        const tab = searchParams.get("tab");
        if (tab) {
            setActiveTabKey(tab)
        }
    }, []);

    if (isLoading) {
        return <div>
            Загрузка данных....
        </div>
    }

    function openAttributeMapping(attributeName) {
        if (data.categoriesEnabled && attributeName === "_category_") {
            setActiveTabKey("categories")
        } else if (attributeName === "_advancedFilter_") {
            setActiveTabKey("config")
        } else {
            setActiveTabKey("mapping")
            setAttributeToEdit(attributeName)
        }
    }

    function showRunConfirm() {
        Modal.confirm({
          title: 'Запуcтить экспорт?',
          content: 'Экспорт может быть необратимой операцией. Выполните проверку перед экспортом.',
          okText: 'Да',
          okType: 'danger',
          cancelText: 'Нет',
          onOk() {
            syncFeed(true)
          }
        });
    }

    const syncFeed = async (syncMode) => {   
        setSpinning(true);
        try {
            syncMode? await FeedService.feedSync(data._id) : await FeedService.feedTest(data._id)
        } catch (e){
            console.log("feed sync problem")
        } finally {
            await fetchFeed();
        }
    }

    function getDate(dt){
        return dt ? moment(dt).format("DD.MM.YY HH:mm") : ""
    }

    const moveFeedFunc = async (newParentFolderId) => {
        data.parentFolderId = newParentFolderId
        await FeedService.updateFeed(feedId, {parentFolderId: newParentFolderId})
        setChooseFolder(false)
    }

    const menu = (
        <Menu>
            <Menu.Item>
                <Button type='link' key="1" onClick={() => setRenameCopy('rename')}>
                    Переименовать
                </Button>
            </Menu.Item>
            <Menu.Item>
                <Button type='link' key="2" onClick = { () => setChooseFolder(true)}>
                    Переместить
                </Button>
            </Menu.Item>
            <Menu.Item>
                <Button type='link' key="3" onClick = { () => setRenameCopy('copy')}>
                    Копировать
                </Button>
            </Menu.Item>
            <Menu.Item>
                <Button type='link' onClick={ () => {
                    setSpinning(true);
                    FeedService.downloadExport(data._id, data.name).finally(() => fetchFeed());
                }}>
                    Экспорт в файл
                </Button>
            </Menu.Item>
        </Menu>
    );

    return <div>
        <PageHeader
            onBack={ () => navigate('../exports', {searchParams: {folderId: data.parentFolderId}}) }
            title = { data.name }
            subTitle = { data.type }
            extra={[ 
                <Spin key='spin' style={{marginRight: '20px'}} size='small' spinning={spinning}/>,
                <Button key='check' disabled={spinning} onClick={ () => syncFeed(false)}>Проверка</Button>,     
                <Button key='exp' disabled={spinning} type='primary' onClick = { () => showRunConfirm()}>Экспорт</Button>,
                <Dropdown  key="more" overlay={ menu } disabled={spinning}  placement="bottomRight">
                    <Button type="text" icon={<MoreOutlined style={{ fontSize: 20 }} />} />
                </Dropdown>
            ]}
        />
        <div style= {{margin: '10px 30px'}}>
        <Row>
                <Col span={3}>Успешный экспорт:</Col>
                <Col>
                        {data.lastSync && `${getDate(data.lastSync?.startedAt)} (${Math.floor(data.lastSync?.extractDuration / 1000)} сек.)`}
                </Col>         
            </Row>
            <Row>
                <Col span={3}>Последняя загрузка:</Col>
                <Col span={3}>            
                    <span style={{ color : data.state?.state === 'failed' ? 'red' : 'green'}}>
                        {getDate(data.state?.date)}
                    </span>
                </Col>       
            </Row>
            <Row>
                <Col span={3}>Статус загрузки:</Col>
                <Col>
                    { data.state?.state === 'failed'
                        ? <Space>
                            <span style={{color: 'red'}} title={generateTranslatedErrorMessage(data.state, "fe")}>
                                {data.state.errorType ? translateErrorType(data.state.errorType, "fe") : trans(data.state.state) }                                
                            </span>
                            <Tooltip title={generateTranslatedErrorMessage(data.state, "fe")} trigger={["click", "hover"]}>
                                <a>Детали</a>
                            </Tooltip>
                        </Space>
                        : data.state?.state
                            ? <span style={{color : 'green'}}>{trans(data.state?.state)}</span>
                            : <span>НЕТ</span>
                    }
                </Col>
            </Row>
        </div>

        <Tabs
            style= {{margin: '20px 30px'}}
            activeKey={activeTabKey}
            onChange={ key => {
                setActiveTabKey(key);
            }}
            destroyInactiveTabPane = {true}
        >
            <TabPane tab="Фильтр товаров" key = "filter"><ExportSources data={data} fetchFeed={fetchFeed}/></TabPane>
            { data.categoriesEnabled  &&
                <TabPane tab="Категории" key = "categories"><ExportCategories data={data} fetchFeed={fetchFeed}/></TabPane> 
            }          
            <TabPane tab="Поля экспорта" key = "mapping"><MappingList data={data} fetchFeed={fetchFeed} attributeToEdit={attributeToEdit}/></TabPane>
            <TabPane 
                tab= {<Badge count=  {data.lastSync?.inserted} offset={[8, -6]} color='blue' overflowCount={100000}>Данные</Badge>}
                key = "data"
            >
                <FeedData data={data} />
            </TabPane>   
            <TabPane
                tab= {<Badge count=  {data.lastSync?.mistakes} offset={[8, -6]} color='red' overflowCount={100000}>Ошибки</Badge>}
                key = "errors"
            > 
                <FeedErrors feedDoc={data} openAttributeMapping={openAttributeMapping}/>
            </TabPane>
            <TabPane tab="Настройки" key = "config"><ExportConfig data={data} fetchFeed={fetchFeed}/></TabPane>   
        </Tabs>
        {renameCopy ? <RenameCopyDialog feed={data} mode={renameCopy} setMode={setRenameCopy} fetchFeed={fetchFeed}/> : null}
        <FolderToChoose
            stream={'export'}
            open={chooseFolder}
            defaultValue={data.parentFolderId}
            onChosenFolder={moveFeedFunc}
            onCancel={() => setChooseFolder(false)}
        />
    </div>
}
