import React, {useState} from 'react';
import { Form, Button, Space } from 'antd';
import {useSearchParams} from "react-router-dom";
import OAuthService from "../../API/OAuthService";
import FeedService from "../../API/FeedService";
import configMap from './types';

const Config = (props) => {
    const [form] = Form.useForm();
    const [config, setConfig] = useState({...props.data.config});
    const { showBoundary } = require('react-error-boundary').useErrorBoundary()

    const saveConfig = async (values) => {
        try{
            await FeedService.saveConfig(props.data._id, values);
            setConfig(form.getFieldsValue);
            form.resetFields();
            if (props.onChange)
                props.onChange(values)
        } catch(e){
            console.error("config save problem", e)
            showBoundary(e)
        }
    }
    const [searchParams, setSearchParams] = useSearchParams();
    const code = searchParams.get("code")
    const serviceId = searchParams.get("serviceId")

    React.useEffect(() =>{
        async function Exchange(){
            if (code && serviceId) {
                const result = await OAuthService.getCode(code)
                const access_token = result["access_token"];
                if (access_token) {
                    form.setFieldValue("access_token", access_token)
                    saveConfig(form.getFieldsValue())
                }
                searchParams.delete("code")
                searchParams.delete("serviceId")
                setSearchParams(searchParams)
            }
        }
        Exchange()
    }, [code, form, saveConfig, searchParams, serviceId, setSearchParams])

    const ConfigForm = configMap[props.data.stream][props.data.type]?.config;

    return (
        ConfigForm && <Form 
            form = {form}
            initialValues = {config}
            onFinish = {saveConfig}
            layout = 'vertical'
            labelCol={{span: 4}}
            wrapperCol={{ span: 8 }}
        >
            <h3>Подключение</h3>
            <ConfigForm form = {form} data = {props.data} storeStateFn={() => {}}/>
            <Form.Item shouldUpdate>
                { () => (
                    <Space>  
                        <Button 
                            type="primary" 
                            htmlType="submit"
                            disabled={!form.isFieldsTouched()}
                        >
                            Сохранить
                        </Button>                  
                        <Button 
                            onClick={ () => form.resetFields() }
                            disabled={!form.isFieldsTouched()}
                        >
                            Отмена
                        </Button>
                    </Space>
                )}
            </Form.Item>
        </Form>    
    )
}

export default Config;