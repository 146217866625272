import React, {useState, useEffect} from 'react';
import {Select, Col, Row, Button, Space, List, Modal, Form, AutoComplete, Tabs, Typography, Divider} from 'antd';
import {ArrowLeftOutlined, DeleteOutlined, EditOutlined} from '@ant-design/icons';

import FeedService from "../../API/FeedService";
import AttributeService from '../../API/AttributeService';
import RuleList from "./RuleList";
import FieldOrConstantForm from "../Common/FieldOrConstantForm";
const _ = require('lodash')
const {Text} = Typography;

const {Option} = Select;

const getOptions = () => {
    const values = [0, 1, 2, 3, 4, 5];
    return values.map( i => <Option value = {i} key= {i}/>)
}

export default function RuleEdit(props) {
    const {data, setEditItem, editItem, sourceFields} = props;
    const [loading, setLoading] = useState(false);
    const [corrections, setCorrections] = useState(editItem.corrections);
    const { showBoundary } = require('react-error-boundary').useErrorBoundary()

    //Modal
    const [modal, setModal] = useState();
    const [mainForm] = Form.useForm();
    const [correctForm] = Form.useForm();
    const [attributeValues, setAttributeValues] = useState([]);
    const [dictValues, setDictValues] = useState([]);

    const fillCorrections = async ()=> {
        if (!dictValues || dictValues.length === 0) return;  // Сказать, что нет корректировок
        let updated = false;
        const flatAttrValues = _.compact(attributeValues.flatMap(av => av.value))
        for (const attrValue of flatAttrValues){
            const filled = editItem.corrections.find( item => item.local?.toLowerCase() === attrValue?.toLowerCase());
            if (filled) continue;
            const match = dictValues.find( item => item.value.toLowerCase() === attrValue.toLowerCase());
            if (match) continue;
            editItem.corrections.push({local: attrValue, remote: ''});
            updated = true;
        }
        if (updated){
            await FeedService.saveMapping(data._id, editItem);
            setCorrections(editItem.corrections.slice());
        }     
    }    

    useEffect (() => {
        async function loadDicts() {
            const av = await AttributeService.getFieldValues(data._id, editItem.name);
            const dv = await AttributeService.getDictValues(data._id, editItem.name);
            setAttributeValues(av);
            setDictValues(dv);
        }
        loadDicts()
    }, [data, editItem]);

    const saveItem = async (values) => {
        setLoading(true);
        try{
            editItem.rules = values.rules;
            editItem.priority = values.priority;
            await FeedService.saveMapping(data._id, editItem);
            mainForm.resetFields();
            setLoading(false);
        } catch (e){
            setLoading(false);
            console.log("saving problem")
            showBoundary(e)
        }
    }

    const savePriority = async (value) => {
        setLoading(true);
        try{
            editItem.priority = value;
            await FeedService.saveMapping(data._id, editItem);
            setLoading(false);
        } catch (e){
            setLoading(false);
            showBoundary(e)
        }
    }

    const onSave = async () => {
        const values = correctForm.getFieldsValue();
        const currentItem = editItem.corrections.find(item => item.local === values.local);
        if (currentItem) currentItem.remote = values.remote
        else editItem.corrections.push(values);
        await FeedService.saveMapping(data._id, editItem);
        setModal();
        correctForm.resetFields()
    }

    const onEdit = item => {
        setModal(true);
        correctForm.setFieldsValue(item)
    }   

    const onDelete = async item => {
        const update = editItem.corrections.filter( el => el!==item);
        editItem.corrections = update;
        await FeedService.saveMapping(data._id, editItem);
        setCorrections(update)
    }

    const onCancel = () => {
    	setModal();
        correctForm.resetFields()
    }

    const MainForm = () => (
        <Form 
            form = {mainForm} 
            initialValues = {editItem}
            onFinish = {saveItem}
        >
            <h3 style={{marginTop : "60px", marginBottom:"-5px"}}>Операции</h3>
            <Divider/>
            <RuleList form={mainForm} sourceFields={sourceFields}/>
            <Form.Item shouldUpdate>
                { () => (
                    <Space>  
                        <Button 
                            type="primary" 
                            htmlType="submit"
                            disabled={!mainForm.isFieldsTouched()}
                        >
                            Сохранить
                        </Button>                  
                        <Button 
                            onClick={ () => mainForm.resetFields() }
                            disabled={!mainForm.isFieldsTouched()}
                        >
                            Отмена
                        </Button>
                    </Space>
                )}
            </Form.Item>
        </Form>  
    )

    return ( 
        <Row>
            <Col span={16}>
                <Row><Button
                    style={{margin: '20px 0px'}}
                    type={'dashed'}
                    icon={<ArrowLeftOutlined/>}
                    onClick={() => setEditItem(null)}
                >
                    Вернуться к списку полей
                </Button>
                </Row>
                <Row>
                    <Col span={18}>
                        <Space>
                            <strong style={{fontSize: '1.4em'}}>{editItem?.name}</strong>
                            <h3 style={{margin: '4px'}}> = </h3>
                            <FieldOrConstantForm sourceFields={sourceFields} feedId={data._id} element={editItem}/>
                        </Space>
                    </Col>
                    {data.stream === 'import' &&
                        <Col span={4} offset={2}>
                            Приоритет: <Select style={{width: '3em'}}
                                               value={editItem.priority}
                                               onChange={savePriority}>
                            {getOptions()}
                        </Select>
                        </Col>
                    }
                </Row>
                <Row>
                    <Col><i>{editItem.description}</i></Col>
                </Row>
                <MainForm/>
                <List
                    locale={{ emptyText: 'Нет корректировок' }}
                    style = {{marginTop: '60px'}}
                    itemLayout="horizontal"
                    header = {
                        <Row>
                            <Col flex='auto'><h3>Корректировки</h3></Col>
                            <Col>
                                <Space>
                                    <Button onClick={() => fillCorrections()}>Заполнить</Button>
                                    <Button onClick={() => {setModal(true)}}>Добавить</Button>
                                </Space>
                            </Col>
                        </Row>
                    }
                    dataSource={corrections}
                    rowKey = { item => item._id }
                    renderItem={ item => (
                        <List.Item
                            actions={[
                                <Button onClick={() => {onEdit(item)}}><EditOutlined/></Button>,
                                <Button onClick={() => {onDelete(item)}}><DeleteOutlined/></Button>
                            ]}>
                            <div className="list-item">{item.local}</div>
                            <div> = </div>
                            <div className="list-item">{item.remote}</div>
                        </List.Item>
                    )}
                />
            </Col>         
            <Col offset = {2} span={6}>
                <Tabs
                    defaultActiveKey={"source"}
                >
                    <Tabs.TabPane tab="Данные" key ="clientData" className="scrollable-container">
                        { attributeValues.length
                            ? attributeValues.map( item => <Row>{JSON.stringify(item.value)}</Row>)
                            : "нет загруженных данных"
                        }
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Ограничения" key ="possibleValues" className="scrollable-container">
                        { dictValues.length
                            ? dictValues.map( item => <Row>{JSON.stringify(item.value)}</Row>)
                            : "нет ограничений"
                        }
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Категории" key ="CategoryList" className="scrollable-container">
                        { editItem.scope?.length
                            ? editItem.scope.map( item => <Row>{item}</Row>)
                            : "для всех категорий"
                        }
                    </Tabs.TabPane>
                </Tabs>
            </Col>
            <Modal
                title = {'Добавить корректировку'}
                okText={'Сохранить'}
                cancelText={'Отмена'}
                open={modal}
                onOk={ onSave }
                onCancel={ onCancel }
            >
                <Form layout='inline' form={correctForm}>
                    <Form.Item name = "local">
                        <AutoComplete 
                            style= {{width: '200px'}}
                            placeholder = 'входное значение' 
                            options = {[...new Set(_.compact(attributeValues.flatMap(av => av.value)))]
                                .map(value => ({value}))}
                            filterOption = {true}                      
                        />
                    </Form.Item>
                    <Form.Item> = </Form.Item>
                    <Form.Item name="remote">
                        <AutoComplete 
                            style= {{width: '200px'}}
                            placeholder = 'выходное значение' 
                            options = {dictValues}   
                            filterOption = {true}                     
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </Row>
    );
}
