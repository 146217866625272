import React, {useState} from "react";
import { PageHeader, Table, Upload } from 'antd';
import DictionaryService from "../../API/DictionaryService";

export default function DictionaryCard(props) {
    const {openDictionary} = props;
    const [data, setData] = useState(props.data);

    const customRequest = async (options) => {
        const { onSuccess, onError, file, onProgress } = options;
        try{
            const dict = await DictionaryService.dictionaryUpload(file, data.name, onProgress);
            onSuccess();
            setData(dict)
        } catch (e) {
            console.log(e);
            onError();
        }
    }

    return <div>
        <PageHeader
            onBack={ () => openDictionary(null) }  
            title = { data.name }
            subTitle = { data.type }
            extra={[
                <Upload
                    maxCount = {1}
                    customRequest = {customRequest}
                    showUploadList = {{ showRemoveIcon : false }}>
                        <button>Загрузить словарь</button>
                </Upload>
            ]}
        />
        <Table style= {{margin: '20px 30px'}}
            rowKey={p => p._id}
            dataSource={data.values}
            columns={ data.pages?.map( item => (
                {
                    title: item,
                    dataIndex: [item],
                }
            ))}
            defaultExpandAllRows={false}
            pagination={false}
            key="table"
        /> 
    </div>
}
