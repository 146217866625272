import {masterApi} from "./ServerAPI";

export default class ProjectService {
    static async getProjects(){
        const response = await masterApi.get(`/projects`);
        return response.data
    }

    static async addProject(project){
        const response = await masterApi.post('/projects', project);
        return response.data
    }

    static async register(request){
        const response = await masterApi.post('/register', request);
        return response.data
    }

    static async deleteProject(project){
        const response = await masterApi.delete(`/projects/${project}`);
        return response.data
    }

    static async getAdminProjects(){
        const response = await masterApi.get(`/admin/projects`);
        return response.data
    }

    static async updateProject(id, project){
        const response = await masterApi.post(`/admin/projects/${id}`, project);
        return response.data
    }
}