const host = window.location.host.split(':')[0];
const domain = window.location.host.split('.')[0];
const origin = window.location.origin;

const config = {
    apiUrl: process.env.NODE_ENV === 'production' ? "" : `http://${host}:${process.env.REACT_APP_API_PORT}`,
    synonymizerUrl: 'https://timeweb.databird.ru:2077',
    accessTokenKey: 'databirdAccessToken',
    refreshTokenKey: 'databirdRefreshToken',
    projectKey: 'project',
    domain,
    origin,
    tariffs : [
        {value: "S", summ: 8000, activate: true},
        {value: "M", summ: 12000, activate: true},
        {value: "L", summ: 1800, activate: true},
        {value: "XL", summ: 28000, activate: true}
    ]
}

export default config;
