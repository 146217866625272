// Страницы помощи
const strings = {
    'DATA_SOURCES' : '2-1-4f2e7387f2df4430ae3aa03533b23d4c',
    'EXPORTS' : 'cf1ee31a29e74823b01b4bf20d97ce18',
    'CATALOG': '7-1-d163899c1e0c418ab9c486e39982db52',
    'SOURCE_TYPE' : '1-4-b5e2bee0a2fc4c1d9f472d9bf88d115e',
    'SOURCE_RULE' : 'b0b2d97cad3a4036896cecad534e9201',
    'SOURCE_TO_CATALOG' : '4a432f56c742406baef003c30958c39d',
    'EXPORT_TYPE' : '55f50dfa1c0743ac98f4eeabfe630c2d',
    'EXPORT_FILTERS' : '67018db9aca848158452fe98eb821a4f'
};

export default function getName(name) {
    return strings[name]
}