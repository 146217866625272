import React from 'react';
import {Button} from 'antd';
import ruHelp from '../../languages/ruHelp'

function Help({page, type, children}) {
    return <Button type={type ? type: 'dashed'} onClick={() => window.open(`https://databirds.notion.site/${ruHelp(page)}`,'_blank')}>
            {children}
    </Button>
}

export default Help;