import React, {useState} from 'react';
import {Form, InputNumber, Input, Radio, Space, Checkbox} from 'antd';

import TagList from '../Common/TagList';
import InputTable from '../Common/InputTable';

const ConfigXlsx = (props) => {
    const { form } = props;
    const [readFromFile, setReadFromFile] = useState(form.getFieldValue("readFromFile"));
    const [advancedMode, setAdvancedMode] = useState(form.getFieldValue("isAdvancedMode"))

    return [
        <Form.Item name = "readFromFile" label = 'Источник данных'>
            <Radio.Group defaultValue={false} onChange={e => setReadFromFile(e.target.value)}>
                <Space direction="vertical">
                    <Radio value={true}>Загрузить из файла</Radio>
                    <Radio value={false}>Загрузить по ссылке</Radio>
                </Space>
            </Radio.Group>
        </Form.Item>,
        <Form.Item
            label="Ссылка на фид"
            name="url"
            hidden={readFromFile}
        >
            <Input/>
        </Form.Item>,
        <Form.Item
            label="Логин"
            name="login"
            hidden={readFromFile}
        >
            <Input/>
        </Form.Item>,
        <Form.Item
            label="Пароль"
            name="password"
            hidden={readFromFile}
        >
            <Input/>
        </Form.Item>,
        <Form.Item
            key = "sheets"
            label="Вкладки"
            name="sheets"
            wrapperCol={{ span: 20 }} 
        >
            <TagList />
        </Form.Item>,
        <Form.Item
            key = "skipLines"
            label="Пропустить строчки"
            name="skipLines"
        >
            <InputNumber min={1} max={50}/>
        </Form.Item>,
        <Form.Item
            key = "isAdvancedMode"
            label="Расширенный режим"
            name="isAdvancedMode"
            valuePropName="checked"
        >
            <Checkbox onChange={ e => setAdvancedMode(e.target.checked) }/>
        </Form.Item>,
        <Form.Item
            key="template"
            label="Шаблон файла"
            name="template"
            hidden={!advancedMode}
            wrapperCol={{span: 20}}
            rules={[
                {required: true, message: 'Обязательный параметр'}
            ]}
        >
            <InputTable/>
        </Form.Item>
    ]
};

export default ConfigXlsx;