import React, {useState} from 'react';
import {Form, Input, Radio, Select, Space, Switch} from 'antd';
import TagList from '../Common/TagList';


const ConfigXml = (props) => {
    const { form } = props;
    const [readFromFile, setReadFromFile] = useState(form.getFieldValue("readFromFile"));
    const [postMethod, setPostMethod] = useState(form.getFieldValue("requestMethod") === 'POST');

    return (
        <div>
            <Form.Item name = "readFromFile" label = 'Источник данных'>
                <Radio.Group defaultValue={false} onChange={e => setReadFromFile(e.target.value)}>
                    <Space direction="vertical">
                        <Radio value={true}>Загрузить из файла</Radio>
                        <Radio value={false}>Загрузить по ссылке</Radio>
                    </Space>
                </Radio.Group>
            </Form.Item>
            <Form.Item
                label="Ссылка на фид"
                name="url"
                hidden={readFromFile}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                label="Метод"
                name="requestMethod"
                hidden={readFromFile}
            >
                <Select
                    defaultValue="GET"
                    onChange={value => setPostMethod(value === 'POST')}
                >
                    <Select.Option key="get" value="GET">GET</Select.Option>
                    <Select.Option key="post" value="POST">POST</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item
                label="Тело POST запроса"
                name="requestBody"
                hidden={readFromFile || !postMethod}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                label="Логин"
                name="login"
                hidden={readFromFile}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                label="Пароль"
                name="password"
                hidden={readFromFile}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                label="Кодировка"
                name="encoding"
                rules={[
                    {required: true, message: 'Обязательный параметр'}
                ]}
            >
                <Select>
                    <Select.Option key='utf8'>UTF-8</Select.Option>
                    <Select.Option key='utf16le'>UTF-16LE</Select.Option>
                    <Select.Option key='win1251'>WIN1251</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item
                key="unescape"
                label="Замена спец. символов"
                name="unescape"
                valuePropName="checked"
            >
                <Switch/>
            </Form.Item>
            <Form.Item
                label="Путь к товару"
                name="root"
                rules={[
                    {required: true, message: 'Обязательный параметр'}
                ]}
                wrapperCol={{ span: 20 }}
            >
                <TagList />
            </Form.Item>
            <Form.Item
                label="Путь к категории"
                name="categories"
                wrapperCol={{ span: 20 }}
            >
                <TagList />
            </Form.Item>,
        </div>
    );
};

export default ConfigXml;