import React, {useEffect, useState} from "react";
import dayjs from 'dayjs';

import ProjectService from '../../API/ProjectService';
import {useFetching} from '../../hooks/useFetching';
import { Navigate} from "react-router-dom";
import { Tabs, Alert } from 'antd';
import Header from "../Header";
import ProjectTable from "./ProjectTable";
import PaymentTable from "./PaymentTable";


export default function Master({user}) {
    const [data, setData] = useState([]);
    const [fetchProjects, isLoading, error] = useFetching(async () => {
        const response = await ProjectService.getAdminProjects();
        setData(response.map(modify))
    });

    useEffect(() => {
        fetchProjects();
    }, []);

    function modify(project) {
        const modified = {...project}
        modified.date = project.date ? dayjs(project.date) : undefined
        modified.dueDate = project.dueDate ? dayjs(project.dueDate) : undefined
        modified.dateStr = modified.date?.format('DD.MM.YYYY H:mm')
        modified.dueDateStr = modified.dueDate?.format('DD.MM.YYYY')
        return modified
    }

    if (!user.isMaster) return <Navigate to="/" />
    return (                
        <div>
            <Header />
            {error && (<Alert message={error} type="error"/>)}
            <Tabs
                style= {{margin: '20px 30px'}}
                defaultActiveKey={'projects'}
            >       
                <Tabs.TabPane tab="Проекты" key = "projectTable"><ProjectTable projects={data} fetchProjects={fetchProjects}/></Tabs.TabPane>
                <Tabs.TabPane tab="Счета и платежи" key = "paymentTable"><PaymentTable projects={data}/></Tabs.TabPane>
            </Tabs>
        </div>
    )
};