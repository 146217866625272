import React from 'react';
import { Form, Input} from 'antd';

import config from '../../config';
import AuthProvider from '../../API/AuthProvider';

const ConfigYml = ({data}) => {
    const host = config.apiUrl ? config.apiUrl : config.origin;
    const feedUrl = `${host}/api/load/${AuthProvider.getProject()}_${data?.name}.xml`;
    return (
        <div>
            { data &&
                <Form.Item label="Ссылка на фид" wrapperCol={{ span: 16 }}> 
                    <a href={feedUrl}>{feedUrl}</a>
                </Form.Item>
            }
            <Form.Item
                label="Поле yml: name"
                name="ymlName"
            >   
                <Input/>
            </Form.Item>
            <Form.Item
                label="Поле yml: company"
                name="ymlCompany"
            >   
                <Input/>
            </Form.Item>
            <Form.Item
                label="Поле yml: url"
                name="ymlUrl"
            >   
                <Input/>
            </Form.Item>
        </div>
    );
};

export default ConfigYml;