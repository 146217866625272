import React, { useState, useEffect, useRef }  from 'react';
import {Avatar, Badge, Button, Col, Row, Tooltip, Tabs, Typography, Space, Modal, Spin} from 'antd';
import {CloseCircleTwoTone, LoadingOutlined} from '@ant-design/icons';

import trans from "../../languages/ruTranslate";
import AddAttributeBlock  from "../Attributes/AddAttributeBlock";
import FeedService from "../../API/FeedService";
import RuleEdit from './RuleEdit';
import AttributeService from "../../API/AttributeService";
import FieldOrConstantForm from "../Common/FieldOrConstantForm";

const {Text} = Typography;

function MappingList(props) {
    const mapping = props.data.mapping;
    const attributeToEdit = props.data.mapping?.find(x => x.name === props.attributeToEdit)
    const [activeTab, setActiveTab] = useState("main");
    const [editItem, setEditItem] = useState(attributeToEdit);
    const [hints, setHints] = useState({});
    const [sourceFields, setSourceFields ] = useState();
    const [isSynonymizing, setIsSynonymizing] = useState(false);
    const scroll = useRef();

    useEffect( ()=> {
        if (!editItem && scroll.current) {
            window.scrollTo(0, scroll.current)
        }
    }, [editItem]);

    useEffect( async () => {
        if (props.data?._id) {
            const sf = await AttributeService.getSourceFields(props.data._id);
            setSourceFields(sf);
        }
    }, []);

    const removeItem = async (element) => {
        await FeedService.deleteMapping(props.data._id, element.name);
        props.fetchFeed()
    }

    const addAttribute = async (attribute) => {
        await FeedService.saveMapping(props.data._id, attribute);
        props.fetchFeed()
    }

    const showSynonymizeConfirm = async () => {
        Modal.confirm({
            title: "Запустить автоподбор для полей экспорта?",
            content: "Это может занять некоторое время. Для каждого конкретного поля вы сами выберите, принимать автоподбор или нет.",
            okText: 'Да',
            okType: 'danger',
            cancelText: 'Нет',
            onOk() { synonymize().then(); }
        });
    }

    const synonymize = async () => {
        setIsSynonymizing(true);
        const filteredSourceFields = await AttributeService.getProductsAttributes(props.data.filters);
        const attrs = (mapping ? mapping.filter(attr => attr.categoryDepended) : [])
            .map(attr => attr.name);
        setHints(await FeedService.getHints(attrs, filteredSourceFields, 0.75));

        setIsSynonymizing(false);
        showSynonymizeSuccess();
    }

    const showSynonymizeSuccess = () => {
        Modal.success({
            title: "Автоподбор завершён",
            content: "Для каждого поля проверьте, устраивает ли вас пободобранное поле, и примите либо отмените изменения.",
            okText: 'Понятно'
        });
    }

    const title = (availableSynonymize) => (
        <Row key='title'>
            <Col span={8}>
                <h4 style={{marginLeft: "40px", textDecoration:"underline dotted"}}>
                    { props.data.stream === 'import' ? "атрибут" : "поле экспорта"}
                </h4>
            </Col>
            <Col span={11}>
                <h4 style={{textDecoration:"underline dotted"}}>
                    правила заполнения
                </h4>
            </Col>
            <Col span={3}>
                {
                    availableSynonymize ?
                        <Button type={"primary"} onClick={() => showSynonymizeConfirm()}>
                            Автоподбор полей
                        </Button>
                        : null
                }
            </Col>
        </Row>
    )

    function itemRender(element) {
        const totalOperations = element.rules?.length + element.corrections?.length;
        return (
            <Row align={'middle'} style={{ margin: "10px" }} key={element.name}>
                <Col span={6} style={{overflow: 'hidden'}}>
                    <span style={{
                        color: 'red',
                        display: 'inline-block',
                        width: '8px'
                    }}>{element.required ? '*' : ' '}</span>
                    <Tooltip title={element.description}>
                        <Button type='link'>
                            {element.name}
                        </Button>
                    </Tooltip>
                </Col>
                <Col span={2}>
                    <Badge size='small' color='grey' count={element.priority}>
                        <Avatar shape='square'>=</Avatar>
                    </Badge>
                </Col>
                <Col span={10}>
                    <FieldOrConstantForm sourceFields={sourceFields} feedId={props.data._id} element={element}
                                         hint={element.categoryDepended ? hints[element.name] : undefined}/>
                </Col>
                <Col span={2}>
                    { totalOperations > 0 && <Text type='warning'>+ операции</Text>}
                </Col>
                <Col span={2}>
                    <Button onClick={() => {
                        scroll.current = window.scrollY;
                        setEditItem(element)
                    }
                    }>
                        Настроить
                    </Button>
                </Col>
                <Col span={1}>
                    <Button type="text" onClick={() => removeItem(element)}>
                        {(element.name !== trans('productId') && props.data.stream === 'import' || props.data.editableMapping) &&
                            <CloseCircleTwoTone/>}
                    </Button>
                </Col>
            </Row>
        );
    }

    const getAttributes = (categoryDepended) => {
        if (categoryDepended && isSynonymizing) {
            return <Spin style={{width: "100%"}} tip={"Выполняется автоподбор..."} indicator={<LoadingOutlined style={{fontSize: 40}} spin />} />
        }

        const record = mapping? mapping.filter(attr => attr.categoryDepended === categoryDepended) : []
        const requiredAttr = [];
        const nonRequiredAttr = [];
        record.forEach(attr => {
            if (attr.required) requiredAttr.push(attr)
            else nonRequiredAttr.push(attr)
        })
        const render = [...requiredAttr, ...nonRequiredAttr].map(itemRender)
        // Добавлены заголовки
        render.unshift(title(categoryDepended));
        if (props.data.stream === 'import' || props.data.editableMapping) render.push((
            <Space style={{marginLeft: "30px", marginBottom: '30px'}}>
                <AddAttributeBlock addAttribute={addAttribute}
                                   existedAttributeNames={mapping.map(item => item.name)}
                                   disableSavingNewAttrs={props.data.editableMapping}/>
            </Space>
        ));
        return render
    }

    if (editItem)
        return <RuleEdit data={props.data} editItem ={editItem} setEditItem={setEditItem} sourceFields={sourceFields}/>
    else
        return props.data.categoriesEnabled
            ? <Tabs
                type = "card"
                activeKey = {activeTab}
                onChange={tab => setActiveTab(tab)}
            >
                <Tabs.TabPane  tab = "Основные" key = "main">
                    { getAttributes(false) }
                </Tabs.TabPane>
                <Tabs.TabPane tab = "Характеристики" key= "charts">
                    { getAttributes(true) }
                </Tabs.TabPane>
            </Tabs>
            : getAttributes(false)
}

export default MappingList;