import React, {useState} from "react";
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import {Button, Popconfirm, Space, Table, Typography, Radio} from 'antd';

import ProjectService from '../../API/ProjectService';
import EditProject from "./EditProject";
import AddProject from "./AddProject";

export default function ProjectTable({projects, fetchProjects}) {
    const [mode, setMode] = useState('active');
    const [editProject, setEditProject] = useState();
    const [addProject, setAddProject] = useState();

    const create = async (project) => {
        if (project) await ProjectService.addProject(project);
        setAddProject(false);
        await fetchProjects()
    }

    const deleteProject = async item => {
        await ProjectService.deleteProject(item._id);
        await fetchProjects()
    }

    const columns = [
        {
            title: 'Название',
            dataIndex: 'name',
        },  {
            dataIndex: 'operation',
            title: 'Основной проект',
            render: (_, record) => record.parentProject ? projects.find(prj => prj._id === record.parentProject)?.name : '-',
        }, {
            title: 'Дата создания',
            dataIndex: 'dateStr',
        }, {
            title: 'Тариф',
            dataIndex: 'tariff',
        }, {
            title: 'Дата окончания',
            dataIndex: 'dueDateStr',
        }, {
            title: 'Товаров',
            dataIndex: 'maxProducts',
        },
        {
            dataIndex: 'operation',
            render: (_, record) => {
                return (
                    <Space>
                        <a onClick={() => setEditProject(record)}>
                            <EditOutlined />
                        </a>
                        <Popconfirm title={`Удалить проект ${record.name}?`} onConfirm={() => deleteProject(record)}>
                            <DeleteOutlined />
                        </Popconfirm>
                    </Space>
                );
            },
        },
    ]

    const getProjects = () => {
        switch (mode) {            
            case 'active': 
                return projects.filter(item => item.dueDate > new Date() && item.tariff !== 'Test' && !item.parentProject);
            case 'test': 
                return projects.filter(item => item.tariff === 'Test'&& !item.parentProject);               
            case 'payment': 
                return projects.filter(item => {
                    const d = new Date();
                    d.setDate(d.getDate() + 7);
                    return item.dueDate < d && item.tariff !== 'Test' && !item.parentProject
                })
            default:
                return projects
        }        
    }

    return <div>
        <Space>
            <Radio.Group defaultValue="active" buttonStyle="solid" onChange={ e => setMode(e.target.value) }>
                <Radio.Button value="active">Активные</Radio.Button>
                <Radio.Button value="test">Тестовые</Radio.Button>
                <Radio.Button value="payment">К оплате</Radio.Button>
                <Radio.Button value="all">Все</Radio.Button>
            </Radio.Group>
            <Button style={{marginLeft: '50px'}} type='dashed' onClick={() => setAddProject(true)}>Создать проект</Button>
        </Space>
        <Table
            rowKey={p => p._id}
            dataSource={getProjects()}
            columns={columns}
            pagination={false}
            key="project"
        />
        {editProject &&
            <EditProject project={editProject} hide={() => setEditProject(null)} onProjectChange={fetchProjects} projects={projects}/>}
        {addProject && <AddProject create={create}/>}
    </div>
};
