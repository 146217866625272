import React from 'react';
import {Form, Input} from 'antd';

const ConfigSkult = () => {
    return (
        <div>
            <Form.Item
                label="Ссылка на сервер"
                name="url"
            >   
                <Input/>
            </Form.Item>
        </div>
    )
};

export default ConfigSkult;