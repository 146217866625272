import React, {useEffect, useState} from 'react';
import {Select, Radio, Popover, Input, Form, Space, Button} from 'antd';
import FeedService from "../../API/FeedService";

/*
    Селектор поля или константа
*/

function FieldOrConstantForm({sourceFields, element, feedId, hint = undefined}) {
    const [isField, setIsField] = useState(element.isField);
    const [formChanged, setFormChanged] = useState(false);
    const [form] = Form.useForm();
    const {showBoundary} = require('react-error-boundary').useErrorBoundary()

    useEffect(() => {
        if (!(element.isField && hint && !element.source)) return;

        form.setFieldValue('source', hint);
        setFormChanged(true);
    }, [hint]);

    const saveItem = async (values) => {
        try {
            element.isField = isField;
            element.source = values.source;
            element.value = values.value;
            await FeedService.saveMapping(feedId, element);
            form.resetFields();
            setFormChanged(false)
        } catch (e) {
            showBoundary(e)
        }
    }

    return <Form
        form={form}
        initialValues={element}
        onFinish={saveItem}
        onValuesChange={() => setFormChanged(true)}
    >
        <Space>
            <Popover
                placement="left"
                trigger="click"
                content={
                    <Form.Item name="isField" style={{marginBottom: "0px"}}>
                        <Radio.Group onChange={e => {
                            setIsField(e.target.value);
                            setFormChanged(true)
                        }}>
                            <Radio value={true}>поле</Radio>
                            <Radio value={false}>значение</Radio>
                        </Radio.Group>
                    </Form.Item>
                }
            >
                <Form.Item name="source" style={{marginBottom: "0px"}} hidden={!isField} shouldUpdate>
                    <Select placeholder="укажите поле" showSearch style={{width: 300}}>
                        <Select.Option key="none" value=""><span
                            style={{color: "lightgrey"}}>не задано</span></Select.Option>
                        {sourceFields?.map(item => (<Select.Option key={item} value={item}>{item}</Select.Option>))}
                    </Select>
                </Form.Item>
                <Form.Item name="value" style={{marginBottom: "0px"}} hidden={isField} shouldUpdate>
                    <Input placeholder="укажите значение" style={{width: 300, color: 'darkblue'}}/>
                </Form.Item>
            </Popover>
            <Form.Item shouldUpdate style={{marginBottom: "0px"}} hidden={!formChanged}>
                {() => (
                    <Space>
                        <Button type="primary" htmlType="submit">
                            Ок
                        </Button>
                        <Button onClick={() => {
                            setFormChanged(false);
                            form.resetFields();
                        }}>
                            Отмена
                        </Button>
                    </Space>
                )}
            </Form.Item>
        </Space>
    </Form>
}

export default FieldOrConstantForm;
