import React from 'react';
import {Form, Input} from 'antd';

const ConfigOzon = () => {
    return (
        <div>
            <Form.Item
                label="Ссылка на 1С API"
                name="url"
                rules={[
                    {required: true, message: 'Обязательный параметр'}
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Логин"
                name="login"
                rules={[
                    {required: true, message: 'Обязательный параметр'}
                ]}
            >   
                <Input/>
            </Form.Item>
            <Form.Item
                rules={[
                    {required: true, message: 'Обязательный параметр'}
                ]}
                label="Пароль"
                name="password"
            >   
                <Input/>
            </Form.Item>
            <Form.Item
                label="ID склада в формате 1С"
                name="warehouseId"
            >   
                <Input/>
            </Form.Item>  
        </div>
    )
};

export default ConfigOzon;