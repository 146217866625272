import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button, Space } from 'antd';

import Activate from "./Activate";
import Header from "../Header";
import { databirdAuthProvider } from '../../API/AuthProvider';
import ResetPassword from "./ResetPassword";
import Register from "./Register";

export default function Login() {
    const [resetPassword, setResetPassword] = useState();
    const params = new URL(document.location).searchParams;
    const login = params.get("login");
    const activation  = params.get("activation");
    const register  = params.get("register");
    const navigate = useNavigate();
    const [message, setMessage] = useState();

    const onFinish = async (values) => {
        try {
            await databirdAuthProvider.signin(values);
            databirdAuthProvider.setProject('');
            setMessage(null);
            navigate('/');
        } catch (e) {
            setMessage("Неверный логин/пароль");
            console.log("login problem");
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const decorator = body => (
        <div>
            <Header />
            <div style={{ padding: '50px', minHeight: '85vh' }}>
                { body }
            </div>
        </div>
    )
    
    if (activation) return decorator(<Activate activation={activation} login={login} onFinish={onFinish}/>);
    if (resetPassword) return decorator(<ResetPassword />)
    if (register) return decorator(<Register />)

    // Главный логин
    return decorator(
        <Form name="basic"                            
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 8 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item label="Логин/email" name="login" rules={[{ required: true, message: 'введите email!' }]}>
                <Input />
            </Form.Item>
            <Form.Item 
                label="Пароль" 
                name="password" 
                rules={[
                    { required: true, message: 'Введите пароль!' },
                ]}>
                <Input.Password />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8, span: 8 }}>
                <Space>
                    <Button type="primary" htmlType="submit">Открыть</Button>
                    <Button type="link" onClick={() => setResetPassword(true)}>Сбросить пароль</Button>
                </Space>
                <span style={{color : 'red'}}> {message}</span>
            </Form.Item>
        </Form>
    )
};