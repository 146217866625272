import axios from 'axios';
import config from '../config';
import jwt_decode from 'jwt-decode';

const databirdAuthProvider = {
    decodedToken: () => {
        const token = localStorage.getItem(config.accessTokenKey);
        let decoded = null;
        try {
            decoded = jwt_decode(token);
        } catch (err) { }

        return decoded;
    },

    getUserId: () => {
        const token = databirdAuthProvider.decodedToken();
        return token?.id;
    },

    getUserSettings: () => {
        return {
            lang: 'ru-RU',
        };
    },

    signin: async(values) => {
        const response = await axios.post(`${config.apiUrl}/api/login`, values);
        localStorage.setItem(config.accessTokenKey, response.data.accessToken);
        localStorage.setItem(config.refreshTokenKey, response.data.refreshToken);
    },

    signout: () => {
        localStorage.setItem(config.accessTokenKey, null);
        localStorage.setItem(config.refreshTokenKey, null);
    },

    setProject: (projectId) => localStorage.setItem(config.projectKey, projectId),

    getProject: () => localStorage.getItem(config.projectKey)
};

export { databirdAuthProvider, databirdAuthProvider as default };