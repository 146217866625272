import {Modal, Row, Col, Form, DatePicker} from 'antd';
import config from '../../config';

export default function ConfirmPayment({item, confirmPayment}) {
    const [form] = Form.useForm();

    const setActivationDate = payDate => {
        const shoudActivate = config.tariffs.find(el => item.tariff === el.value)?.activate;
        if (payDate && shoudActivate) {
            const dueDate = item.dueDate && item.dueDate.isAfter(payDate) ? item.dueDate.clone().add(1, 'month') : payDate.clone().add(1, 'month');
            form.setFieldsValue({payDate, dueDate})
        } else form.setFieldValue('payDate', payDate)
    }
    
    const billInfo = () => {
        const rows = [
            {
                title: 'Номер счета',
                index: 'num',
            }, {
                title: 'Дата',
                index: 'dateStr',
            }, {
                title: 'Сумма',
                index: 'summ',
            }, {
                title: 'Тариф',
                index: 'tariff',
            }, {
                title: 'Проект',
                index: 'projectName',
            }, {
                title: 'Дата окончания',
                index: 'dueDateStr',
            }, {
                title: 'Клиент',
                index: 'client',
            }, {
                title: 'ИНН',
                index: 'inn',
            }
        ];
  
        return rows.map(row => (
            <Row>
                <Col span={6}>{row.title}:</Col>
                <Col span={8}>{item[row.index]}</Col>
            </Row>
        ))
    }

    return (
        <Modal
            open={true}
            title= "Подтверждение платежа"
            okText="Подтвердить"
            cancelText="Закрыть"
            onOk={()=> confirmPayment(
                {_id: item.projectId, dueDate: form.getFieldValue('dueDate')},
                {_id: item._id, payDate: form.getFieldValue('payDate'), status: 'paid'}
            )}
            onCancel={() => confirmPayment()}
        >
            <div style={{marginBottom : '20px'}}>
                {billInfo()}
            </div>
            <Form
                    labelCol={{ span: 5 }}
                    wrapperCol={{ span: 18 }}
                    form={form}               
            >
                <Form.Item 
                    label="Дата платежа" 
                    name="payDate"
                >
                    <DatePicker onChange={date => setActivationDate(date)}/>
                </Form.Item>
                <Form.Item 
                    label="Активация до" 
                    name="dueDate"
                >
                    <DatePicker />
                </Form.Item>
            </Form>
        </Modal>
    );

};
