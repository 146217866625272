import React, {useState} from 'react';
import { Input, Modal } from 'antd';

import DictionaryService from '../../API/DictionaryService';

function DictionaryAdd(props) {
    const {setAddModal, data} = props;
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [name, setName] = useState();
    const { showBoundary } = require('react-error-boundary').useErrorBoundary()

    const saveItem = async () => {
        setConfirmLoading(true);
        try{
            //Check if there is same name!
            const dictionary = await DictionaryService.addDictionary(name);
            data.push(dictionary);  
            setConfirmLoading(false);
            setAddModal(false);
        } catch (e){
            console.log("dictionary adding problem")
            showBoundary(e)
        }
    }

    return (
        <Modal
            title={"Добавить словарь"}
            okText={'Сохранить'}
            cancelText={'Отмена'}
            visible={true}
            onOk={ () => saveItem() }
            onCancel = { () => setAddModal(false) }
            confirmLoading = {confirmLoading}
        >
            <Input
                style = {{marginTop : "10px"}}
                placeholder="введите название словаря"
                value = { name }
                onChange={ e => setName(e.target.value) }
            />
        </Modal>
    );
}

export default DictionaryAdd;