import React, {useEffect, useState} from "react";
import {Modal, TreeSelect} from 'antd';
import _ from "lodash";


import FolderService from "../../../API/FolderService";

function toTree(allFolders, excludeFolderId) {
    function toNode(folder) {
        return {title: folder.name, value: folder._id, children: [], parent: folder.parentFolder}
    }
    const idToNode = {};
    for (const folder of allFolders) {
        idToNode[folder._id] = toNode(folder);
    }
    const rootNodes = []
    for (const node of _.values(idToNode)) {
        if (!node.parent) {
            if (node.value !== excludeFolderId) {
                rootNodes.push(node);
            }
        } else {
            const parentNode = idToNode[node.parent];
            if (parentNode) {
                if (node.value !== excludeFolderId) {
                    parentNode.children.push(node);
                }
            } else {
                console.error("Invalid parent of node %o", node)
            }
        }
    }
    return rootNodes;
}

export default function FolderToChoose({stream, open, defaultValue, excludeBranch, onChosenFolder, onCancel}) {
    const [folderTree, setFolderTree] = useState([]);
    const [value, setValue] = useState(defaultValue);
    const {showBoundary} = require('react-error-boundary').useErrorBoundary()
    useEffect(async () => {
        if (open) {
            try {
                const response = await FolderService.getFolders(stream);
                const topNodeArray = toTree(response, excludeBranch?._id);
                topNodeArray.unshift({title: 'Верхний уровень', value: '', children: []});
                setFolderTree(topNodeArray)
            } catch (e) {
                showBoundary(e)
            }
        }
    }, [stream, open]);

    return <div>
        <Modal
            title={'Выбор папки'}
            okText={'Выбрать'}
            cancelText={'Отмена'}
            open={open}
            onOk={() => onChosenFolder(value ? value : null)}
            onCancel={onCancel}
        >
            <TreeSelect
                showSearch
                style={{ width: '100%' }}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                placeholder="Выберите куда переместить"
                defaultValue={defaultValue}
                allowClear={false}
                treeDefaultExpandAll
                onChange={setValue}
                treeData={folderTree}
            />
        </Modal>
    </div>
};
