import React from 'react';
import {Form, Input} from 'antd';

const ConfigOzon = () => {
    return (
        <div>
            <Form.Item
                label="ID клиента в OZON"
                name="clientId"
                rules={[
                    {required: true, message: 'Обязательный параметр'}
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Ozon токен"
                name="token"
                rules={[
                    {required: true, message: 'Обязательный параметр'}
                ]}
            >
                <Input />
            </Form.Item>
        </div>
    );
};

export default ConfigOzon;