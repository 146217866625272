import {Button, Dropdown, Form, Input, Menu, Modal, notification} from "antd";
import React, {useEffect, useState} from "react";
import _ from "lodash";
import {MoreOutlined} from "@ant-design/icons";
import {useNavigateWithSearch} from "../../../hooks/navigation";
import FolderService from "../../../API/FolderService";
import FeedService from "../../../API/FeedService";
import FolderToChoose from "./FolderToChoose";

export default function FolderMenu({folder, updateFolder, updateFolders}) {
    const [checkAgainst, setCheckAgainst] = useState([]);
    const [chooseFolder, setChooseFolder] = useState(false);
    const [form] = Form.useForm();
    const {showBoundary} = require('react-error-boundary').useErrorBoundary()
    const [modifyAction, setModifyAction] = useState({});
    const navigate = useNavigateWithSearch()
    const [notificationInstance, contextHolder] = notification.useNotification();

    useEffect(async () => {
        if (modifyAction.action) {
            form.getFieldInstance("name").focus()
            if (modifyAction.action === "rename") {
                form.setFieldValue("name", folder.name)
            }
            const response = modifyAction.action === 'create'
                ? await FolderService.getSubFolders(folder.stream, folder._id)
                : await FolderService.getSiblings(folder._id);
            setCheckAgainst(response)
        }
    }, [modifyAction])

    const modifyFolderFunc = () => {
        form.validateFields()
            .then(doModify, () => {
            })

        async function doModify(folderValues) {
            try {
                if (modifyAction.action === 'create') {
                    folderValues.parentFolder = folder._id;
                    folderValues.stream = folder.stream;
                    const newFolder = await FolderService.addFolder(folderValues);
                    checkAgainst.push(newFolder);
                    updateFolders(checkAgainst);
                } else {
                    folder.name = folderValues.name;
                    const renamedFolder = await FolderService.updateFolder(folder);
                    updateFolder(renamedFolder);
                }
            } catch (e) {
                showBoundary(e)
            }
            form.resetFields()
            setModifyAction({})
        }
    }

    const deleteFolderFunc = async () => {
        const subFolders = await FolderService.getSubFolders(folder.stream, folder._id);
        const feeds = await FeedService.getFeeds(folder.stream, folder._id);
        if (subFolders.length > 0 || feeds.length > 0) {
            notificationInstance.error({
                message: `Нельзя удалить папку`,
                description:
                    'Папка содержит в себе сущности. Удалите или переместите их сначала.',
                placement: 'top',
            });
        } else {
            await FolderService.deleteFolder(folder._id);
            navigate(`../${folder.stream}s`, {searchParams: {folderId: folder.parentFolder}})
        }
    }

    const moveFolderFunc = async (newParentFolderId) => {
        const existingFolders = await FolderService.getSubFolders(folder.stream, newParentFolderId)
        const selfParent = newParentFolderId === folder._id
        const samePlace = _.find(existingFolders, f => f._id === folder._id);
        const sameName = _.find(existingFolders, f => f.name === folder.name);
        if (selfParent || samePlace || sameName) {
            notificationInstance.error({
                message: `Нельзя переместить в эту папку`,
                description: selfParent ? 'Нельзя переместить папку саму в себя' :
                    samePlace ? 'Данная папка уже здесь' :
                    `Эта папка уже содержит папку с таким же именем: ${folder.name}. Сначала переименуйте текущую папку.`,
                placement: 'top',
            })
        } else {
            folder.parentFolder = newParentFolderId
            await FolderService.updateFolder(folder)
            setChooseFolder(false)
        }
    }

    const menu = <Menu>
        <Menu.Item key="create">
            <Button type='link'
                    onClick={() => setModifyAction({
                        action: 'create',
                        title: 'Создать папку',
                        okText: 'Создать'
                    })}>
                Создать папку
            </Button>
        </Menu.Item>
        {folder._id ? [
            <Menu.Item key="rename">
                <Button type='link' onClick={() => setModifyAction({
                    action: 'rename',
                    title: 'Переименовать папку',
                    okText: 'Переименовать'
                })}>
                    Переименовать папку
                </Button>
            </Menu.Item>,
            <Menu.Item key="move">
                <Button type='link' onClick={() => setChooseFolder(true)}>
                    Переместить папку
                </Button>
            </Menu.Item>,
            <Menu.Item key="delete">
                <Button type='link' key="3" onClick={deleteFolderFunc}>
                    Удалить папку
                </Button>
            </Menu.Item>
        ] : null}
    </Menu>

    return <div>
        {contextHolder}
        <Modal
            title={modifyAction.title}
            okText={modifyAction.okText}
            cancelText={'Отмена'}
            open={modifyAction.action}
            onOk={modifyFolderFunc}
            onCancel={() => setModifyAction({})}
        >
            <Form layout='inline' form={form} onFinish={modifyFolderFunc}>
                <Form.Item name="name" label="Имя" rules={[
                    {required: true, message: 'Обязательный параметр'},
                    {
                        pattern: /^(?! +)[a-zA-Z0-9_а-яА-Я-?!()~@#$%^&*+ ]*[a-zA-Z0-9_а-яА-Я-?!()~@#$%^&*+]+$/,
                        message: 'Некоторые символы и пробелы в начале и в конце запрещены',
                    },
                    {
                        validator: (_, value) =>
                            checkAgainst.find(x => x.name === value.trim()) ? Promise.reject(new Error()) : Promise.resolve(),
                        message: "Такая папка уже есть"
                    }
                ]}>
                    <Input placeholder='Имя папки' style={{width: "25rem"}}/>

                </Form.Item>
            </Form>
        </Modal>
        <FolderToChoose
            stream={folder.stream}
            open={chooseFolder}
            defaultValue={folder.parentFolder}
            excludeBranch={folder}
            onChosenFolder={moveFolderFunc}
            onCancel={() => setChooseFolder(false)}
        />
        <Dropdown key="more" overlay={menu} placement="bottomRight">
            <Button type="text" icon={<MoreOutlined style={{fontSize: 20}}/>}/>
        </Dropdown>
    </div>

}