import React, {useState, useEffect} from "react";
import { PageHeader,Button, Modal, Space, List } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import DictionaryAdd  from "./DictionaryAdd";
import DictionaryCard  from "./DictionaryCard";
import DictionaryService from "../../API/DictionaryService";
import { useFetching } from "../../hooks/useFetching";

export default function Dictionaries() {    
    const [data, setData] = useState([]);
    const [currentDictionary, openDictionary] = useState();  
    const [addModal, setAddModal] = useState(false);
    const [fetchDictionaries, isLoading, error] = useFetching( async () => {
        const response = await DictionaryService.getDictionaries();
        setData(response)
    })
    const { showBoundary } = require('react-error-boundary').useErrorBoundary()

    const deleteDictionary = async (element) => {
        try {
            await DictionaryService.deleteDictionary(element.name);
            const newData = data.filter( (item) => item.name !== element.name );
            setData(newData);
        } catch (e) {
            console.log("attribute deleting problem")
            showBoundary(e)
        }
    }

    function showDeleteConfirm(element) {
        Modal.confirm({
          title: 'Удалить словарь?',
          okText: 'Да',
          okType: 'danger',
          cancelText: 'Нет',
          onOk() {
            deleteDictionary(element);
          }
        });
    }

    useEffect(() => {
        fetchDictionaries();
    }, []);
    
    if (error) {
        return <div>
            Ошибка загрузки: {error}
        </div>
    }

    if (isLoading) {
        return <div>
            Загрузка данных....
        </div>
    }

    return currentDictionary
    ? <DictionaryCard data={currentDictionary} openDictionary={openDictionary} />
    : <div>
        <PageHeader
            title="Словари"
            extra={[
                <Button key="addDictionary" type="primary" onClick={() => setAddModal(true)}>
                    Добавить словарь
                </Button>
            ]}
        />   
        <List
            style= {{margin: '20px 30px'}}
            itemLayout="horizontal"
            dataSource={data}
            rowKey = { item => item.name }
            renderItem={ item => (
                <List.Item
                    actions={[
                        <Button icon={<DeleteOutlined />} onClick = { () => showDeleteConfirm(item)}/>
                    ]}>
                    <Button type='link' onClick={ () => openDictionary(item)}>
                        {item.name}
                    </Button>
                    <Space>{item.pages}</Space>
                </List.Item>
            )}
        />
        
        { addModal ? <DictionaryAdd setAddModal = {setAddModal} data = {data} />: null }
    </div>         
}