import React, {useState} from 'react';
import { Input, Modal } from 'antd';

import AttributeService from '../../API/AttributeService';

function AttributeAdd(props) {
    const {setAddModal, addAttribute} = props;
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [name, setName] = useState(props.name);
    const [description, setDescription] = useState();
    const { showBoundary } = require('react-error-boundary').useErrorBoundary()

    const saveItem = async () => {
        setConfirmLoading(true);
        try{
            const attribute = await AttributeService.addAttribute({name, description});
            addAttribute(attribute);   
            setConfirmLoading(false);
            setAddModal(false);
        } catch (e){
            console.log("attribute adding problem")
            showBoundary(e)
        }
    }

    return (
        <Modal
            title={"Добавить атрибут"}
            open={true}
            okText={'Сохранить'}
            cancelText={'Отмена'}
            onOk={ () => saveItem() }
            onCancel = { () => setAddModal(false) }
            confirmLoading = {confirmLoading}
        >
            <Input
                style = {{marginTop : "10px"}}
                placeholder="имя"
                value = { name }
                onChange={ e => setName(e.target.value) }
            />
            <Input.TextArea
                style = {{marginTop : "10px"}}
                placeholder="описание"
                rows={4}
                value = { description }
                onChange={ e => setDescription(e.target.value) }
            />
        </Modal>
    );
}

export default AttributeAdd;