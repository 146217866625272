import React from 'react';
import {Button, Form, Input} from 'antd';

const serviceId = "yandexMarket"

function createServerCallbackUrl(serviceId) {
    const hostname = window.location.hostname;
    const subDomains = hostname.split(".")
    // we need to make a single callback url so that yandex can whitelisted it
    let hostWithMainDomain
    if (subDomains.length >= 2) {
        subDomains[0] = "app"
        hostWithMainDomain = subDomains.join(".")
    } else {
        hostWithMainDomain = hostname
    }
    const portPart = process.env.REACT_APP_API_PORT ? ":" + process.env.REACT_APP_API_PORT : ""
    const protocol = window.location.protocol
    return `${protocol}//${hostWithMainDomain}${portPart}/api/oauth-callback/${serviceId}`;
}

function redirectToYandexOAuthEndpoint() {
    const clientId = "aa056b6d695c41c19195db2437f1f62c"
    const callbackUrl = createServerCallbackUrl(serviceId);
    var currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set("tab", "config"); // we need to go to the config tab of our client page
    const encodedCurrentUrl = encodeURIComponent(currentUrl.href)
    const encodedCallbackUrl = encodeURIComponent(callbackUrl)
    window.location.href = `https://oauth.yandex.ru/authorize?response_type=code&client_id=${clientId}&force_confirm=yes&state=${encodedCurrentUrl}&redirect_uri=${encodedCallbackUrl}`
}

const ConfigYandexMarket = (business = "", warehouse = "", campaign = "") => ({storeStateFn}) => {
    return (
        <div>
            <Form.Item name="oauth_endpoint">
                <Button type="primary" onClick={() => {
                    storeStateFn()
                    redirectToYandexOAuthEndpoint()
                }}>Получить токен</Button>
            </Form.Item>
            <Form.Item
                label="Токен Яндекс"
                name="access_token"
                required={true}
            >
                <Input/>
            </Form.Item>
            {/* TODO we can get all the campaign and business of this user by sending
            GET https://api.partner.market.yandex.ru/campaigns
            Кампания соответствует магазину в личном кабинете, но id у них разные. Если выбрать магазин, то
            id кампании можно взять из адресной строки браузера.

            Id кабинета = businessId
            */}
            {business ? <Form.Item
                label="ID кабинета"
                name="businessId"
                required={true}
                extra="Откройте личный кабинет поставщика в Яндекс Маркет. Найдите ID кабинета на странице."
            >
                <Input/>
            </Form.Item> : null
            }
            {campaign ? <Form.Item
                label="ID кампании"
                name="campaignId"
                required={campaign.includes("required")}
                extra="Чтобы узнать идентификатор, войдите в личный кабинет, на «Сводке» выберите нужный магазин и
                 скопируйте параметр campaignId (номер) из адресной строки браузера вида
                  https://partner.market.yandex.ru/supplier/{campaignId}/summary."
            >
                <Input/>
            </Form.Item> : null
            }
            {/* TODO we can get all the warehouses and campaigns of the user by sending
            GET https://api.partner.market.yandex.ru/businesses/{businessId}/warehouses
            */}
            {warehouse ? <Form.Item
                label="ID Склада"
                name="warehouseId"
                extra="Идентификатор склада для синхронизации остатков. Если ID Склада для товара не задан, то будет использоваться этот идентификатор.
                Узнать идентификатор склада вы можете в личном кабинете в разделе Логистика → Склады. Он указан в поле ID склада.
                Если вы работаете с общими остатками, вы можете посмотреть идентификатор склада в личном кабинете
                в разделе Настройки → Настройки API в блоке Вы работаете с общими остатками."
            >
                <Input/>
            </Form.Item> : null}
        </div>
    );
};

export default ConfigYandexMarket;
