import api from "./ServerAPI";
import axios from "axios";
import {download, fileUpload} from "../utils/Files"
import config from "../config";

export default class FeedService {
    static async getFeeds(stream, folderId){
        const response = await api.get(`/feeds`, { params : { stream, folderId } });
        return response.data
    }

    static async getFeed(feedId){
        const response = await api.get(`/feeds/${feedId}`);
        return response.data
    }

    static async addFeed(feed) {
        const response = await api.post('/feeds', feed);
        return response.data
    }

    static async copyFeed(feedId, feedData){
        const response = await api.post(`/feeds/${feedId}/copy`, feedData);        
        return response.data
    }

    static async updateFeed(feedId, feed){
        const response = await api.patch(`/feeds/${feedId}`, feed);
        return response.data
    }

    static async deleteFeed(feedId){
        const response = await api.delete(`/feeds/${feedId}`);
        return response.data
    }

    static async saveMapping(feedId, mapping){
        await api.post(`/feeds/${feedId}/mapping`, mapping);        
        return mapping
    }

    static async deleteMapping(feedId, name){
        const response = await api.delete(`/feeds/${feedId}/mapping`, { data: { name } });
        return response.data
    }

    static async feedUpload(file, feedId, onProgress){
        return await fileUpload('/extract', file, onProgress, {feed: feedId});
    }

    static async feedSync(feedId){
        const response = await api.get(`/feeds/${feedId}/sync`);
        return response.data
    }

    static async feedCheck(feedId){
        const response = await api.get(`/feeds/${feedId}/check`);
        return response.data
    }

    static async feedCheckFile(feedId, file, onProgress){
        return await fileUpload(`/feeds/${feedId}/check-file`, file, onProgress);
    }

    static async feedTest(feedId){
            const response = await api.get(`/feeds/${feedId}/export-to-file`,{responseType : 'blob'});
        return response.data
    }

    static async saveConfig(feedId, config){
        const response = await api.post(`/feeds/${feedId}/config`, config);
        return response.data
    }

    static async saveSchedule(feedId, schedule){
        const response = await api.post(`/feeds/${feedId}/schedule`, schedule);
        return response.data
    }

    static async saveFilters(feedId, filters){
        const response = await api.post(`/feeds/${feedId}/filters`, filters);
        return response.data
    }

    static async saveAdvancedFilter(feedId, advancedFilter){
        const response = await api.post(`/feeds/${feedId}/advanced-filter`, advancedFilter);
        return response.data
    }

    static async saveCatalogUpdate(feedId, catalogUpdate){
        const response = await api.post(`/feeds/${feedId}/catalog-update`, catalogUpdate);
        return response.data
    }

    static async saveFeedParameters(feedId, syncParameters){
        const response = await api.post(`/feeds/${feedId}/feed-parameters-update`, syncParameters);
        return response.data
    }

    static async getNumberOfProducts(feedId) {
        const response = await api.get(`/feeds/${feedId}/number-products`);
        return response.data
    }

    static  async downloadExport(feedId, feedName){
        const response = await api.get(`/feeds/${feedId}/export-to-file`,{responseType : 'blob'});
        const disp = response.headers['content-type'];        
        const ext = disp ? disp.split('/')[1].split(';')[0] : 'json';
        download(response.data, `${feedName}.${ext}`)
    }


    static  async downloadDataToXls(feedName, feedId, search){
        const response = await api.get(`/feeds/${feedId}/data-xls`, {
            responseType : 'blob',
            params: {search}
        })
        download(response.data, `${feedName}.xlsx`, 'application/vnd.ms-excel');
    }


    static async downloadErrorsToXls(feedName, feedId) {
        const response = await api.get(`/feeds/${feedId}/errors-xls`, {responseType: 'blob'})
        download(response.data, `${feedName}-errors.xlsx`, 'application/vnd.ms-excel');
    }

//Category management
    static async setSource(feedId, source){
        const response = await api.post(`/feeds/${feedId}/source`, {source});
        return response.data
    }

    static async addCategory(feedId, category){
        const response = await api.post(`/feeds/${feedId}/category`, category);
        return response.data
    }

    static async deleteCategory(feedId, name){
        const response = await api.delete(`/feeds/${feedId}/category`, { data: { name } });
        return response.data
    }

    static async getCategoryList(feedId, pattern){
        const response = await api.get(`/feeds/${feedId}/catlist`,  { params : { pattern } } );
        return response.data
    }

    static async getPresentedCategoryList(feedId){
        const response = await api.get(`/feeds/${feedId}/presented-categories`);
        return response.data
    }

    static async getCategoryHints(fromArray, feedType) {
        let hints;
        await axios.post(
            `${config.synonymizerUrl}/seqs2cat`,{
                from: fromArray,
                to: feedType.toLowerCase(),
            }, {method: "post"}).then(
                response => { hints = response?.data['data'] ?? {}; }
            ).catch(
                () => { hints = {}; }
            );
        return hints;
    }

    static async getHints(fromArray, toArray, limit = -1) {
        let hints;
        await axios.post(
            `${config.synonymizerUrl}/seqs2seqs`,{
                from: fromArray,
                to: toArray,
                limit: limit
            }, {method: "post"}).then(
            response => { hints = response?.data['data'] ?? {}; }
        ).catch(
            () => { hints = {}; }
        );
        return hints;
    }
}