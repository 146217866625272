import React from "react";
import {Button, Result} from "antd";

const FallbackComponent = ({error, resetErrorBoundary}) => {

    return error.response?.status === 403 ?
        <Result
            status="warning"
            title="Вам запрещена эта операция"
            subTitle="(не хватает прав)"
            extra={<Button type="primary" onClick={resetErrorBoundary}>Вернуться</Button>
            }
        />
        :
        <Result
            status="error"
            title="Ошибка"
            subTitle={error.message ? error.message : "Что-то пошло не так"}
            extra={<Button type="primary" onClick={resetErrorBoundary}>Вернуться</Button>
            }
        />
}

export default FallbackComponent