import React, {useEffect, useState} from "react";
import {useNavigate, useOutletContext} from "react-router-dom";
import {Badge, Button, Card, Col, Divider, PageHeader, Row, Space, Statistic, Typography} from 'antd';
import {QuestionCircleOutlined} from '@ant-design/icons';
import {useFetching} from "../../hooks/useFetching";
import UserService from "../../API/UserService";
import dayjs from 'dayjs';
import {dateOverdue} from "../ContentPanel";
import HelpLink from "../Common/HelpLink";
import { htmlPdf } from '../Common/Pdf/pdfGen';

const {Paragraph, Text, Title, Link} = Typography;

export default function Dashboard({user}) {
    const [data, setData] = useState({});
    const [project] = useOutletContext();
    const [fetchDashboard, isLoading, error] = useFetching(async () => {
        const response = await UserService.getProjectDashboard();
        setData(response)
    })
    const navigate = useNavigate();

    useEffect(() => {
        fetchDashboard();
    }, []);

    const printBill = () => {        
        const bill = {...project.lastBill};
        console.log(project.lastBill);
        bill.dateStr = dayjs(bill.date).format('DD.MM.YYYY');
        bill.summStr = bill.summ?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        bill.clientInn = bill.client && bill.inn ? `Покупатель: ${bill.client} ИНН ${bill.inn}`: '';

        htmlPdf(`Счет датаберд ${bill.num} от ${bill.dateStr}.pdf`, bill)
    } 

    if (error) {
        return <div>
            Ошибка загрузки: {error}
        </div>
    }

    if (isLoading) {
        return <div>
            Загрузка данных....
        </div>
    }

    return <div>
        <PageHeader title={data.projectName}/>
        <Row gutter={[16, 24]}>
            <Col className="gutter-row" span={12}>
                <Title level={4}>{user.name}, добро пожаловать в DataBird</Title>
                <Text type="secondary">Если у вас возникнут вопросы, пишите смело в тех. поддержку, мы поможем
                    вам!</Text>
            </Col>
            <Col className="gutter-row" span={6}>
                <Card>
                    <Row>
                        <Col span={12}>
                            <Statistic
                                title={<Space>
                                    <Badge status={dateOverdue(data) ? "error" : "success"}/>
                                    Аккаунт активен до
                                </Space>}
                                value={dayjs(data.dueDate).format('DD.MM.YYYY')}
                            />
                        </Col>
                        <Col span={12}>
                            { project?.lastBill && 
                                <Button 
                                    style={{marginTop: '30px'}} 
                                    danger
                                    onClick={() => printBill()}
                                >
                                        Счет на оплату
                                </Button>
                            }
                        </Col>
                    </Row>
                </Card>
            </Col>
            <Col className="gutter-row" span={6}>
                <Card>
                    <Statistic
                        title={<Space>
                            <Badge status={data.numProducts >= data.maxProducts ? "error" : "success"}/>
                            Лимит товаров
                        </Space>}
                        value={data.numProducts + '/' + data.maxProducts}
                    />
                </Card>
            </Col>
            <Col className="gutter-row" span={6}>
                <Space direction="vertical" style={{width: '100%'}}>
                    <Card>
                        <Statistic
                            title="Товаров в каталоге"
                            value={data.numProducts}
                            groupSeparator="&#8201;"
                        />
                    </Card>
                    <Button style={{width: '100%'}} type="primary" onClick={() => navigate("../imports")}>Загрузить
                        товары в
                        каталог</Button></Space>
            </Col>
            <Col className="gutter-row" span={6}>
                <Card>
                    <Statistic
                        title="Источников товара"
                        value={data.feedCount?.import ?? 0}
                    />
                </Card>
            </Col>
            <Col className="gutter-row" span={6}>
                <Card>
                    <Statistic
                        title="Экспортов товара"
                        value={data.feedCount?.export ?? 0}
                    />
                </Card>
            </Col>
            <Col className="gutter-row" span={6}>
                <Card>
                    <Statistic
                        title="Количество категорий"
                        value={data.categoryCount}
                        groupSeparator="&#8201;"
                    />
                </Card>
            </Col>
        </Row>
        <Divider/>
        <Row gutter={[16, 24]}>
            <Space direction='vertical'>
                <h4>Принцип работы сервиса</h4>

                <Paragraph>
                    <ul>
                        <li>
                            Выбираете источник данных с товарами <HelpLink
                            page='DATA_SOURCES'><QuestionCircleOutlined/></HelpLink>
                        </li>
                        <li>
                            Настраиваете правила загрузки товаров в каталог <HelpLink
                            page='SOURCE_RULE'><QuestionCircleOutlined/></HelpLink>
                        </li>
                        <li>
                            Загружаете товары в каталог <HelpLink
                            page='SOURCE_TO_CATALOG'><QuestionCircleOutlined/></HelpLink>
                        </li>
                        <li>
                            Редактируете товары в каталоге <HelpLink page='CATALOG'><QuestionCircleOutlined/></HelpLink>
                        </li>
                        <li>
                            Настраиваете экспорт и выгружаете товары на нужные вам площадки <HelpLink
                            page='EXPORTS'><QuestionCircleOutlined/></HelpLink>
                        </li>
                    </ul>
                </Paragraph>
            </Space>
        </Row>
        <Divider/>
        <Row gutter={[16, 16]}>
            <Space direction='vertical'>
                <h4>Посмотрите вводные видео по работе с сервисом</h4>
                <Space wrap={true}>
                    <Link href='https://www.youtube.com/watch?v=qeFAxm7V9jM&t=55s' target='_blank'>
                        <img height='180'
                             src='https://img.youtube.com/vi/qeFAxm7V9jM/hqdefault.jpg' alt='Databird video'/>
                    </Link>
                    <Link href='https://www.youtube.com/watch?v=yFdNkF3Hdr0&t=36s' target='_blank'>
                        <img height='180' src='https://img.youtube.com/vi/yFdNkF3Hdr0/hqdefault.jpg'
                             alt='Databird video'/>
                    </Link>
                    <Link href='https://www.youtube.com/watch?v=zD8p3_Po740&t=85s' target='_blank'>
                        <img height='180' src='https://img.youtube.com/vi/zD8p3_Po740/hqdefault.jpg'
                             alt='Databird video'/>
                    </Link>
                </Space>
            </Space>
        </Row>
    </div>;
}
