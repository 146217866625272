import api from "../API/ServerAPI";

function download(data, fileName, contentType) {
    const options = contentType ? {type: contentType} : {}
    const url = window.URL.createObjectURL(new Blob([data], options));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
}

function newWindow(data, contentType) {
    const options = contentType ? {type: contentType} : {}
    const url = window.URL.createObjectURL(new Blob([data], options));
    window.open(url, '_blank').focus()
}

async function fileUpload(urlPath, file, onProgress, formDataEntries) {
    const formData = new FormData();
    console.debug("form data, %o", formDataEntries)
    if (formDataEntries) {
        for (const key in formDataEntries) {
            console.debug(key)
            console.debug(formDataEntries[key])
            formData.append(key, formDataEntries[key]);
        }
    }
    formData.append('file', file);
    const response = await api.post(urlPath, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
        onUploadProgress: event => {
            onProgress({percent: (event.loaded / event.total) * 100})
        }
    });
    return response.data;
}

export {download, fileUpload, newWindow}