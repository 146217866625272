import React from 'react';
import {Input, Form, Typography, Radio, Space} from 'antd';

const {Paragraph} = Typography;

const ConfigSberImport = () => {
    return (
        <div>
            <Paragraph>
                Мегамаркет пока не имеет автоматического способа получения данных. Необходимо скачать Эксель файл с
                товарами.
            </Paragraph>
            <Form.Item name="readFromFile" label='Источник данных'
                       rules={[
                           {required: true, message: 'Необходим Эксель файл в формате Мегамаркета'}
                       ]}>
                <Radio.Group defaultValue={false}>
                    <Space direction="vertical">
                        <Radio value={true}>Загрузить из файла</Radio>
                    </Space>
                </Radio.Group>
            </Form.Item>
            <Paragraph>
                Если планируете синхронизировать заказы или остатки введите токен Мегамаркет.
            </Paragraph>
            <Form.Item
                label="Токен Мегамаркет"
                name="token"
            >
                <Input/>
            </Form.Item>
            <Form.Item
                label="Код мерчанта"
                name="merchantCode"
                extra="Для получения этикеток необходим код мерчанта Мегамаркет (выдается при регистрации)"
            >
                <Input/>
            </Form.Item>
        </div>
    )
};

export default ConfigSberImport;