import api from "./ServerAPI";

export default class DictionaryService {
    static async getDictionaries(){
        const response = await api.get(`/dictionaries`);
        return response.data
    }    

    static async dictionaryUpload(file, name, onProgress){
        const formData = new FormData();
        formData.append('file', file);
        formData.append('name', name);        
        const response =  await api.post('/dictionaries/upload', formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress: event => { 
                onProgress({ percent: (event.loaded / event.total) * 100 })
            }
        });
        return response.data;
    } 

    static async addDictionary(name){
        const response = await api.post('/dictionaries', { name });
        return response.data
    }

    static async deleteDictionary(name){
        const response = await api.delete('/dictionaries', { data: { name } });
        return response.data
    }
}