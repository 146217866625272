import React, {useState} from "react";
import { Button, Form, Input, Space, Radio, Switch } from 'antd';
import jsonata from 'jsonata';

import Config from "../Config";
import FeedService from "../../API/FeedService";
import ScheduleForm from '../Common/ScheduleForm';
import TagListSelector from '../Common/TagListSelector';
import trans from '../../languages/ruTranslate';

const syncFeedTypes = ['OZON', 'WILD', 'YNDX', 'SBER']

const FeedConfig = ({data, onConfigChange}) => {
    const [readFromFile, setReadFromFile] = useState(data.config?.readFromFile)
    const [filterForm] = Form.useForm();
    const [catalogUpdateForm] = Form.useForm();
    const [syncParametersForm] = Form.useForm();
    const attributes = data.mapping
        .filter(item => item.name !== trans('productId'))
        .map(item =>({_id: item.name, name: item.name}));
    const { showBoundary } = require('react-error-boundary').useErrorBoundary()

    const saveAdvancedFilter = async (values) => {
        try{
            await FeedService.saveAdvancedFilter(data._id, values);
            Object.assign(data, values)
            filterForm.resetFields()
        } catch(e){
            showBoundary(e)
        }
    }

    const saveCatalogUpdate = async (values) => {
        try{
            const update = {...values};
            await FeedService.saveCatalogUpdate(data._id, update);
            Object.assign(data, update);
            catalogUpdateForm.resetFields()
        } catch(e){
            console.error("catalog update save problem", e)
            showBoundary(e)
        }
    }

    const saveSyncParameters = async (values) => {
        try{
            await FeedService.saveFeedParameters(data._id, values)
            Object.assign(data.feedParameters, values)
            syncParametersForm.resetFields()
        } catch(e){
            console.error("Cannot save sync parameters", e)
            showBoundary(e)
        }
    }

    const configChangeHandler = async (config) => {
        onConfigChange(config)
        setReadFromFile(config.readFromFile)
        await FeedService.saveSchedule(data._id, {period: "manual"})
    }


    return (
        <div>
            <ScheduleForm feed={data} disabled={readFromFile}/>
            <br/>
            <Config data={data} onChange={configChangeHandler}/>
            <Form
                form={catalogUpdateForm}
                initialValues={data}
                onFinish = {saveCatalogUpdate}
                layout = 'vertical'
                labelCol={{ span: 10}}
            >
                <h3>Настройки обновления каталога</h3>
                <Form.Item key = "catalogCreate" name = "catalogCreate" label = 'Создание новых товаров'>
                    <Radio.Group defaultValue={false}>
                        <Space direction="vertical">
                            <Radio value={true}>Создавать новые товары в каталоге</Radio>
                            <Radio value={false}>Не создавать</Radio>
                        </Space>
                    </Radio.Group>
                </Form.Item>
                <Form.Item key = "loadSourceFields" name = "loadSourceFields" label = 'Добавление исходных полей'>
                    <Radio.Group defaultValue={false}>
                        <Space direction="vertical">
                            <Radio value={true}>Добавлять исходные поля в каталог при создании товара</Radio>
                            <Radio value={false}>Не добавлять</Radio>
                        </Space>
                    </Radio.Group>
                </Form.Item>
                <Form.Item key = "catalogUpdateFields" name = "catalogUpdateFields" label = 'Список атрибутов для обновления существующих товаров'>
                    <TagListSelector options={attributes}/>
                </Form.Item>
                <Form.Item key = "missedProposalUnsetFields" name = "missedProposalUnsetFields" label = 'Список атрибутов для удаления в отсутствующих товарах'>
                    <TagListSelector options={attributes}/>
                </Form.Item>
                <Form.Item shouldUpdate>
                    { () => (
                        <Space>
                            <Button
                                type="primary"
                                htmlType="submit"
                                disabled={!catalogUpdateForm.isFieldsTouched()}
                            >
                                Сохранить
                            </Button>
                            <Button
                                onClick={ () => catalogUpdateForm.resetFields() }
                                disabled={!catalogUpdateForm.isFieldsTouched()}
                            >
                                Отмена
                            </Button>
                        </Space>
                    )}
                </Form.Item>
            </Form>
            <Form
                form={filterForm}
                initialValues={data}
                onFinish={saveAdvancedFilter}
                layout='vertical'
                labelCol={{span: 5}}
            >
                <h3>Препроцессинг записи</h3>
                <Form.Item
                    key="preprocessor"
                    name="preprocessor"
                    rules={[{
                        validator: async (_, value) => value && jsonata(value),
                        message: "Неверный формат правила"
                    }]}
                >
                    <Input.TextArea
                        rows={2}
                        placeholder="БЕЗ ПРЕРОЦЕССИНГА"
                    />
                </Form.Item>
                <h3>Условие для загрузки товара</h3>
                <Form.Item
                    key="advancedFilter"
                    name="advancedFilter"
                    rules={[{
                        validator: async (_, value) => value && jsonata(value),
                        message: "Неверный формат правила"
                    }]}
                >
                    <Input.TextArea
                        rows={2}
                        placeholder="УСЛОВИЯ НЕ ЗАДАНЫ"
                    />
                </Form.Item>
                <Form.Item shouldUpdate>
                    {() => (
                        <Space>
                            <Button
                                type="primary"
                                htmlType="submit"
                                disabled={!filterForm.isFieldsTouched()}
                            >
                                Сохранить
                            </Button>
                            <Button
                                onClick={() => filterForm.resetFields()}
                                disabled={!filterForm.isFieldsTouched()}
                            >
                                Отмена
                            </Button>
                        </Space>
                    )}
                </Form.Item>
            </Form>
            {syncFeedTypes.includes(data.type) ?
                <Form
                    form={syncParametersForm}
                    initialValues={data.feedParameters}
                    onFinish={saveSyncParameters}
                    layout='vertical'
                >
                    <h3>Cинхронизации заказов и остатков</h3>
                    <Space>
                        <Form.Item
                            key="orderSync"
                            label="Заказы"
                            name="orderSync"
                            valuePropName="checked"
                            style={{width: '100px'}}
                        >
                            <Switch/>
                        </Form.Item>
                        <Form.Item
                            key="stockSync"
                            label="Остаток"
                            name="stockSync"
                            valuePropName="checked"
                            style={{width: '100px'}}
                        >
                            <Switch/>
                        </Form.Item>
                    </Space>
                    <Form.Item shouldUpdate>
                        {() => (
                            <Space>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    disabled={!syncParametersForm.isFieldsTouched()}
                                >
                                    Сохранить
                                </Button>
                                <Button
                                    onClick={() => syncParametersForm.resetFields()}
                                    disabled={!syncParametersForm.isFieldsTouched()}
                                >
                                    Отмена
                                </Button>
                            </Space>
                        )}
                    </Form.Item>
                </Form>
            : null}
        </div>
    )
}

export default FeedConfig;