import React from "react";
import {Button, List, PageHeader} from 'antd';
import configMap from "../../Config/types";
import {useLocation, useSearchParams} from "react-router-dom";
import {useNavigateWithSearch} from "../../../hooks/navigation";

import Help from "../../Common/Help";

const groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

const feedTypes = Object.entries(configMap["export"])
    .filter(([type, value]) => !value.hidden)
    .map(([type, value]) => {
        return {
            type,
            ...value
        }
    }
);

const groupedFeedTypes = groupBy(feedTypes, "parent")
const parentsAndTypes = Object.entries(groupedFeedTypes).flatMap(
    ([parentKey, entries]) => {
        const parent = configMap["parent"][parentKey]
        const parentWithKey = {
            type: parentKey,
            ...parent
        }
        const result = []
        result.push(parentWithKey)
        result.push(...entries)
        return result
    }
)

export default function ExportWizard() {
    const [searchParams] = useSearchParams();
    const folderId = searchParams.get("folderId");
    const navigate = useNavigateWithSearch();
    const { state } = useLocation();

    return <div>
        <PageHeader
            title={'Создание нового экспорта'}
            subTitle={'выберите получателя данных'}
            backIcon={false}
            extra={[
                <Help page='EXPORT_TYPE'>Что такое тип экспорта?</Help>,
            ]}
        />

        <List
            style={{margin: '20px 30px'}}
            itemLayout="horizontal"
            dataSource={parentsAndTypes}
            rowKey={item => item.type}
            renderItem={item => (
                item.name ? <List.Item>
                        <h4>{item.name}</h4>
                    </List.Item>
                    :
                    <List.Item>
                        <div className="list-item" style={{width: '130px'}}><b>{item.type}</b></div>
                        <List.Item.Meta description={item.description}/>
                        <Button onClick={() => navigate(item.type, {searchParams: {folderId}, state })}>Выбрать</Button>
                    </List.Item>
            )}
        />
    </div>
};
