import React, {useEffect, useState} from "react";

import {Button, Space, Table, Radio, Popconfirm} from 'antd';
import { EditOutlined, CheckCircleOutlined, DeleteOutlined, CopyOutlined } from '@ant-design/icons';

import dayjs from 'dayjs';

import PaymentService from '../../API/PaymentService';
import ProjectService from '../../API/ProjectService';
import {useFetching} from '../../hooks/useFetching';
import EditPayment from './EditPayment'
import ConfirmPayment from "./ConfirmPayment";
import { htmlPdf } from '../Common/Pdf/pdfGen';

export default function PaymentTable({projects}) {
    const [data, setData] = useState([]);
    const [mode, setMode] = useState('new');
    const [pageSize, setPageSize] = useState(100);
    const [current, setCurrent] = useState(1);
    const [total, setTotal] = useState(10);
    const [item, setItem] = useState();
    const [confirmItem, setConfirmItem] = useState();
    const [fetchPayments, isLoading, error] = useFetching(async () => {
        const response = await PaymentService.getAllPayments();
        setData(response.map(modify))
        setTotal(response.length);
    });

    useEffect(() => {
        fetchPayments();
    }, []);

    const handleTableChange = ({current, pageSize}) => {
        setCurrent(current);
        setPageSize(pageSize);
    };

    const saveItem = async item => {
        if (item) {
            if (item._id) await PaymentService.updatePayment(item)
            else await PaymentService.addPayment(item)
            fetchPayments()
        }
        setItem()
    }

    const deleteItem = async item => {
        await PaymentService.updatePayment({_id: item._id, status: 'deleted'});
        fetchPayments()
    }

    
    // Функция подтверждения. Продлить проект, поставить статус "оплачен".
    const confirmPayment = async (project, payment) => {
        if (payment){ 
            if (project.dueDate) {
                await ProjectService.updateProject(project._id, project);
            }
            await PaymentService.updatePayment(payment);
            fetchPayments()
        }
        setConfirmItem()
    }

    const projectsBilling = async ()=> {
        await PaymentService.projectsBiling();
        fetchPayments()
    }

    function modify(payment) {
        const modified = {...payment}
        const project = projects.find(item => item._id === payment.projectId);
        modified.projectName = project?.name;
        modified.dueDate = project?.dueDate ? dayjs(project.dueDate) : undefined;
        modified.dueDateStr = modified.dueDate?.format('DD.MM.YYYY')
        modified.date = payment.date ? dayjs(payment.date) : undefined
        modified.dateStr = modified.date?.format('DD.MM.YYYY')
        modified.payDate = payment.payDate ? dayjs(payment.payDate) : undefined
        modified.payDateStr = modified.payDate?.format('DD.MM.YYYY')
        modified.summStr = modified.summ?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        modified.clientInn = modified.client && modified.inn ? `Покупатель: ${modified.client} ИНН ${modified.inn}`: '';
        return modified
    }

    const getColumns = () => {
        const main = [
            {
                title: 'Номер',
                dataIndex: 'num',
            }, {
                title: 'Дата счета',
                dataIndex: 'dateStr',
            }, {
                title: 'Сумма',
                dataIndex: 'summ',
            }, {
                title: 'Тариф',
                dataIndex: 'tariff',
            }, {
                title: 'Проект',
                dataIndex: 'projectName',
            }, {
                title: 'ИНН',
                dataIndex: 'inn',
            }
        ];
        if (mode === 'paid') main.push({            
            title: 'Дата оплаты',
            dataIndex: 'payDateStr',            
        })
        main.push({
            dataIndex: 'operation',
            render: (_, record) => {
                if (mode === 'deleted') return;
                return (
                    <Space>
                        { mode === 'new' && [
                            <a onClick={() => htmlPdf(`Счет датаберд ${record.num} от ${record.dateStr}.pdf`, record)}>
                                <CopyOutlined />
                            </a>,
                            <a onClick={() => setConfirmItem(record)}>
                                <CheckCircleOutlined />
                            </a>,
                        ]}
                        <a onClick={() => setItem(record)}>
                            <EditOutlined/>
                        </a>
                        <Popconfirm
                            title="Удалить запись?"
                            onConfirm={() => deleteItem(record)}
                            okText="Да"
                            cancelText="Нет"
                        >
                            <DeleteOutlined />
                        </Popconfirm>                        
                    </Space>
                );
            }
        })
        return main
    }

    return <div>
        <Space>
            <Radio.Group defaultValue="new" buttonStyle="solid" onChange={ e => setMode(e.target.value) }>
                <Radio.Button value="new">Счета</Radio.Button>
                <Radio.Button value="paid">Платежи</Radio.Button>
                <Radio.Button value="deleted">Архив</Radio.Button>
            </Radio.Group>
            { mode === 'new' && <Button style={{marginLeft: '200px'}} type='dashed' onClick={() => setItem({})}>Создать счет</Button> }
            { mode === 'new' && <Button type='dashed' onClick={() => projectsBilling()}>Биллинг проектов</Button> }
        </Space>
        <Table
            rowKey={p => p._id}
            dataSource={data.filter(item => item.status === mode)}
            columns={getColumns()}
            pagination={{current, total, pageSize}}
            key="payment"
            onChange={handleTableChange}
        />
        { item && <EditPayment projects={projects} item={item} saveItem= {saveItem} payments={data} /> }
        { confirmItem && <ConfirmPayment item={confirmItem} confirmPayment={confirmPayment} /> }
    </div>
};
